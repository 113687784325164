import React, { FC } from 'react';
import { Button, Grid, Typography, DialogContent, Dialog, DialogActions } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import styled from 'styled-components';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';

interface ManageContentModalProps {
    closeModal: () => void;
    open: boolean;
    submit: () => void;
    isLoading: boolean;
}

const ConfirmDelete: FC<ManageContentModalProps> = ({ closeModal, open, submit, isLoading }) => {
    return (
        <ConfirmModal open={open}>
            <Grid container direction="row" justifyContent="flex-end">
                <Button style={{ padding: '10px 0 0', minWidth: 0 }} onClick={closeModal}>
                    <CloseRounded />
                </Button>
            </Grid>
            <DialogContent style={{ padding: '0 20px 20px 40px', overflow: 'hidden' }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h1">Are you sure you want to delete?</Typography>
                        <Typography color="textSecondary">
                            The images being used here will be saved in your asset library. However, there is no way to
                            recover this community data once it's been deleted.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: '0 40px 20px' }}>
                <Button size="large" variant="contained" color="secondary" onClick={closeModal}>
                    No, close
                </Button>
                <Button size="large" variant="outlined" color="secondary" onClick={submit}>
                    <ProgressiveButtonText isLoading={isLoading} text="Yes" />
                </Button>
            </DialogActions>
        </ConfirmModal>
    );
};

const ConfirmModal = styled(Dialog)<any>`
    .MuiDialog-paper {
        width: 700px;
        margin: 0;
        height: fit-content;
        padding-bottom: 10px;
        margin-left: 65px;
    }
`;

export default ConfirmDelete;
