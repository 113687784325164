import React, { createContext, useContext, useReducer } from 'react';
import { editorInitialState, editorReducer, ContainerEditorState } from './container.reducer';

const ContainerStateContext = createContext(undefined as any);
const ContainerStateProvider = ({ children }: any) => {
    const [editorState, editorDispatch] = useReducer(editorReducer, editorInitialState);
    const valueProvider = {
        ...editorState,
        editorDispatch,
    };
    return <ContainerStateContext.Provider value={valueProvider}>{children}</ContainerStateContext.Provider>;
};

const useContainerState = (): [ContainerEditorState, React.Dispatch<any>] => {
    const context = useContext(ContainerStateContext);
    if (!context) {
        throw new Error('useContainerState needs to be within the ContainerStateProvider');
    }
    return context;
};

export { ContainerStateProvider, useContainerState };
