import React, { FC, useState } from 'react';
import { Typography, Button, Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import MediaUploadModal from 'domain/scry/components/common/Media/MediaUploadModal';

interface MediaAssetBoxProps {
    url: string;
    label?: string;
    dataName: string;
    selectAction: (libraryId: string, dataName: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: 'hidden',
            minHeight: 165,
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            border: `2px dashed ${theme.palette.text.disabled}`,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        container: {
            height: '100%',
            padding: 10,
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
        },
        label: {
            marginBottom: 10,
            marginTop: 0,
        },
        editButton: {
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'white',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: 5,
            borderRadius: 4,
        },
    })
);

const MediaAssetBox: FC<MediaAssetBoxProps> = ({ url, selectAction, label, dataName }: MediaAssetBoxProps) => {
    const [mediaModalOpen, setMediaModal] = useState<boolean>(false);
    const classes = useStyles();

    const handleSelect = (libraryId: string) => {
        selectAction(libraryId, dataName);
        setMediaModal(false);
    };

    const formattedUrl = url.toString().includes('-') ? url.replace(/-/g, '') : url;

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <div className={classes.root}>
                            {formattedUrl === '' ? (
                                <>
                                    {label && <Typography className={classes.label}>{label}</Typography>}
                                    <Button onClick={() => setMediaModal(true)} color="primary" variant="contained">
                                        Add Media
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Create className={classes.editButton} onClick={() => setMediaModal(true)} />
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_CDN_URL}/${formattedUrl}`}
                                        width="auto"
                                        style={{ maxWidth: '100%' }}
                                        alt=""
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
            {mediaModalOpen && (
                <MediaUploadModal isOpen={true} selectAction={handleSelect} closeAction={() => setMediaModal(false)} />
            )}
        </>
    );
};

export default MediaAssetBox;
