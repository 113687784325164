import React, { FC, useState } from 'react';
import { Typography, Grid, Popover, IconButton } from '@material-ui/core';
import { AutorenewRounded } from '@material-ui/icons';
import styled from 'styled-components';
import { Job } from 'core/models/Asset';
import JobItem from './JobItem';
import { FileToUpload } from './UploadAssetContainer';
import UploadItem from './UploadItem';
interface AssetUploaderProps {
    anchorEl: any;
    handleCloseNotificationPopup: any;
    filesToUpload: Array<FileToUpload>;
    fetchActiveJobs: () => void;
    fetchMediaAssets: () => void;
    activeJobs: Array<Job>;
    handleDeleteUploadItem: (indexNumber: number) => void;
    retryUpload: (index: number) => void;
}

const AssetUploader: FC<AssetUploaderProps> = ({
    anchorEl,
    handleCloseNotificationPopup,
    filesToUpload,
    fetchActiveJobs,
    fetchMediaAssets,
    activeJobs,
    retryUpload,
    handleDeleteUploadItem,
}) => {
    const [refreshing, setRefreshStatus] = useState(false);

    const handleRefresh = async () => {
        setRefreshStatus(true);
        fetchActiveJobs();
        fetchMediaAssets();

        setTimeout(() => {
            setRefreshStatus(false);
        }, 300);
    };

    return (
        <StylePopover
            id="upload-status-container"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={anchorEl !== null}
            onClose={handleCloseNotificationPopup}
            style={{ maxHeight: 'none', overflowY: 'hidden', padding: 0 }}
        >
            <Grid container style={{ width: 400, padding: 0 }}>
                <Grid item xs={12} style={{ padding: 20 }}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h2" style={{ margin: 0 }}>
                                Uploaded Files in Flight
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RefreshJobsList onClick={handleRefresh} className={refreshing ? 'fetching' : ''.trim()}>
                                <AutorenewRounded />
                            </RefreshJobsList>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        maxHeight: 350,
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        padding: '10px 20px 20px',
                    }}
                >
                    {filesToUpload.map((file: FileToUpload, index: number) => (
                        <UploadItem
                            retryUpload={retryUpload}
                            handleDeleteUploadItem={handleDeleteUploadItem}
                            key={index}
                            index={index}
                            file={file}
                        />
                    ))}
                    {activeJobs?.length > 0 && (
                        <Grid item xs={12}>
                            {activeJobs.map((job: Job, index: number) => {
                                const isLastItem = activeJobs.length === index + 1;
                                return (
                                    <JobItem
                                        key={job.id}
                                        data={job}
                                        isLastItem={isLastItem}
                                        fetchActiveJobs={fetchActiveJobs}
                                    />
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </StylePopover>
    );
};

const RefreshJobsList = styled(IconButton)`
    padding: 0;

    &.fetching {
        animation: spin 1s infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const StylePopover = styled(Popover)`
    .MuiPopover-paper {
        padding: 0;
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;

export default AssetUploader;
