import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NarrationSubMenuItem } from '../../domain/narration/components/manifest-editor/menus/SubMenu';
import { List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@material-ui/core';

interface SubnavigationProps {
    callToAction?: any;
    title?: string;
    navLinks?: Array<NarrationSubMenuItem>;
}

const SubNavigation: FC<SubnavigationProps> = ({ callToAction, title, navLinks }) => {
    const { push } = useHistory();
    const { palette } = useTheme();
    return (
        <StyledSubnav>
            <CallToActionContainer title={title}>
                {callToAction}
                {title && <Typography style={{ fontWeight: 'bold', marginTop: 30 }}>{title}</Typography>}
            </CallToActionContainer>
            {navLinks && (
                <List>
                    {navLinks.map(({ path, text, icon, selected }: NarrationSubMenuItem, index: number) => (
                        <StyledListItem
                            key={`${index}-${path}`}
                            button
                            theme={palette}
                            onClick={() => push(path)}
                            selected={selected}
                            style={{
                                backgroundColor: selected ? palette.background.paper : 'inherit',
                            }}
                        >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={text} />
                        </StyledListItem>
                    ))}
                </List>
            )}
        </StyledSubnav>
    );
};

const CallToActionContainer = styled.div`
    padding: 0 25px;
    margin: ${props => (props.title ? '0' : '0 0 30px 0')};

    @media only screen and (max-width: 960px) {
        padding: 0;
        text-align: center;
    }
`;

const StyledListItem = styled(ListItem)`
    padding: 10px 25px;
    @media only screen and (max-width: 960px) {
        justify-content: center;
    }
    span {
        margin: 0 !important;
    }

    .MuiListItemIcon-root {
        color: ${style => style.theme.text.main};
    }
    .MuiListItemText-root {
        margin: 0;
        padding: 0;

        @media only screen and (max-width: 960px) {
            display: none;
        }
    }
    &.Mui-selected {
        .MuiListItemIcon-root {
            color: ${style => style.theme.action.active};
        }
    }
    .MuiTypography-root {
        padding: 0 0 0 10px;
    }
`;

const StyledSubnav = styled.div`
    width: 272px;
    min-width: 272px !important;
    height: 100%;
    margin-top: 40px;
    padding: 60px 0 0;
    border: none;
    background-color: transparent !important;

    @media only screen and (max-width: 960px) {
        width: 120px;
        min-width: 120px !important;
    }
`;

export default SubNavigation;
