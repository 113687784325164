import * as yup from 'yup';

export const narrationGameSchema = yup.object().shape({
    name: yup.string().required(),
    aboutUri: yup.string().url().required(),
    price: yup.number().required(),
    sku: yup.string().required(),
});

export const gameImageUrlSchema = yup.object().shape({
    url: yup.string().url().required(),
});
