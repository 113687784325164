import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid, useTheme, TextField, Typography } from '@material-ui/core';
import MediaAssetBox from 'components/shared/MediaAssetBox';
interface CommunityMetaFormProps {
    data: {
        name: string;
        iconId: string;
        cardId: string;
    };
    handleChange: (event: any) => void;
    handleAssetSelect: (libraryId: string, dataName: string) => void;
}

interface Image {
    imageUrl: string;
    name: 'cardId' | 'iconId';
    label: string;
}

const CommunityMetaForm: FC<CommunityMetaFormProps> = ({ data, handleChange, handleAssetSelect }) => {
    const { palette } = useTheme();
    const { name, cardId, iconId } = data;

    const imageUploadTypes: Array<Image> = [
        {
            imageUrl: cardId.replace(/-/g, ''),
            name: 'cardId',
            label: 'Add Thumbnail',
        },
        {
            imageUrl: iconId.replace(/-/g, ''),
            name: 'iconId',
            label: 'Add Icon',
        },
    ];

    return (
        <>
            <Typography variant="body2" style={{ fontWeight: 600, margin: '20px 0 5px 0' }}>
                Community Meta Data
            </Typography>
            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4} lg={3}>
                    <Typography style={{ margin: 0 }}>Community Name</Typography>
                </Grid>
                <Grid item xs={7} md={8} lg={9}>
                    <TextField
                        id="name"
                        name="name"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        onChange={handleChange}
                        value={name}
                    />
                </Grid>
            </FormGroup>
            <Typography variant="body2" style={{ fontWeight: 600, margin: '35px 0 5px 0' }}>
                Community Images
            </Typography>
            <Grid container direction="row" alignItems="flex-start" spacing={3}>
                {imageUploadTypes.map((image: Image, index: number) => (
                    <Grid item xs={4} lg={3} key={`${index}-${image.name}`}>
                        <MediaAssetBox
                            dataName={image.name}
                            url={image.imageUrl}
                            label={image.label}
                            selectAction={handleAssetSelect}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const FormGroup = styled(Grid)`
    width: 100%;
    background-color: ${style => style.theme.background.paper};
    padding: 0 20px;
`;

export default CommunityMetaForm;
