import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import PublisherList from './PublisherList';
import ManagePublisherInfo from './ManagePublisherInfo';

const PublisherManagementContainer: FC = () => {
    return (
        <Switch>
            <Route exact path="/publishers" component={PublisherList} />
            <Route exact path={['/publishers/add', '/publishers/edit/:publisherId']} component={ManagePublisherInfo} />
        </Switch>
    );
};

export default PublisherManagementContainer;
