import Asset from './Asset';
import Guid from './Guid';

export enum GameTypes {
    None = 'None',
    AudioNarration = 'AudioNarration',
    Scry = 'Scry',
}

type Publisher = {
    name: string;
};
export default interface Game {
    id: Guid;
    name: string;
    duration: number;
    type: GameTypes;
    storeCardUri: string;
    gameDetailCardUri: string;
    learnMoreCardUri: string;
    aboutUri: string;
    price: number;
    published: boolean;
    forSale: boolean;
    sku: string;
    shopifyId: number;
    publisherId: Guid;
    publisher: Publisher;
}
export interface Transition {
    type: string;
    requirementDescription?: string;
    transitionPrompt?: string;
    forceEnd: boolean;
    childNodes: Array<Track>;
    endType: 'return' | 'winlose' | null;
}
export interface Track {
    id: string;
    title: string;
    asset: Asset | null;
    transition: Transition;
}
export interface GameDetailsState {
    loading: boolean;
    game: Game | null;
    uploadedLearnMoreUri: NewImageBlob | null;
    uploadedGameDetailCardUri: NewImageBlob | null;
    uploadedStoreCardUri: NewImageBlob | null;
}

export enum EditDispatchTypes {
    UPDATE_FIELD = 'UpdateField',
    SET_LOADING = 'SetLoading',
    SET_GAME_DATA = 'SetInitialGameData',
    UPLOAD_IMAGE = 'UploadImage',
    RESET_UPLOADED_IMAGE_FIELDS = 'ResetUploadedImageFields',
}

export type NewImageBlob = {
    blob: string;
    file: FileList;
};
export interface GameFormValidation {
    name: RegExp;
    sku: RegExp;
    publisherId: RegExp;
    aboutUri: RegExp;
    price: RegExp;
}

export interface CreateGameRequest {
    id?: string;
    name: string;
    sku: string;
    publisherId: string;
    aboutUri: string;
    type: GameTypes;
    price: string | number;
    storeCard?: any;
    gameDetailCard?: any;
    learnMoreCard?: any;
    published?: boolean;
    forSale?: boolean;
}

export enum EditorTypes {
    UPDATE_STATE = 'update state key',
    MANAGE_TRACK = 'manage track',
    CLOSE_TRACK_DRAWER = 'close track drawer',
    CHANGE_DRAWER_TYPE = 'change drawer type',
}

export type UploadImageTypes = 'uploadedLearnMoreUri' | 'uploadedGameDetailCardUri' | 'uploadedStoreCardUri';

export type UploadContainerImageTypes = 'uploadedBackgroundUri' | 'uploadedForegroundUri';

export type MapTypes = 'uploadedMapUri';

export type GameId = {
    gameId: string;
};

export interface PlaylistResponse {
    id: string;
    containerId: string;
    gameId: string;
    content: Array<Track>;
}
