import AxiosService from 'core/services/AxiosService';
import { AxiosResponse } from 'axios';
import { Publisher, UpdatePublisherMetadataRequest } from 'core/models/PublisherDefinitions';

class PublisherServiceImpl {
    async getPublishers(): Promise<Array<Publisher>> {
        return new Promise<Array<Publisher>>((resolve, reject) => {
            AxiosService.get('/b/publishers')
                .then((response: AxiosResponse<Array<Publisher>>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async addNewPublisher(data: FormData): Promise<Publisher> {
        return new Promise<Publisher>((resolve, reject) => {
            AxiosService.post('/b/publishers', data)
                .then((response: AxiosResponse<Publisher>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async updatePublisher(publisherId: string, newData: UpdatePublisherMetadataRequest): Promise<Publisher> {
        return new Promise<Publisher>((resolve, reject) => {
            AxiosService.post(`/b/publishers/${publisherId}`, newData)
                .then((response: AxiosResponse<Publisher>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async updatePublisherImage(publisherId: string, logo: File): Promise<Publisher> {
        const imageData = new FormData();
        imageData.set('logo', logo);

        return new Promise<Publisher>((resolve, reject) => {
            AxiosService.post(`/b/publishers/${publisherId}/logo`, imageData)
                .then((response: AxiosResponse<Publisher>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }
}

const PublisherService = new PublisherServiceImpl();
export default PublisherService;
