import { emailSchema } from 'core/validators/email';
import { useSearchAccountByEmail } from 'core/api/users';
import { Box, IconButton, TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import PageContainer from 'components/shared/PageContainer';
import PageTitle from 'components/shared/PageTitle';
import React, { useEffect } from 'react';
import ListAccounts from './ListAccounts';
import { useNarrationState } from 'core/state/narration/narration.state';
import { useAppState } from 'core/state/app.state';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';

const UserManagement = () => {
    const [search, setSearch] = React.useState<string>('');

    const { data: request, refetch: searchAccountByEmail, error, isLoading } = useSearchAccountByEmail(search);
    const accounts = request?.data ?? [];

    const [
        {
            auth: { currentUser },
        },
    ] = useAppState();
    const [{ games, hasError }, narrationDispatch] = useNarrationState();

    useEffect(() => {
        if (games.length === 0 && !hasError && currentUser) {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                dispatcher: narrationDispatch,
            } as FetchGamesRequestAction);
        }
    }, [games, hasError, narrationDispatch, currentUser]);

    const handleSearch = () => {
        const emailValue = (document.getElementById('search-email') as HTMLInputElement)?.value.toLowerCase();
        const validEmailSearch = emailSchema.isValidSync({ email: emailValue });

        if (!validEmailSearch) return;
        setSearch(emailValue);

        setTimeout(() => searchAccountByEmail(), 300);
    };

    return (
        <PageContainer removeSidebar>
            <PageTitle text="User Accounts" />
            <Box maxWidth="500px" mb={3}>
                <TextField
                    id="search-email"
                    name="email"
                    variant="standard"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                    placeholder="Enter an email address to lookup an account"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSearch}>
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {isLoading && <CircularProgress color="primary" />}
            <ListAccounts
                accounts={accounts}
                games={games ?? []}
                error={error as Error | null}
                refetch={searchAccountByEmail}
            />
        </PageContainer>
    );
};

export default UserManagement;
