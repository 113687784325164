import React, { Fragment, FC } from 'react';
import { Track } from 'core/models/Game';
import { DrawerType } from '../../TrackEditorWrapper';
import { Card, useTheme, Grid, Typography } from '@material-ui/core';
import { AddCircle, VolumeUpRounded, CallMergeRounded, ArrowForwardRounded, PanToolRounded } from '@material-ui/icons';
import styled from 'styled-components';
import { convertToTime } from '../../utils';

type Align = 'flex-start' | 'flex-end' | 'center';
interface TracksProps {
    tracks: Array<Track>;
    drawerType: DrawerType;
    changeDrawerType: (type: DrawerType) => void;
    manageTrack: (track: Track, type: DrawerType, currentIndex: number) => void;
}

interface CallbackData {
    track: Track;
    type?: DrawerType;
    currentIndex: number;
}

interface CardData {
    trackData: Track;
    align?: Align;
    start?: boolean | undefined;
    currentIndex: number;
}

const Tracks: FC<TracksProps> = ({ tracks, manageTrack, drawerType }) => {
    const { palette } = useTheme();

    const startTrack = {
        id: 'start',
        title: '',
        asset: null,
        transition: {
            type: 'linear',
            description: '',
            forceEnd: false,
            endType: null,
            childNodes: [],
        },
    };

    const handleInternalManageEvent = (data: CallbackData) => {
        manageTrack(data.track, data.type!, data.currentIndex);
    };

    const renderChildren = (type: string, data: Array<Track>, currentIndex: number, parentTrack: Track) => {
        if (type === 'choice') {
            return decisionBlock(data, parentTrack, currentIndex);
        }
        return renderTrackList(data);
    };

    const renderTrackList = (tracksArray: Array<Track>) => {
        return tracksArray.map((track: Track, index: number) => {
            return (
                <Fragment key={JSON.stringify(track)}>
                    <Card currentIndex={index} trackData={track} />
                    {track.transition.childNodes.length > 0 &&
                        renderChildren(track.transition.type, track.transition.childNodes, index, track)}
                </Fragment>
            );
        });
    };

    const decisionBlock = (tracksArray: Array<Track>, parentTrack: Track, currentIndex: number) => {
        const callbackData: CallbackData = {
            track: parentTrack,
            type: 'editBlock',
            currentIndex,
        };
        return (
            <FlexDiv>
                <FlexContainer>
                    <FlexDiv>
                        <Node style={{ boxShadow: 'none' }} theme={palette} align="center" className="decision">
                            <span onClick={() => handleInternalManageEvent(callbackData)} style={{ cursor: 'pointer' }}>
                                <CallMergeRounded
                                    style={{ transform: 'rotate(180deg)', top: 2, position: 'relative' }}
                                />
                            </span>
                        </Node>
                    </FlexDiv>
                    {tracksArray.map((childTrack: Track, index: number) => (
                        <FlexDiv key={JSON.stringify(childTrack)}>
                            <Card trackData={childTrack} currentIndex={index} />
                            {renderChildren(
                                childTrack.transition.type,
                                childTrack.transition.childNodes,
                                index,
                                parentTrack
                            )}
                        </FlexDiv>
                    ))}
                </FlexContainer>
            </FlexDiv>
        );
    };

    const Card = ({ trackData, currentIndex, start, align }: CardData) => {
        const isParentObject = tracks.find(track => track.id === trackData.id);
        const callbackData: CallbackData = {
            track: trackData,
            currentIndex,
        };

        const progressArrow = () => {
            if (isParentObject || start) {
                const arrayIds = tracks.map((track: Track) => track.id);
                const lastIndex = arrayIds.lastIndexOf(trackData.id);
                const isLastOfArray = lastIndex + 1 === tracks.length;
                return isLastOfArray ? null : <PathIndicator theme={palette} />;
            }

            return !trackData.transition.childNodes.length ? null : <PathIndicator theme={palette} />;
        };

        return (
            <div style={{ position: 'relative', display: trackData.transition.forceEnd ? 'flex' : 'initial' }}>
                <Node
                    start={start ? 'true' : undefined}
                    id={`track-${trackData.id}`}
                    theme={palette}
                    align={align || 'flex-start'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (start) {
                            return null;
                        } else {
                            handleInternalManageEvent({ ...callbackData, type: 'edit' });
                        }
                    }}
                >
                    {start ? (
                        <Typography noWrap>Start</Typography>
                    ) : (
                        <>
                            <StyledHeader theme={palette} container direction="row" alignItems="center" spacing={0}>
                                <Grid item xs={10}>
                                    <Typography noWrap>{trackData.title}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <VolumeUpRounded />
                                </Grid>
                            </StyledHeader>

                            <Grid container spacing={0} direction="column">
                                <Grid item xs={12}>
                                    <Typography noWrap style={{ marginBottom: 0 }}>
                                        Length: {convertToTime(trackData.asset?.duration?.toString() || '0')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography noWrap style={{ margin: '0.5rem 0' }}>
                                        File: {trackData.asset?.name || ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Node>
                {trackData.transition.forceEnd && (
                    <FlexDiv>
                        <Node style={{ boxShadow: 'none' }} theme={palette} align="center" className="force-end">
                            <span>
                                <PanToolRounded />
                            </span>
                        </Node>
                    </FlexDiv>
                )}

                {(trackData.transition.type === 'linear' || isParentObject || start) && !trackData.transition.forceEnd && (
                    <AddNewNodeAction
                        onClick={() =>
                            handleInternalManageEvent({
                                ...callbackData,
                                // type: start ? 'start' : isParentObject ? 'root' : drawerType,
                                type: isParentObject ? 'root' : drawerType, // TODO: remove this test for the above once ready
                            })
                        }
                        style={{ cursor: 'pointer', color: palette.action.active }}
                    />
                )}
                {trackData.transition.type === 'linear' && progressArrow()}
            </div>
        );
    };

    return (
        <TrackDetailsContainer>
            <Card align="center" start currentIndex={-1} trackData={startTrack} />
            {renderTrackList(tracks)}
        </TrackDetailsContainer>
    );
};

const PathIndicator = styled(ArrowForwardRounded)`
    position: absolute;
    top: 125px;
    right: -25px;
    color: ${style => style.theme.text.secondary};
`;

const FlexDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const AddNewNodeAction = styled(AddCircle)`
    position: absolute;
    z-index: 999;
    top: 60px;
    right: -24px;
`;

const TrackDetailsContainer = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-right: 35px;
`;

const StyledHeader = styled(Grid)`
    width: 100%;
    padding: 0;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${style => style.theme.borders.paperColor};

    .MuiCardHeader-action {
        display: flex;
        align-self: center;
    }

    .MuiCardContent-root {
        padding: 15px 20px 0;
    }

    svg {
        color: ${style => style.theme.text.main};
    }
`;

const Node = styled(Card)<{ align?: Align; theme: any; start?: 'true' | undefined }>`
    display: flex;
    width: 210px;
    min-height: 146px;
    align-items: center;
    justify-content: ${style => style.align};
    flex-direction: column;
    height: min-content !important;
    margin-left: ${style => (style.start ? '0' : '24px')};
    margin-bottom: 24px;

    p {
        wrap: nowrap;
    }

    &.decision {
        background-color: transparent !important;

        span {
            background-color: ${style => style.theme.background.paper};
            padding: 3px 3px 0 3px;
            border-radius: 4px;
        }

        svg {
            font-size: 3rem;
            top: 2px;
            position: relative;
            color: ${style => style.theme.success.main};
        }
    }

    &.force-end {
        background-color: transparent !important;
        margin-left: 0px;
        padding: 0;

        span {
            background-color: ${style => style.theme.background.paper};
            padding: 3px 3px 0 3px;
            border-radius: 4px;
        }

        svg {
            font-size: 2.5rem;
            position: relative;
            padding: 8px;
            top: 1px;
            right: 1px;
            color: ${style => style.theme.error.main};
        }
    }
`;

export default Tracks;
