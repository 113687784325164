/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Typography,
    IconButton,
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    Grid,
    Button,
    Box,
    TextField,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useListItemsByGameId } from 'domain/scry/api/communities';
import MediaUploadModalSkeleton from './skeletons/MediaUploadModalSkeleton';
import { MediaItem } from 'domain/scry/models/Community';
import Asset from './Asset';
import { sortByKey } from 'domain/narration/utils';

interface MediaUploadModalProps {
    isOpen: boolean;
    closeAction: () => void;
    selectAction?: (libraryId: string, storageId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: 'inherit',
            maxHeight: '100%',
        },
        content: {
            overflow: 'auto',
            overflowX: 'hidden',
            maxHeight: '100vh',
            height: '100%',
            minHeight: 230,
            padding: '10px 0 20px 0',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        searchInput: {
            '& label.Mui-focused': {
                color: theme.palette.action.active,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.action.active,
                },
            },
        },
    })
);

const MediaUploadModal: FC<MediaUploadModalProps> = ({ isOpen, closeAction, selectAction }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [filterQuery, setFilterQuery] = useState<string>('');
    const { gameId }: { gameId: string } = useParams();
    const classes = useStyles();

    const { data, isFetching } = useListItemsByGameId(gameId);
    const request = data?.data || [];

    const filterItems =
        filterQuery.length > 0
            ? request.filter((i: MediaItem) => i.displayName.toLowerCase().indexOf(filterQuery.toLowerCase()) !== -1)
            : request;
    const items = sortByKey(filterItems as MediaItem[], 'addedAtUtc', true);

    const removePendingUpload = async (): Promise<void> => {
        const newFilesToUploadArr: File[] = [];

        await Promise.all(
            // eslint-disable-next-line array-callback-return
            filesToUpload.map((file: File) => {
                const fileExists = (items as MediaItem[]).find(
                    (item: MediaItem) => item.originalFileName === file.name
                );
                if (!fileExists) {
                    newFilesToUploadArr.push(file);
                }
            })
        );

        setFilesToUpload(newFilesToUploadArr);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (filesToUpload.length > 0) {
            removePendingUpload();
        }
    }, [items]);

    const handleOnFileChange = (event: React.FormEvent<HTMLInputElement>) => {
        const files = Object.values(event?.currentTarget?.files ?? []);
        setFilesToUpload(files);
    };

    const handleClickToUpload = () => {
        const button = document.getElementById('new-uploads');
        if (button) {
            button.click();
        }
    };

    const NoItems = () => (
        <Box padding={2} textAlign="center">
            <Typography color="textSecondary">No Media Found</Typography>
            <Button onClick={handleClickToUpload} color="primary" variant="contained">
                Add Media
            </Button>
        </Box>
    );

    const displayPendingUploads = () => {
        if (filesToUpload.length === 0) {
            return null;
        }

        return filesToUpload.map((file: File) => (
            <Asset gameId={gameId} key={file.lastModified + file.size} preview={file} />
        ));
    };

    const displayMediaAssets = () => {
        return (items as MediaItem[]).map((item: MediaItem, i: number) => (
            <Asset key={i} gameId={gameId} media={item} handleSelect={selectAction} />
        ));
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className={classes.root}>
            <input
                accept="image/png,image/jpg"
                id="new-uploads"
                type="file"
                onChange={handleOnFileChange}
                hidden
                multiple
            />

            <Grid
                style={{ width: '100%', maxWidth: 852 }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={8}>
                    <Typography style={{ margin: 0 }}>Media Assets</Typography>
                </Grid>
                <Grid item>
                    <IconButton aria-label="close" onClick={closeAction}>
                        <CloseRounded />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent dividers className={classes.content}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    style={{ paddingLeft: 8, paddingRight: 8, marginBottom: 10 }}
                >
                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setFilterQuery(event.target.value)
                            }
                            className={classes.searchInput}
                            variant="outlined"
                            placeholder="Type to search for assets"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Box textAlign="right">
                            <Button
                                style={{ height: 50 }}
                                onClick={handleClickToUpload}
                                variant="contained"
                                color="primary"
                            >
                                Add New
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {!data && isFetching && filesToUpload.length === 0 ? (
                    <MediaUploadModalSkeleton />
                ) : (items as Array<MediaItem>).length === 0 && filesToUpload.length === 0 ? (
                    <NoItems />
                ) : (
                    <Grid container justifyContent="flex-start" wrap="wrap" alignItems="center">
                        {displayPendingUploads()}
                        {displayMediaAssets()}
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default MediaUploadModal;
