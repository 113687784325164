import AxiosService from 'core/services/AxiosService';
import { AxiosResponse } from 'axios';
import Guid from 'core/models/Guid';
import { Contribution } from 'core/models/scry/Contribution';
import {
    CreateInteractionRequest,
    InteractionDefinition,
    UpdateInteractionImageRequest,
    UpdateInteractionRequest,
} from 'core/models/scry/InteractionDefinition';
import { PostConfigResponse, ConfigurationStateDefinition } from 'core/models/scry/ConfigurationDefinitions';

export const itemValidationRules: any = {
    name: /^(?=.*[a-zA-Z0-9]).{1,}$/,
    order: /[0-9]/,
    cardUri: /\w{1,}/,
    cost: /\b(?![0]\b)\d{1,}\b/,
    maxQuantity: /\b(?![0]\b)\d{1,}\b/,
};

export const isValid = (expression: any, value: any) => {
    const check = new RegExp(expression);
    return check.test(value);
};

class ScryServiceImpl {
    currentUser: firebase.User | null = null;

    async postGameConfiguration(gameId: Guid, configData: ConfigurationStateDefinition): Promise<PostConfigResponse> {
        return new Promise<PostConfigResponse>((resolve, reject) => {
            AxiosService.post(`/b/scry/${gameId}/config`, configData)
                .then((response: AxiosResponse<PostConfigResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }

    async getGameConfiguration(gameId: Guid): Promise<PostConfigResponse> {
        return new Promise<PostConfigResponse>((resolve, reject) => {
            AxiosService.get(`/b/scry/${gameId}/config`)
                .then((response: AxiosResponse<PostConfigResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }

    async getRecentEngagement(gameId: Guid, minutes: number): Promise<Contribution[]> {
        return new Promise<Contribution[]>((resolve, reject) => {
            AxiosService.get(`/b/scry/${gameId}/activity?dur=${minutes}`)
                .then((response: AxiosResponse<Contribution[]>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }

    async getEngagementSummary(gameId: Guid, start: Date, end: Date): Promise<Contribution[]> {
        return new Promise<Contribution[]>((resolve, reject) => {
            AxiosService.get(`/b/scry/${gameId}/activity/range?start=${start.getTime()}&end=${end.getTime()}`)
                .then((response: AxiosResponse<Contribution[]>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }

    async getEngagementCSV(gameId: Guid, start: Date, end: Date): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            AxiosService.get(`/b/scry/${gameId}/activity/range/export?start=${start.getTime()}&end=${end.getTime()}`)
                .then((response: AxiosResponse) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err.response);
                    reject(err.response);
                });
        });
    }

    async getInteractionsByGame(gameId: Guid): Promise<InteractionDefinition[]> {
        return new Promise<InteractionDefinition[]>((resolve, reject) => {
            AxiosService.get(`/b/scry/${gameId}/interactions`)
                .then((response: AxiosResponse<InteractionDefinition[]>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }

    async addNewInteraction(gameId: Guid, interaction: CreateInteractionRequest): Promise<InteractionDefinition> {
        var data = new FormData();
        data.set('name', interaction.name);
        data.set('category', interaction.category);
        data.set('order', interaction.order.toString());
        data.set('cost', interaction.cost.toString());
        data.set('maxQuantity', interaction.maxQuantity.toString());
        data.set('requireBuyout', interaction.requireBuyout ? 'true' : 'false');

        if (interaction.card) {
            data.set('card', interaction.card);
        }

        return new Promise<InteractionDefinition>((resolve, reject) => {
            AxiosService.post(`/b/scry/${gameId}/interactions`, data)
                .then((response: AxiosResponse<InteractionDefinition>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }

    async updateInteraction(
        gameId: Guid,
        interactionId: Guid,
        update: UpdateInteractionRequest
    ): Promise<InteractionDefinition> {
        return new Promise<InteractionDefinition>((resolve, reject) => {
            AxiosService.put(`/b/scry/${gameId}/interactions/${interactionId}`, update)
                .then((response: AxiosResponse<InteractionDefinition>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }

    async updateInteractionImage(
        gameId: Guid,
        interactionId: Guid,
        update: UpdateInteractionImageRequest
    ): Promise<InteractionDefinition> {
        var data = new FormData();
        data.set('card', update.card);

        return new Promise<InteractionDefinition>((resolve, reject) => {
            AxiosService.put(`/b/scry/${gameId}/interactions/${interactionId}/image`, data)
                .then((response: AxiosResponse<InteractionDefinition>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err.response);
                });
        });
    }
}

const ScryService = new ScryServiceImpl();
export default ScryService;
