import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ScryStateProvider } from 'core/state/scry/scry.state';
import { MenuSlugs } from 'core/services/FeatureService';
import PageContainer from '../../components/shared/PageContainer';

// Routes
import Communities from './Communities';
import ScryManagement from './ScryManagement';
import EditScry from './EditScry';
import ScryGameConfiguration from './GameConfiguration/GameConfiguration';

const GameConfiguration: React.FC = () => {
    const rootRoute = `/${MenuSlugs.Scry}`;

    return (
        <ScryStateProvider>
            <PageContainer removeSidebar>
                <Grid item xs={12}>
                    <Switch>
                        <Route exact path={`${rootRoute}/:gameId/configuration`} component={ScryGameConfiguration} />
                        <Route exact path={`${rootRoute}/:gameId`} component={EditScry} />
                        <Route
                            exact
                            path={[
                                `${rootRoute}/:gameId/communities/add`,
                                `${rootRoute}/:gameId/communities/:communityId`,
                            ]}
                            component={Communities}
                        />
                        <Route path={rootRoute} component={ScryManagement} />
                    </Switch>
                </Grid>
            </PageContainer>
        </ScryStateProvider>
    );
};

export default GameConfiguration;
