import React, { FC } from 'react';
import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import DropToUpload from 'react-drop-to-upload';

interface CsvUploaderProps {
    id: string;
    onDrop: any;
    name: string;
    disabled: boolean;
    label?: string;
    subLabel?: string;
    customHeight?: string;
    buttonLabel?: string;
    subLabelPosition?: 'normal' | 'bottom';
    removeSideGutters?: boolean;
}

const CsvUploader: FC<CsvUploaderProps> = ({
    id,
    onDrop,
    name,
    disabled,
    label,
    subLabel,
    buttonLabel,
    customHeight,
    subLabelPosition = 'normal',
    removeSideGutters,
}) => {
    const { palette } = useTheme();

    const handleDrop = (files: any) => {
        if (disabled) return;
        const reader = new FileReader();
        reader.onload = (event: any) => {
            event.preventDefault();
            onDrop({
                file: files[0],
                blob: URL.createObjectURL(files[0]),
            });
        };
        reader.readAsDataURL(files[0]);
    };

    const handleUpload = (event: any) => {
        handleDrop(event.target.files);
    };

    const handleClickToUpload = () => {
        const el = document.getElementById(id);
        if (el) {
            el.click();
        }
    };

    const dropContainerCss = {
        overflow: 'hidden',
        minHeight: 165,
        height: '100%',
        position: 'relative',
        display: 'flex',
        flex: 1,
        border: `2px dashed ${palette.text.disabled}`,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <UploadContainer
                    theme={palette}
                    style={{ height: customHeight ? customHeight : '100%', padding: removeSideGutters ? '10px 0' : 10 }}
                >
                    <DropToUpload style={dropContainerCss} onDrop={handleDrop}>
                        <HiddenInput type="file" name={name} id={id} accept="text/csv" onChange={handleUpload} />

                        <UploadLabel theme={palette}>
                            {label && <Typography style={{ margin: subLabel ? 0 : '0 0 10px 0' }}>{label}</Typography>}
                            {subLabel && subLabelPosition === 'normal' && (
                                <Typography color="textSecondary" variant="caption" style={{ marginTop: 5 }}>
                                    {subLabel}
                                </Typography>
                            )}
                            <Button
                                onClick={handleClickToUpload}
                                color="primary"
                                variant="contained"
                                disabled={disabled}
                            >
                                {buttonLabel ? buttonLabel : 'Upload'}
                            </Button>
                        </UploadLabel>
                    </DropToUpload>
                </UploadContainer>
            </Grid>
            {subLabel && subLabelPosition === 'bottom' && (
                <Grid item xs={12}>
                    <Typography color="textSecondary" variant="body2" style={{ margin: '0 0 5px' }}>
                        {subLabel}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

const UploadContainer = styled.div`
    background-color: ${style => style.theme.background.paper};
    display: flex;
`;

const UploadLabel = styled.div`
    display: flex;
    text-align: center;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: ${style => style.theme.text.secondary};
    flex-direction: column;

    svg {
        font-size: 3rem;
    }
`;

const HiddenInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    opacity: 0;
`;

export default CsvUploader;
