/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import BlockContentRow from './BlockContentRow';
import AddBlockList from './AddBlockList';
import AddImageBlock from './AddImageBlock';
import AddTagBlock from './AddTagBlock';
import AddTextBlock from './AddTextBlock';
import ManageContentModal from './modals/ManageContent';
import { BlockTypes, Community } from 'domain/scry/models/Community';

interface CommunityDetailBlockProps {
    communities: Array<Community>;
    handleContentBlockChange: (blocks: BlockTypes[]) => void;
}

enum DetailActions {
    SET_META_DATA = 'set meta data',
    TOGGLE_LOADING = 'toggle loading',
    UPDATE_CONTENT_BLOCKS = 'update content blocks',
    SET_ANCHOR = 'set menu anchor',
    TOGGLE_MODAL = 'toggle modal',
    CHANGE_MODAL_PAGE = 'change modal page',
    ADD_NEW_BLOCK = 'add new block',
    EDIT_CONTENT_BLOCK = 'edit contenet block',
}

interface CommunityDetailsState {
    loading: boolean;
    confirmModalOpen: boolean;
    modalPage: string;
    modalOpen: boolean;
    metaData: {
        name: string;
        id: string;
        cardUri: string;
    };
    contentBlocks: Array<BlockTypes>;
    editFields: BlockTypes | null;
    editIndex: number;
}

const initialState = {
    loading: false,
    confirmModalOpen: false,
    modalOpen: false,
    modalPage: 'create',
    metaData: {
        name: '',
        id: '',
        cardUri: '',
    },
    contentBlocks: [],
    editFields: null,
    editIndex: -1,
};

const reducer = (state: CommunityDetailsState, [type, payload]: [DetailActions, any]) => {
    switch (type) {
        case DetailActions.SET_META_DATA:
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    ...payload.meta,
                },
                contentBlocks: payload.blocks,
            };
        case DetailActions.ADD_NEW_BLOCK:
            return {
                ...state,
                contentBlocks: [...state.contentBlocks, payload],
            };
        case DetailActions.CHANGE_MODAL_PAGE:
            return {
                ...state,
                modalPage: payload,
            };
        case DetailActions.TOGGLE_LOADING:
            return {
                ...state,
                loading: payload,
            };
        case DetailActions.UPDATE_CONTENT_BLOCKS:
            return {
                ...state,
                contentBlocks: payload,
            };
        case DetailActions.SET_ANCHOR:
            return {
                ...state,
                anchorEl: payload,
            };
        case DetailActions.TOGGLE_MODAL:
            return {
                ...state,
                modalOpen: payload,
                ...(!payload && {
                    editIndex: -1,
                    editFields: null,
                    modalPage: 'create',
                    metaData: initialState.metaData,
                }),
            };
        case DetailActions.EDIT_CONTENT_BLOCK:
            return {
                ...state,
                editFields: payload.data,
                editIndex: payload.index,
                modalPage: payload.page,
                modalOpen: payload.data ? true : state.modalOpen,
            };
        default:
            return state;
    }
};

const CommunityDetailBlocks: FC<CommunityDetailBlockProps> = ({ communities, handleContentBlockChange }) => {
    const { palette } = useTheme();
    const { communityId }: { communityId: string } = useParams();
    const [{ editIndex, editFields, contentBlocks, modalPage, modalOpen }, dispatch] = useReducer(
        reducer,
        initialState
    );

    useEffect(() => {
        handleContentBlockChange(contentBlocks);
    }, [contentBlocks]);

    useEffect(() => {
        if (communities && communityId) {
            const communityData = communities.find((c: Community) => c.id === communityId);
            dispatch([
                DetailActions.SET_META_DATA,
                {
                    meta: {
                        name: communityData?.name ?? '',
                        id: communityData?.id ?? '',
                        cardUri: communityData?.cardId ?? '',
                    },
                    blocks: communityData?.content ?? [],
                },
            ]);
        }
    }, [communities, communityId]);

    const changePage = (name: string) => {
        dispatch([DetailActions.CHANGE_MODAL_PAGE, name]);
    };

    const updateBlockInDetails = (data: BlockTypes) => {
        const upatedArray = contentBlocks.reduce((acc: Array<BlockTypes>, curr: BlockTypes, blockIndex: number) => {
            if (blockIndex === editIndex) {
                return [...acc, data];
            }
            return [...acc, curr];
        }, []);
        dispatch([DetailActions.UPDATE_CONTENT_BLOCKS, upatedArray]);
        dispatch([DetailActions.TOGGLE_MODAL, false]);
    };

    const modalComponent = (modalPage: string) => {
        switch (modalPage) {
            case 'add-image':
                return (
                    <AddImageBlock
                        add={addBlockToDetails}
                        editFields={editFields}
                        editIndex={editIndex}
                        update={updateBlockInDetails}
                    />
                );
            case 'add-tag':
                return (
                    <AddTagBlock
                        add={addBlockToDetails}
                        editFields={editFields}
                        editIndex={editIndex}
                        update={updateBlockInDetails}
                    />
                );
            case 'add-html':
                return (
                    <AddTextBlock
                        add={addBlockToDetails}
                        editFields={editFields}
                        editIndex={editIndex}
                        update={updateBlockInDetails}
                    />
                );
            default:
                return <AddBlockList changePage={changePage} />;
        }
    };

    const toggleModal = () => {
        dispatch([DetailActions.TOGGLE_MODAL, !modalOpen]);
        if (!modalOpen === false) {
            setTimeout(() => dispatch([DetailActions.CHANGE_MODAL_PAGE, 'create']), 350);
        }
    };

    const addBlockToDetails = (data: BlockTypes) => {
        dispatch([DetailActions.ADD_NEW_BLOCK, data]);
        toggleModal();
    };

    const editContentBlock = (index: number) => {
        dispatch([
            DetailActions.EDIT_CONTENT_BLOCK,
            {
                data: contentBlocks[index],
                page: `add-${contentBlocks[index].contentType.toLowerCase()}`,
                index,
            },
        ]);
    };

    const removeBlock = (index: number) => {
        const updatedArray = contentBlocks.filter((c: BlockTypes, blockIndex: number) => blockIndex !== index);
        dispatch([DetailActions.UPDATE_CONTENT_BLOCKS, updatedArray]);
    };

    const moveBlock = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = contentBlocks[dragIndex];
            dispatch([
                DetailActions.UPDATE_CONTENT_BLOCKS,
                update(contentBlocks, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            ]);
        },
        [contentBlocks]
    );

    return (
        <>
            <Grid container style={{ marginTop: 35 }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        style={{ borderBottom: `1px solid ${palette.borders.paperColor}`, marginBottom: 20 }}
                    >
                        <Grid item>
                            <Typography variant="h2">Community Details</Typography>
                        </Grid>
                        <Grid item style={{ textAlign: 'right' }}>
                            <NewBlockButton theme={palette} variant="contained" color="default" onClick={toggleModal}>
                                Add New Content Block
                            </NewBlockButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <DndProvider backend={HTML5Backend}>
                        {contentBlocks.map((block: any, index: number) => (
                            <BlockContentRow
                                key={JSON.stringify(block)}
                                blockIndex={index}
                                block={block}
                                edit={editContentBlock}
                                removeBlock={removeBlock}
                                moveBlock={moveBlock}
                            />
                        ))}
                    </DndProvider>
                </Grid>
            </Grid>
            <ManageContentModal
                toggleModal={toggleModal}
                changePage={changePage}
                pageComponent={modalComponent(modalPage)}
                editIndex={editIndex}
                modalOpen={modalOpen}
                modalPage={modalPage}
            />
        </>
    );
};

const NewBlockButton = styled(Button)`
    background-color: ${style => style.theme.background.paper};
    font-size: 0.725rem;
    color: ${style => style.theme.text.main};

    &:hover {
        background-color: ${style => style.theme.background.paper} !important;
    }
`;

export default CommunityDetailBlocks;
