import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { MenuSlugs } from 'core/services/FeatureService';
import ListView from './GameListView';

import { ContainerStateProvider } from 'core/state/narration/container.state';

import GameDetails from './GameManagementWrapper';
import ItemEditor from './TrackEditorWrapper';
import ContainerEditor from './Containers/ContainerEditor';
import AssetContainer from './Media/MediaWrapper';
import CreateContainer from './Containers/CreateContainer';

const NarrationManagementContainer: React.FC = () => {
    const rootRoute = `/${MenuSlugs.Narration}`;
    return (
        <ContainerStateProvider>
            <Switch>
                <Route exact path={`${rootRoute}/:gameId/containers`} component={ContainerEditor} />
                <Route
                    exact
                    path={[`${rootRoute}/:gameId/containers/add`, `${rootRoute}/:gameId/containers/:containerId`]}
                    component={CreateContainer}
                />

                <Route exact path={`${rootRoute}/:gameId/media`} component={AssetContainer} />
                <Route exact path={`${rootRoute}/:gameId/:containerId/items`} component={ItemEditor} />
                <Route exact path={[`${rootRoute}/add`, `${rootRoute}/:gameId`]} component={GameDetails} />
                <Route exact path={rootRoute} component={ListView} />
            </Switch>
        </ContainerStateProvider>
    );
};

export default NarrationManagementContainer;
