import { Action } from '../app.actions';

export enum ScryActions {
    CHANGE_IS_BUSY = 'Change IsBusy',
    FETCH_INTERACTION = 'fetch interaction',
    ADD_NEW_INTERACTION = 'add new interaction',
    GET_GAME_DATA = 'get game data',
    GET_ENGAGEMENT_SUMMARY = 'get engagement summary',
    UPSTREAM_RECENT_ENGAGEMENTS = 'upstream recent engagements',
    GET_GAME_CONFIGURATION = 'get game configuration',
}

export enum InteractionActions {
    CHANGE_TAB = 'change tab',
    TOGGLE_MODAL = 'toggle modal',
    UPDATE_FORM = 'update form',
    SET_MAX_QTY = 'set max qty',
    UPLOAD_CARD_URI = 'upload cardUri',
    RESET_STATE = 'reset state',
    SET_INIT_STATE = 'set initial state',
    TOGGLE_LOADING = 'toggle loading',
}
export interface ChangeIsBusyAction extends Action {
    isBusy: boolean;
}
