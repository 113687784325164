/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, Grid, CircularProgress, Button } from '@material-ui/core';

import { useAppState } from 'core/state/app.state';
import {
    FetchPublisherRequestAction,
    PublisherActions,
    Publisher,
    FetchPublisherDoneAction,
} from 'core/state/publishers/publisher.actions';
import { MenuSlugs } from 'core/services/FeatureService';

import PublisherForm from './components/PublisherForm';
import PublisherMediaForm from './components/PublisherMediaForm';

import SubNavigation from 'components/main-menu/SubNavigation';
import PublisherSubMenu from './components/SubMenu';
import PageContainer from 'components/shared/PageContainer';
import PageBreadcrumbs from 'components/shared/BreadCrumbs';
import { NewImageBlob } from 'core/models/Game';

import { publisherSchema } from 'core/validators/publishers-yup-validator';
import PublisherService from 'core/services/PublisherService';
import { AppActions } from 'core/state/app.actions';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';

interface PublisherDetailsState extends Publisher {
    uploadedLogoUri: NewImageBlob | null;
}

interface PublisherRefName {
    current: string | null;
}

const ManagePublisherInfo: FC = () => {
    const publisherNameRef: PublisherRefName = useRef(null);
    const [loading, setLoading] = useState(false);
    const [publisherDetails, setPublisherDetails] = useState<PublisherDetailsState>({
        id: '',
        name: '',
        aboutUri: '',
        logoUri: '',
        uploadedLogoUri: null,
    });
    const { push } = useHistory();
    const [{ publishers, auth }, appDispatch]: any = useAppState();
    const { publisherId }: { publisherId: string } = useParams();

    const fetchListOfPublishers = async () => {
        publishers.dispatch({
            type: PublisherActions.FETCH_PUBLISHERS_REQUEST,
            dispatcher: publishers.dispatch,
        } as FetchPublisherRequestAction);
    };

    useEffect(() => {
        if (publishers.ready === false && auth.currentUser) {
            fetchListOfPublishers();
        }
    }, [publishers.ready, auth.currentUser]);

    const getDataByPublisher = () => {
        const publisher = publishers.list.find((p: Publisher) => p.id === publisherId);
        if (publisher) {
            publisherNameRef.current = publisher.name;
            setPublisherDetails({ ...publisherDetails, ...publisher });
        }
    };

    useEffect(() => {
        if (publishers.ready && publishers.list.length > 0) {
            getDataByPublisher();
        }
    }, [publishers]);

    const handleChange = (event: any) => {
        const { id, value } = event.target;
        setPublisherDetails({
            ...publisherDetails,
            [id]: value,
        });
    };

    const handleImageUpload = (files: any) => {
        setPublisherDetails({
            ...publisherDetails,
            uploadedLogoUri: files,
        });
    };

    const createNewPublisher = async () => {
        const { name, aboutUri, uploadedLogoUri, id } = publisherDetails;
        let formData = new FormData();

        formData.set('name', name);
        formData.set('aboutUri', aboutUri);
        formData.set('logo', uploadedLogoUri!.file[0]);

        try {
            const newPublisher: Publisher = await PublisherService.addNewPublisher(formData);
            const hydratePublisherList = await PublisherService.getPublishers();
            const redirect: boolean = id === '';

            publishers.dispatch({
                type: PublisherActions.FETCH_PUBLISHERS_DONE,
                publishers: hydratePublisherList,
            } as FetchPublisherDoneAction);

            publisherNameRef.current = name;
            setPublisherDetails({ uploadedLogoUri: null, ...newPublisher });

            if (redirect) {
                setTimeout(() => push(`/publishers/edit/${newPublisher.id}`), 500);
            }
        } catch (err) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'There was an issue adding this publisher. Please try again',
                status: 'error',
                open: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const updatePublisher = async () => {
        const { name, aboutUri, uploadedLogoUri, id } = publisherDetails;
        let snackMessage: string = 'Publisher has been updated successfully';
        let snackStatus: 'error' | 'success' = 'success';

        try {
            await PublisherService.updatePublisher(id, { name, aboutUri });

            if (uploadedLogoUri?.file) {
                await PublisherService.updatePublisherImage(id, uploadedLogoUri.file[0]);
            }
        } catch {
            snackMessage = 'There was an issue updating this publisher. Please try again';
            snackStatus = 'error';
        } finally {
            if (snackStatus === 'success') {
                // rehydrate publisher list
                fetchListOfPublishers();
            }
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: snackMessage,
                status: snackStatus,
                open: true,
            });
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (publisherDetails.id === '') {
            createNewPublisher();
        } else {
            updatePublisher();
        }
    };

    const formDisabled = () => {
        const { name, uploadedLogoUri, aboutUri } = publisherDetails;
        if (loading) {
            return true;
        }
        const isValid = publisherSchema.isValidSync({ name, aboutUri });

        if (!publisherId) {
            return !isValid || uploadedLogoUri === null;
        }
        return !isValid;
    };

    const pageName = () => {
        if (publisherNameRef.current !== '') {
            return publisherNameRef.current;
        }
        if (publisherNameRef.current === '') {
            return publisherDetails.id === '' ? 'New Publisher' : '';
        }
        return '';
    };

    return (
        <>
            <SubNavigation title="Publisher Info" navLinks={PublisherSubMenu(publisherDetails.id, 'summary')} />
            <PageContainer>
                <PageBreadcrumbs
                    links={[{ label: 'Publishers', to: `/${MenuSlugs.Publishers}` }, { label: pageName() as string }]}
                />

                {publishers.ready === false ? (
                    <CircularProgress color="secondary" size="3rem" />
                ) : (
                    <>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h1">
                                    {!publisherNameRef.current ? '' : publisherNameRef.current}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={5}>
                                    <Grid item>
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={formDisabled()}
                                            variant="contained"
                                            color="primary"
                                        >
                                            <ProgressiveButtonText text="Save Changes" isLoading={loading} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <PublisherForm handleChange={handleChange} publisherDetails={publisherDetails} />
                        <PublisherMediaForm
                            handleImageUpload={handleImageUpload}
                            previewUrls={{ logoUri: publisherDetails.logoUri }}
                            uploadedImageUrls={{ uploadedLogoUri: publisherDetails.uploadedLogoUri }}
                        />
                    </>
                )}
            </PageContainer>
        </>
    );
};

export default ManagePublisherInfo;
