import AxiosService from 'core/services/AxiosService';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

export interface Backer {
    displayName: string;
    email: string;
}

export interface RedeemBackersRequest {
    sku: string;
    imageUrl: string;
    backers: Backer[];
}

export const useRedeemBackers = (): UseMutationResult<
    AxiosResponse<AxiosResponse>,
    unknown,
    RedeemBackersRequest,
    unknown
> => {
    return useMutation(
        'redeem/backers',
        async (body: RedeemBackersRequest) => await AxiosService.post<AxiosResponse>('/orders/redeem/backers', body)
    );
};
