import { AppState } from './app.state';
import {
    AppActionTypes,
    AppActions,
    CurrentUserChangeAction,
    ToastSnackAction,
    FeaturesUpdateRequestAction,
    FeaturesUpdateResponseAction,
    ChangeActiveFeatureAction,
} from './app.actions';

import FeatureService from '../services/FeatureService';
import UserService from '../services/UserService';

export const appReducer = (state: AppState, action: AppActionTypes): AppState => {
    let newState: AppState;

    switch (action.type) {
        case AppActions.TOAST_SNACK:
            newState = toastSnack(state, action as ToastSnackAction);
            break;

        case AppActions.CURRENT_USER_CHANGED:
            newState = changeCurrentUser(state, action as CurrentUserChangeAction);
            break;

        case AppActions.FEATURES_UPDATE_REQUEST:
            featuresUpdateRequest(action as FeaturesUpdateRequestAction);
            return state;

        case AppActions.FEATURES_UPDATE_RESPONSE:
            newState = changeFeaturesAvailable(state, action as FeaturesUpdateResponseAction);
            break;

        case AppActions.CHANGE_ACTIVE_FEATURE:
            newState = changeActiveFeature(state, action as ChangeActiveFeatureAction);
            break;

        case AppActions.SIGN_OUT:
            signout();
            return state;

        default:
            return state;
    }

    return {
        ...newState,
    };
};

//=============================================================================
function changeCurrentUser(currentState: AppState, { currentUser }: CurrentUserChangeAction): AppState {
    return {
        ...currentState,
        auth: {
            currentUser,
        },
    };
}

//=============================================================================
function toastSnack(currentState: AppState, action: ToastSnackAction): AppState {
    return {
        ...currentState,
        snack: {
            open: action.open,
            message: action.message,
            status: action.status,
        },
    };
}

//=============================================================================
function featuresUpdateRequest({ dispatcher }: FeaturesUpdateRequestAction): void {
    FeatureService.getAllowed()
        .then(result => {
            dispatcher({
                type: AppActions.FEATURES_UPDATE_RESPONSE,
                features: result,
            } as FeaturesUpdateResponseAction);
        })
        .catch(error => {
            console.error('Error checking user claims', error);
        });
}

//=============================================================================
function changeFeaturesAvailable(currentState: AppState, action: FeaturesUpdateResponseAction): AppState {
    return {
        ...currentState,
        features: action.features,
    };
}

//=============================================================================
function changeActiveFeature(currentState: AppState, { current: activeFeature }: ChangeActiveFeatureAction): AppState {
    return {
        ...currentState,
        activeFeature,
    };
}

//=============================================================================
function signout(): void {
    UserService.logout();
}
