/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useReducer, useCallback, useRef } from 'react';
import { Grid, Button, Typography, FormControlLabel, Switch, CircularProgress, useTheme } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { MenuSlugs } from 'core/services/FeatureService';

import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useNarrationState } from 'core/state/narration/narration.state';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';
import { narrationGameSchema } from 'core/validators/games-yup-validators';
import Game, {
    GameId,
    EditDispatchTypes,
    GameDetailsState,
    NewImageBlob,
    UploadImageTypes,
    CreateGameRequest,
    GameTypes,
} from 'core/models/Game';
import CatalogService from 'core/services/CatalogService';

// common layout components
import SubNavigation from '../../components/main-menu/SubNavigation';
import { NarrationSubMenu } from './components/manifest-editor/menus/SubMenu';
import PageContainer from 'components/shared/PageContainer';
import PageBreadcrumbs from 'components/shared/BreadCrumbs';
import { FetchPublisherRequestAction, PublisherActions } from 'core/state/publishers/publisher.actions';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';

import GameMetaData from './components/forms/GameMetaData';
import GameMediaForm from './components/forms/GameMediaForm';

const defaultGameState = {
    id: '',
    name: '',
    storeCardUri: '',
    gameDetailCardUri: '',
    learnMoreUri: '',
    type: GameTypes.AudioNarration,
    aboutUri: '',
    price: 0,
    published: true,
    forSale: true,
    sku: '',
    publisherId: '',
};

const initialState: GameDetailsState = {
    loading: false,
    game: null,
    uploadedLearnMoreUri: null,
    uploadedGameDetailCardUri: null,
    uploadedStoreCardUri: null,
};

const reducer = (state: GameDetailsState, [type, payload]: [EditDispatchTypes, any]) => {
    switch (type) {
        case EditDispatchTypes.SET_GAME_DATA:
            return {
                ...state,
                loading: false,
                game: payload,
            };
        case EditDispatchTypes.RESET_UPLOADED_IMAGE_FIELDS:
            return {
                ...state,
                uploadedLearnMoreUri: null,
                uploadedGameDetailCardUri: null,
                uploadedStoreCardUri: null,
            };
        case EditDispatchTypes.UPDATE_FIELD:
            return {
                ...state,
                game: {
                    ...state.game,
                    [payload.name]: payload.value,
                },
            };
        case EditDispatchTypes.UPLOAD_IMAGE:
            return {
                ...state,
                [payload.name]: {
                    file: payload.value.file[0],
                    blob: payload.value.blob,
                },
            };
        case EditDispatchTypes.SET_LOADING:
            return {
                ...state,
                loading: payload,
            };
        default:
            console.log('YOU DIDN"T DISPATCH A MESSAGE');
            return state;
    }
};

const GameDetails: FC = () => {
    const { push } = useHistory();
    const { palette } = useTheme();
    const { gameId }: GameId = useParams();

    const [{ games, hasError }, narrationDispatch] = useNarrationState();
    const [{ publishers, auth }, appDispatch]: any = useAppState();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { game, uploadedLearnMoreUri, uploadedGameDetailCardUri, uploadedStoreCardUri } = state;
    const gameNameRef = useRef('');
    const gameDataRef: any = useRef(null);

    const fetchGames = () => {
        narrationDispatch({
            type: NarrationActions.FETCH_GAMES_REQUEST,
            dispatcher: narrationDispatch,
        } as FetchGamesRequestAction);
    };

    useEffect(() => {
        if (games.length === 0 && !hasError && auth.currentUser) {
            fetchGames();
        }
    }, [games, hasError, narrationDispatch, auth.currentUser]);

    const getPublisherList = async () => {
        publishers.dispatch({
            type: PublisherActions.FETCH_PUBLISHERS_REQUEST,
            dispatcher: publishers.dispatch,
        } as FetchPublisherRequestAction);
    };
    useEffect(() => {
        if (publishers.ready === false && auth.currentUser) {
            getPublisherList();
        }
    }, [publishers.ready, auth.currentUser]);

    const setGameDetails = useCallback(() => {
        const currentGame = games.find(game => game.id === gameId);
        gameNameRef.current = currentGame?.name || '';
        gameDataRef.current = currentGame;

        const gameDetails = currentGame ? currentGame : defaultGameState;
        dispatch([EditDispatchTypes.SET_GAME_DATA, gameDetails]);
    }, [games, gameId]);

    useEffect(() => {
        if (games.length > 0 && !hasError) {
            setGameDetails();
        }
    }, [games, hasError, setGameDetails]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { type, checked, name, value } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        dispatch([EditDispatchTypes.UPDATE_FIELD, { name: name, value: newValue }]);
    };

    const handleImageUpload = (event: NewImageBlob, imageType: UploadImageTypes) => {
        dispatch([EditDispatchTypes.UPLOAD_IMAGE, { name: imageType, value: event }]);
    };

    const previewUrls = {
        learnMoreUri: game?.learnMoreUri || '',
        gameDetailCardUri: game?.gameDetailCardUri || '',
        storeCardUri: game?.storeCardUri || '',
    };

    const pageName = () => {
        if (gameNameRef.current !== '') {
            return gameNameRef.current;
        }
        if (gameNameRef.current === '') {
            return game ? 'New Narration Game' : '';
        }
        return '';
    };

    const isFormDisabled = () => {
        /**
         * Validate Images:
         * If the image keys (storeCardUri, gameDetailCardUri, learnMoreUri) are blank strings,
         * the corresponding uploadedImage object cannot be NULL
         */
        const imagesValidate = [
            { imageName: 'storeCardUri', newImage: uploadedStoreCardUri },
            { imageName: 'gameDetailCardUri', newImage: uploadedGameDetailCardUri },
            { imageName: 'learnMoreUri', newImage: uploadedLearnMoreUri },
        ]
            .reduce(
                (acc: Array<boolean>, curr: { imageName: string; newImage: null | NewImageBlob }) => [
                    ...acc,
                    game[curr.imageName] === '' && !curr.newImage ? false : true,
                ],
                []
            )
            .every(image => image);

        /**
         * Validate form fields
         */
        const validateFormFields = narrationGameSchema.isValidSync({
            name: game.name,
            aboutUri: game.aboutUri,
            price: game.price,
            sku: game.sku,
        });

        /**
         * Either imagesValidate or validateFormFields returning false means that there is a field
         * that hasn't validated. If either of them aren't valid, return TRUE -- meaning the form IS disabled
         */
        const imagesAlsoValidate = game.id === '' ? imagesValidate === false : false;
        return validateFormFields === false || imagesAlsoValidate;
    };

    const uploadImage = async (image: any, type: string, requestId?: string) => {
        try {
            await CatalogService.updateCardImage(image as File, type, requestId ? requestId : game.id);
        } catch (error) {
            snackMessage(`There was an error adding image for: ${type}`, 'error');
        }
    };

    const onSubmit = async () => {
        const newImagesUploaded = () => {
            const anyNewImages = [uploadedLearnMoreUri, uploadedGameDetailCardUri, uploadedStoreCardUri].reduce(
                (acc: Array<boolean>, curr: NewImageBlob | null) => [...acc, curr ? true : false],
                []
            );
            return !anyNewImages.every(image => image === false);
        };

        if (gameDataRef.current === game && !newImagesUploaded()) {
            return false;
        }

        let request: Game;
        let responseMessage = 'Updated has been completed.';
        let responseType = 'success';

        const { id, name, publisherId, aboutUri, price, sku, published, forSale } = game;
        let formData: CreateGameRequest = {
            name,
            publisherId,
            type: GameTypes.AudioNarration,
            aboutUri,
            price: id === '' ? parseInt(price) : price.toString(),
            sku,
            ...(id !== '' && { id, published, forSale }),
        };

        try {
            let fetchedAnyAPI: boolean = false;

            dispatch([EditDispatchTypes.SET_LOADING, true]);

            if (game.id) {
                fetchedAnyAPI = true;
                request = await CatalogService.updateGame(formData);
                gameDataRef.current = request;
            } else {
                request = await CatalogService.addNewGame(formData);
            }

            // hit the /card API for each of them that are applicable to update it
            if (state.uploadedStoreCardUri) {
                await uploadImage(state.uploadedStoreCardUri.file, 'storeCard', request.id);
                fetchedAnyAPI = true;
            }

            if (state.uploadedGameDetailCardUri) {
                await uploadImage(state.uploadedGameDetailCardUri.file, 'gameDetailCard', request.id);
                fetchedAnyAPI = true;
            }

            if (state.uploadedLearnMoreUri) {
                await uploadImage(state.uploadedLearnMoreUri.file, 'learnMoreCard', request.id);
                fetchedAnyAPI = true;
            }

            if (fetchedAnyAPI) {
                // fetch the full list of games to rehydrate
                narrationDispatch({
                    type: NarrationActions.FETCH_GAMES_REQUEST,
                    dispatcher: narrationDispatch,
                } as FetchGamesRequestAction);

                setTimeout(async () => {
                    // give it a sec
                    await new Promise(resolve => {
                        // clear out uploaded images
                        dispatch([EditDispatchTypes.RESET_UPLOADED_IMAGE_FIELDS, null]);
                        resolve({});
                    });
                }, 500);

                /**
                 * Redirect to edit game page
                 * We're putting this in a setTimeout to give it time since we can't 'await' the dispatch call
                 */
                if (!game.id) {
                    setTimeout(() => push(`/games/narration/${request.id}`), 500);
                }
                snackMessage(responseMessage, responseType);
            }
        } catch (err) {
            responseMessage = `There was an error ${game.id ? 'updating' : 'adding'} game: ${name}`;
            responseType = 'error';
            snackMessage(responseMessage, responseType);
        } finally {
            dispatch([EditDispatchTypes.SET_LOADING, false]);
        }
    };

    const snackMessage = (message: string, status: string) => {
        appDispatch({
            type: AppActions.TOAST_SNACK,
            message: message,
            status: status,
            open: true,
        } as ToastSnackAction);
    };

    return (
        <>
            <SubNavigation title="Game Info" navLinks={NarrationSubMenu(gameId, 'summary')} />
            <PageContainer>
                <PageBreadcrumbs
                    links={[{ label: 'Narration', to: `/${MenuSlugs.Narration}` }, { label: pageName() }]}
                />

                {game ? (
                    <>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h1">{gameNameRef.current}</Typography>
                            </Grid>
                            <Grid item>
                                {game.id === '' && (
                                    <Button
                                        variant="text"
                                        onClick={() => push(`/${MenuSlugs.Narration}`)}
                                        color="primary"
                                        style={{ padding: '0 20px' }}
                                    >
                                        Discard Changes
                                    </Button>
                                )}
                                <Button
                                    onClick={onSubmit}
                                    disabled={isFormDisabled()}
                                    variant="contained"
                                    color="primary"
                                >
                                    <ProgressiveButtonText isLoading={state.loading} text="Save Changes" />
                                </Button>
                            </Grid>
                        </Grid>
                        {game.id !== '' && (
                            <Grid container direction="row" style={{ margin: '20px 0' }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={handleChange}
                                            checked={game.published}
                                            name="published"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Display in Catalog"
                                    style={{
                                        marginRight: 17,
                                        paddingRight: 17,
                                        borderRight: `1px solid ${palette.borders.paperColor}`,
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={handleChange}
                                            name="forSale"
                                            checked={game.forSale}
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="end"
                                    label="For Sale"
                                />
                            </Grid>
                        )}

                        <GameMetaData
                            snackMessage={snackMessage}
                            fetchGames={fetchGames}
                            handleChange={handleChange}
                            game={game}
                            publishers={publishers.list}
                        />
                        <GameMediaForm
                            previewUrls={previewUrls}
                            handleImageUpload={handleImageUpload}
                            uploadedImageUrls={{
                                uploadedLearnMoreUri,
                                uploadedGameDetailCardUri,
                                uploadedStoreCardUri,
                            }}
                        />
                    </>
                ) : (
                    <CircularProgress color="secondary" />
                )}
            </PageContainer>
        </>
    );
};

export default GameDetails;
