import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Typography, TextField, useTheme, InputAdornment, IconButton, Button } from '@material-ui/core';
import { SearchRounded, CloseRounded } from '@material-ui/icons';
import CommunityCarouselSkeleton from './skeleton';

// Carousel
import Whirligig from 'react-whirligig';
import { Community } from 'domain/scry/models/Community';

interface CommunityCarouselProps {
    communities: Array<Community>;
    gameId: string;
    isFetching: boolean;
}

const CommunityCarousel: FC<CommunityCarouselProps> = ({ gameId, communities, isFetching }) => {
    const { palette } = useTheme();
    const { push } = useHistory();
    const [searchbar, toggleSearchbar] = useState(false);
    const [searchFilter, filterSearch] = useState('');

    const handleSearchClick = () => {
        toggleSearchbar(!searchbar);

        if (searchbar) {
            filterSearch('');
        }
    };

    const items = () => {
        let results: Array<Community>;

        results =
            searchFilter.length > 0
                ? communities.filter((r: any) => r.name.toLowerCase().indexOf(searchFilter) !== -1)
                : communities;
        if (results.length <= 0)
            return (
                <Typography variant="body1" align="center">
                    No communities found
                </Typography>
            );
        return results.map(({ name, cardId, id }: Community) => {
            return (
                <div className="content-wrapper" onClick={() => push(`/games/scry/${gameId}/communities/${id}`)}>
                    <img
                        src={`${process.env.REACT_APP_IMAGE_CDN_URL}/${cardId.replace(/-/g, '')}`}
                        alt={`${name} communities slider`}
                        style={{ maxWidth: '100%' }}
                    />
                    <Typography variant="body1" align="center">
                        {name}
                    </Typography>
                </div>
            );
        });
    };

    const Gallery = () => {
        if (isFetching) {
            return <CommunityCarouselSkeleton />;
        }
        return <Whirligig className="whirligig-wrapper">{items}</Whirligig>;
    };

    return (
        <Wrapper container theme={palette}>
            <Header justifyContent="space-between" alignItems="center" container direction="row" theme={palette}>
                <Grid item xs={6}>
                    <Typography variant="h2">Communities</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'end' }}>
                    <StyledSearchBar
                        id="communityFilter"
                        name="communityFilter"
                        autoComplete="off"
                        className={searchbar ? 'openSearch' : 'closedSearch'}
                        value={searchFilter}
                        onChange={(event: { target: { value: string } }) =>
                            filterSearch(event.target.value.toLowerCase())
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton style={{ padding: 0 }} onClick={handleSearchClick}>
                                        {!searchFilter.length ? <SearchRounded /> : <CloseRounded />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <AddButton
                        color="primary"
                        variant="contained"
                        onClick={() => push(`/games/scry/${gameId}/communities/add`)}
                    >
                        Add
                    </AddButton>
                </Grid>
            </Header>
            <Slider item xs={12} theme={palette}>
                {Gallery()}
            </Slider>
        </Wrapper>
    );
};

const Wrapper = styled(Grid)`
    background-color: ${style => style.theme.background.paper};
    height: 100%;
    border-radius: 3px;
`;

const Header = styled(Grid)`
    padding: 5px 30px;
    border-bottom: 1px solid ${style => style.theme.background.secondaryPaper};
    max-height: 62px;
`;

const StyledSearchBar = styled(TextField)`
    border-radius: 3px;

    &.closedSearch {
        direction: ltr;
        width: 40px;
        overflow: hidden;
        -webkit-transition: width 0.25s;
        -moz-transition: width 0.25s;
        -o-transition: width 0.25s;
        transition: width 0.25s;
    }
    &.openSearch {
        width: calc(100% - 75px);
        -webkit-transition: width 0.25s;
        -moz-transition: width 0.25s;
        -o-transition: width 0.25s;
        transition: width 0.25s;
    }

    .MuiInputBase-root {
        margin: 0;
    }
    .MuiInputBase-input {
        padding: 12px;
        flex: auto;
    }
    .MuiInputAdornment-root {
        max-height: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        width: 40px;

        button {
            height: 100%;
            width: 100%;
        }
    }
    .MuiInputAdornment-positionEnd {
        position: absolute;
        right: 0;
    }
`;

const AddButton = styled(Button)`
    height: 40px;
    margin-left: 10px;
    border-radius: 3px;
`;

const Slider = styled(Grid)`
    padding: 5px 30px;

    .whirligig-wrapper {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        justify-content: flex-start !important;

        /* width */
        &::-webkit-scrollbar {
            height: 8px;
            border-radius: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
    }

    div[role='listitem'] {
        width: 200px !important;
        display: flex;
    }

    .content-wrapper {
        background-color: ${style => style.theme.background.secondaryPaper};
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover {
            cursor: pointer;
        }

        img {
            border-radius: 3px;
        }
        p {
            margin: 5px 0;
        }
    }
`;

export default CommunityCarousel;
