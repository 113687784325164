/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Game, { GameId } from 'core/models/Game';
import { Typography, Grid, TextField, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { SearchRounded, CloseRounded } from '@material-ui/icons';

import SubNavigation from 'components/main-menu/SubNavigation';
import PageContainer from 'components/shared/PageContainer';

import { NarrationSubMenu } from '../components/manifest-editor/menus/SubMenu';
import { MenuSlugs } from 'core/services/FeatureService';
import { GameAsset, useNarrationState } from 'core/state/narration/narration.state';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';

import AssetItem from '../components/assets/AssetItem';
import UploadAssetsContainer from '../components/assets/UploadAssetContainer';
import PageBreadcrumbs from 'components/shared/BreadCrumbs';
import Asset, { Job } from 'core/models/Asset';
import { useAppState } from 'core/state/app.state';
import CatalogService from 'core/services/CatalogService';
import { copyToClipboard } from '../utils';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';

interface MediaState {
    gameData: Game | null;
    gameAssets: Array<Asset> | null;
    activeJobs: Array<Job> | null;
}

enum MediaActionTypes {
    SET_GAME_DATA = 'set game data',
    SET_ASSET_DATA = 'set asset data',
    SET_JOBS = 'set jobs',
}

const initialState: MediaState = {
    gameData: null,
    gameAssets: null,
    activeJobs: null,
};

type ActionTypes = Array<Asset> | Game | string | Array<Job>;

const reducer = (state: MediaState, [type, payload]: [MediaActionTypes, ActionTypes]): MediaState => {
    switch (type) {
        case MediaActionTypes.SET_GAME_DATA:
            return {
                ...state,
                gameData: payload as Game,
            };
        case MediaActionTypes.SET_JOBS:
            return {
                ...state,
                activeJobs: payload as Array<Job>,
            };
        case MediaActionTypes.SET_ASSET_DATA:
            return {
                ...state,
                gameAssets: payload as Array<Asset>,
            };
        default:
            return state;
    }
};

const AssetContainer: FC = () => {
    const { gameId }: GameId = useParams();
    const [{ auth }, appDispatch] = useAppState();
    const [{ games, media }, narrationDispatch] = useNarrationState();
    const [{ gameData, gameAssets, activeJobs }, dispatch] = useReducer(reducer, initialState);
    const [searchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            getGameData();
        }
    }, [games, auth.currentUser]);

    const fetchActiveJobs = async () => {
        try {
            const jobsResponse = await CatalogService.getAciveJobs(gameId);
            dispatch([MediaActionTypes.SET_JOBS, jobsResponse]);
        } catch (err) {
            console.log('ERROR', err);
        }
    };

    useEffect(() => {
        if (auth.currentUser) {
            if (!activeJobs) {
                fetchActiveJobs();
            }
        }
    }, [activeJobs, auth.currentUser]);

    const copyMediaKey = (key: string) => {
        const copied: boolean = copyToClipboard(key);
        if (copied) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'copied to clipboard',
                status: 'success',
                open: true,
            } as ToastSnackAction);
        }
    };

    const getGameData = () => {
        if (gameId && games.length > 0) {
            const currentGame = games.find((g: Game) => g.id === gameId);
            dispatch([MediaActionTypes.SET_GAME_DATA, currentGame as Game]);
        } else {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                dispatcher: narrationDispatch,
            } as FetchGamesRequestAction);
        }
    };

    const fetchMediaAssets = () => {
        narrationDispatch({
            type: NarrationActions.FETCH_MEDIA_ASSETS,
            gameId,
            dispatcher: narrationDispatch,
        });
    };

    useEffect(() => {
        if (auth.currentUser) {
            fetchMediaAssets();
        }
    }, [auth.currentUser]);

    useEffect(() => {
        if (media) {
            const assetData = media?.find((asset: GameAsset) => asset.id === gameId);
            dispatch([MediaActionTypes.SET_ASSET_DATA, assetData?.data || []]);
        }
    }, [media]);

    const orderedMediaList = (list: Array<Asset>) => {
        let newList: Array<Asset> = list;
        // if there's a typed filter, do that here
        if (searchFilter.length > 0) {
            newList = newList.filter(a => a.name.toLowerCase().indexOf(searchFilter) !== -1);
        }
        return newList;
    };

    const breacrumbList = [
        { label: 'Narration', to: `/${MenuSlugs.Narration}` },
        { label: gameData?.name || '...' },
        { label: 'Assets' },
    ];

    return (
        <>
            <SubNavigation title="Game Info" navLinks={NarrationSubMenu(gameId, 'media')} />
            <PageContainer>
                <PageBreadcrumbs links={breacrumbList} />

                {gameData && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ marginBottom: 20, position: 'relative' }}
                        >
                            <Grid item>
                                <Typography variant="h1">{gameData.name}</Typography>
                            </Grid>
                        </Grid>
                        <UploadAssetsContainer
                            jobs={activeJobs || []}
                            fetchMediaAssets={fetchMediaAssets}
                            fetchActiveJobs={fetchActiveJobs}
                        />

                        <Grid item style={{ padding: '10px 0' }}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h2" noWrap>
                                        Media Library
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="filter"
                                        name="filter"
                                        autoComplete="off"
                                        variant="filled"
                                        label="search media"
                                        value={searchFilter}
                                        onChange={(event: any) => setSearchFilter(event.target.value.toLowerCase())}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        style={{ padding: 0 }}
                                                        onClick={() => {
                                                            return searchFilter.length > 0 ? setSearchFilter('') : null;
                                                        }}
                                                    >
                                                        {!searchFilter.length ? <SearchRounded /> : <CloseRounded />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {(!gameAssets || !gameData) && (
                    <Grid container justifyContent="center" direction="row">
                        <Grid item>
                            <CircularProgress size="3rem" color="secondary" />
                        </Grid>
                    </Grid>
                )}
                {orderedMediaList(gameAssets || []).length === 0 && gameAssets && (
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <Typography variant="h2" color="textSecondary">
                                Nothing Found in Media Library
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                {orderedMediaList(gameAssets || []).map((asset: any) => (
                    <AssetItem key={asset.id} data={asset} copyMediaKey={copyMediaKey} />
                ))}
            </PageContainer>
        </>
    );
};

export default AssetContainer;
