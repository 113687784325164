import { createTheme } from '@material-ui/core';

export const colors = {
    backgroundColor: '#0D111A',
    palette: {
        error: {
            main: '#FE2E2E',
        },
        warning: {
            main: '#FFCA83',
        },
        success: {
            main: '#2EFEA9',
        },
        action: {
            active: '#7D31C9',
            secondary: '#7D31C9',
            tiertiary: '#6B25B1',
        },
        accent: {
            main: '#7D31C9',
            secondary: '#2EFEA9',
            tiertiary: '#636AFA',
        },
        borders: {
            paperColor: '#242730',
            secondaryPaperColor: '#3A3D45',
        },
        background: {
            default: '#0D111A',
            paper: '#1C1F27',
            secondaryPaper: '#2A2C34',
            disabled: '#202D32',
            dark: '#1C1F27',
        },
        text: {
            main: '#ffffff',
            secondary: '#78787E',
            disabled: '#2A2C34',
            light: '#6c7679',
        },
    },
};

export const darkTheme = createTheme({
    // global color palette
    ...colors,

    typography: {
        fontFamily: [
            'Noto Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Arial',
            '"Helvetica Neue"',
            'sane-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

        // typography styling
        body1: {
            fontSize: '0.938rem',
            lineHeight: '1.25rem',
            margin: '1rem 0',
            color: colors.palette.text.main,
        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: '1.188rem',
            margin: '1rem 0',
            color: colors.palette.text.main,
        },
        h1: {
            fontSize: '1.438rem !important',
            lineHeight: '1.555rem !important',
            margin: '1rem 0 !important',
            fontWeight: 400,
            color: colors.palette.text.main,
        },
        h2: {
            fontSize: '1.125rem',
            lineHeight: '1.5remm',
            margin: '1rem 0',
            fontWeight: 400,
            color: colors.palette.text.main,
        },
        h3: {
            fontSize: '0.688rem',
            lineHeight: '1.063rem',
            margin: '0.3rem 0',
            color: colors.palette.text.main,
        },
        h4: {
            fontSize: '0.625rem',
            lineHeight: '0.938rem',
            margin: '1rem 0',
            fontWeight: 400,
            color: colors.palette.text.main,
        },
        h5: {
            fontSize: '0.65rem',
            lineHeight: '1.063rem',
            margin: '1rem 0',
            letterSpacing: '1.5px',
            color: colors.palette.text.main,
        },
        caption: {
            fontSize: '0.75rem',
            lineHeight: '1.063rem',
            margin: '1rem 0',
            color: colors.palette.text.main,
        },
        button: {
            textTransform: 'none',
        },
    },

    // global overrides
    overrides: {
        MuiFormHelperText: {
            root: {
                color: colors.palette.text.secondary,
                textAlign: 'right',
            },
            contained: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                marginBottom: 5,
            },
        },
        MuiCssBaseline: {
            '@global': {
                html: {
                    WebkitFontSmoothing: 'auto',
                },
                body: {
                    margin: 0,
                    backgroundColor: colors.backgroundColor,
                },
            },
        },
        MuiAppBar: {
            positionFixed: {
                backgroundColor: `${colors.palette.background.dark} !important`,
                height: 60,
                boxShadow: 'none',
            },
        },
        MuiFormLabel: {
            root: {
                margin: '0.5rem 0',
                '&$focused': {
                    color: colors.palette.text.disabled,
                },
            },
        },
        MuiBadge: {
            colorPrimary: {
                backgroundColor: colors.palette.action.active,
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
        MuiFormControlLabel: {
            label: {
                margin: '5px 0',
            },
        },
        MuiRadio: {
            colorPrimary: {
                color: colors.palette.action.active,
            },
            root: {
                padding: '5px 9px',
                '&.Mui-checked': {
                    color: `${colors.palette.success.main} !important`,

                    '&:hover': {
                        backgroundColor: `rgba(${colors.backgroundColor}, 0.35)`,
                    },
                },
            },
        },
        MuiCheckbox: {
            root: {
                '&.Mui-checked': {
                    color: `${colors.palette.success.main} !important`,

                    '&:hover': {
                        backgroundColor: `rgba(${colors.backgroundColor}, 0.35)`,
                    },
                },
            },
        },
        MuiSvgIcon: {
            colorAction: {
                color: colors.palette.success.main,
            },
            colorPrimary: {
                color: colors.palette.action.active,
            },
            colorSecondary: {
                color: colors.palette.text.secondary,
            },
            colorDisabled: {
                color: colors.palette.text.disabled,
            },
        },
        MuiSwitch: {
            colorPrimary: {
                '&.Mui-checked': {
                    color: colors.palette.text.main,
                },
                '&$checked + $track': {
                    backgroundColor: `${colors.palette.action.active} !important`,
                    opacity: 1,
                },
            },
            track: {
                backgroundColor: `${colors.palette.background.secondaryPaper} !important`,
                opacity: 1,
            },
        },
        MuiSelect: {
            root: {
                display: 'flex',
                flex: 1,
            },
            filled: {
                padding: '25px 10px 10px 12px',
            },
            select: {
                backgroundColor: `${colors.palette.borders.secondaryPaperColor} !important`,
            },
            selectMenu: {
                borderRadius: 2,
                backgroundColor: `${colors.palette.background.secondaryPaper} !important`,
            },
            icon: {
                color: colors.palette.text.secondary,
                right: 10,
            },
        },
        MuiInput: {
            root: {
                width: '100%',
            },
            underline: {
                '&:hover:not(.Mui-disabled):before': {
                    borderColor: `${colors.palette.action.active}`,
                },
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: colors.palette.background.secondaryPaper,
                color: colors.palette.text.main,
                fontWeight: 400,
                fontSize: '0.875rem',
                margin: '0.5rem 0',
                '&:after': {
                    border: 'none !important',
                },

                '&.Mui-disabled': {
                    color: colors.palette.text.main,
                },
            },
            input: {
                padding: '15px 12px',
            },
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: colors.palette.text.main,
            },
            colorSecondary: {
                color: colors.palette.action.active,
            },
        },
        MuiFilledInput: {
            underline: {
                '&:after': {
                    borderBottom: 'none',
                },
                '&:focus': {
                    border: 'none',
                    outline: 'none',
                },
                '&:hover': {
                    '&:after': {
                        border: 'none',
                    },
                },
            },
            adornedEnd: {
                backgroundColor: `${colors.palette.background.secondaryPaper} !important`,
                borderRadius: 4,
                '&:hover': {
                    backgroundColor: colors.palette.borders.secondaryPaperColor,
                },
                '&:focus': {
                    backgroundColor: colors.palette.borders.secondaryPaperColor,
                },
            },
            input: {
                fontSize: '0.875rem',
                padding: '25px 10px 10px 12px',
                borderRadius: 2,
                backgroundColor: colors.palette.background.secondaryPaper,

                '&-webkit-autofill': {
                    backgroundColor: colors.palette.borders.paperColor,
                },
            },
        },
        MuiDialogContent: {
            root: {
                padding: 30,

                '&:first-child': {
                    paddingTop: 30,
                },
            },
        },
        MuiDialog: {
            paper: {
                maxWidth: 675,
                width: 675,
            },
            paperWidthSm: {
                maxWidth: '100%',
                width: 852,
            },
        },
        MuiBreadcrumbs: {
            ol: {
                margin: 0,
                li: {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiButton: {
            text: {
                fontSize: '0.875rem',
                textDecoration: 'underline',
                lineHeight: '1.063rem',
                margin: '0.5rem 0',
                color: colors.palette.text.main,
                textTransform: 'none',

                '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'transparent !important',
                },
            },
            textPrimary: {
                color: colors.palette.text.main,
            },
            textSecondary: {
                color: colors.palette.text.secondary,
            },
            root: {
                textTransform: 'none',
                width: 'fit-content',
                borderRadius: 2,
                '&$disabled': {
                    backgroundColor: `${colors.palette.action.tiertiary} !important`,
                    cursor: 'not-allowed',
                    color: 'rgba(0, 0, 0, 0.6) !important',
                },
            },
            containedPrimary: {
                backgroundColor: colors.palette.action.active,
                fontSize: '0.875rem',
                textTransform: 'none',
                color: colors.palette.text.main,

                '&:hover': {
                    backgroundColor: `${colors.palette.action.active} !important`,
                },
            },
            outlinedSecondary: {
                backgroundColor: 'transparent',
                borderColor: colors.palette.error.main,
                color: colors.palette.text.main,
            },
            containedSecondary: {
                backgroundColor: colors.palette.error.main,
                color: colors.palette.text.main,

                '&:hover': {
                    backgroundColor: colors.palette.error.main,
                },
            },
            outlined: {
                border: '1px solid white',
                textTransform: 'none',
                color: colors.palette.text.main,
            },
        },
        MuiButtonGroup: {
            groupedContainedPrimary: {
                backgroundColor: colors.palette.background.paper,
                borderColor: `${colors.palette.borders.paperColor} !important`,
                padding: '10px 13px',
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: 400,
                zIndex: 25,
                color: colors.palette.text.main,
                fontSize: '0.875rem',
                '&$error': {
                    color: `${colors.palette.error.main} !important`,
                },
            },
            shrink: {
                color: `${colors.palette.text.secondary} !important`,
            },
        },
        MuiLink: {
            root: {
                color: colors.palette.action.active,
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: `${colors.palette.background.paper} !important`,
                padding: '0 20px',
            },
        },
        MuiLinearProgress: {
            barColorPrimary: {
                backgroundColor: colors.palette.borders.secondaryPaperColor,
            },
            colorPrimary: {
                backgroundColor: colors.palette.action.active,
            },
            barColorSecondary: {
                backgroundColor: colors.palette.borders.secondaryPaperColor,
            },
            colorSecondary: {
                backgroundColor: colors.palette.success.main,
            },
            bar1Determinate: {
                backgroundColor: colors.palette.success.main,
            },
        },
        MuiCardHeader: {
            root: {
                width: '100%',
                borderBottom: `1px solid ${colors.palette.borders.paperColor}`,
                padding: '0 24px',
            },
            title: {
                fontSize: '0.93rem',
                letterSpacing: 0,
            },
        },
        MuiCardContent: {
            root: {
                width: '100%',
                padding: 24,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: colors.palette.background.secondaryPaper,
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 30,
                color: colors.palette.text.secondary,
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '0.875rem',
                margin: '0.5rem 0',
            },
            dense: {
                margin: 0,
                padding: 0,
            },
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            },
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        MuiListItem: {
            root: {
                fontSize: '0.938rem',
                fontWeight: 300,
            },
            secondaryAction: {
                root: {
                    paddingRight: '16px',
                    right: 0,
                },
            },
            container: {
                backgroundColor: colors.palette.background.paper,
                paddingTop: 0,
                paddingBottom: 0,
            },
            divider: {
                borderBottom: `1px solid ${colors.palette.background.secondaryPaper}`,
            },
            gutters: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        MuiIconButton: {
            root: {
                color: colors.palette.text.main,
                '&:hover': {
                    backgroundColor: `rgba(${colors.palette.background.dark}, 0.35)`,
                },
            },
            colorPrimary: {
                color: colors.palette.text.main,
            },
            colorSecondary: {
                color: colors.palette.action.active,
            },
            edgeEnd: {
                color: colors.palette.text.main,
            },
        },
        MuiToolbar: {
            root: {
                marginLeft: 85,
            },
        },
        MuiTableRow: {
            root: {
                borderBottom: `1px solid ${colors.palette.borders.secondaryPaperColor}`,

                '&:last-child': {
                    border: 'none',
                },
            },
        },
        MuiTab: {
            root: {
                minHeight: 38,
            },
        },
        MuiTableCell: {
            body: {
                backgroundColor: colors.palette.background.secondaryPaper,
                fontSize: '0.938rem',
                color: colors.palette.text.main,
                margin: 0,
                border: 'none !important',
            },
            head: {
                backgroundColor: 'transparent !important',
                padding: '0 10px 5px 10px',
                border: 'none !important',
                fontSize: '0.825rem',
                color: colors.palette.text.main,
                margin: 0,
            },
        },
    },
});
