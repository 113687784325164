import * as React from 'react';
import Grid from '@material-ui/core/Grid';

import PageContainer from '../../components/shared/PageContainer';
import PageTitle from '../../components/shared/PageTitle';
import GamePicker from './components/GamePicker';
import { useState } from 'react';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import CsvUploader from './components/CsvUploader';
import { Backer, useRedeemBackers } from 'core/api/redemption';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { gameImageUrlSchema } from 'core/validators/games-yup-validators';
import isValidEmail from 'core/validators/email';

const RedeemerContainer: React.FC = () => {
    const [, appDispatch] = useAppState();

    const [gameSku, setGameSku] = useState<string>('');
    const [gameImage, setGameImage] = useState<string | null>(null);
    const [redemptions, setRedemptions] = useState<[[]]>();

    const onSelectGame = (event: React.ChangeEvent<{ value: string }>) => {
        setGameSku(event.target.value);
    };

    const handleCvsDrop = async (event: { file: File; blob: string }) => {
        const raw = await event.file.text();
        const matrix = CSVToArray(raw, ',');

        setRedemptions(matrix);
    };

    const handleChangeImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGameImage(event.target.value);
    };

    // This will parse a delimited string into an array of
    // arrays. The default delimiter is the comma, but this
    // can be overriden in the second argument.
    const CSVToArray = (strData: string, strDelimiter: string) => {
        // Check to see if the delimiter is defined. If not,
        // then default to comma.
        strDelimiter = strDelimiter || ',';

        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
            // Delimiters.
            '(\\' +
                strDelimiter +
                '|\\r?\\n|\\r|^)' +
                // Quoted fields.
                '(?:"([^"]*(?:""[^"]*)*)"|' +
                // Standard fields.
                '([^"\\' +
                strDelimiter +
                '\\r\\n]*))',
            'gi'
        );

        // Create an array to hold our data. Give the array
        // a default empty first row.
        var arrData: any = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;

        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while ((arrMatches = objPattern.exec(strData))) {
            // Get the delimiter that was found.
            var strMatchedDelimiter = arrMatches[1];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push([]);
            }

            let strMatchedValue;
            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[2]) {
                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
            } else {
                // We found a non-quoted value.
                strMatchedValue = arrMatches[3];
            }

            // Now that we have our value string, let's add
            // it to the data array.
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        // Return the parsed data.
        return arrData;
    };

    const { mutateAsync, isLoading } = useRedeemBackers();
    const handleSubmitRedemptions = async () => {
        if (!redemptions || !gameImage) return;

        let message: string = '';
        let status: 'error' | 'success' = 'success';

        const convertRedemptionsToBackers: Backer[] = redemptions.map((r: string[]) => {
            return {
                email: r[0],
                displayName: r[1],
            };
        });

        // go through each backer and validate the email
        const validBackers = convertRedemptionsToBackers.filter((backer: Backer) => {
            return isValidEmail(backer.email);
        });

        const postBody = {
            sku: gameSku,
            imageUrl: gameImage,
            backers: validBackers,
        };

        try {
            await mutateAsync(postBody);

            setRedemptions(undefined);
            setGameSku('');
            setGameImage(null);
        } catch (error) {
            message = 'There was an error redeeming the backers';
            status = 'error';
        } finally {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message,
                status,
                open: true,
            } as ToastSnackAction);
        }
    };

    const formIsValid = gameImageUrlSchema.isValidSync({ url: gameImage }) && gameImage && redemptions;

    return (
        <PageContainer removeSidebar>
            <Grid container>
                <Grid item xs={12}>
                    <PageTitle text="Redemptions" />
                </Grid>

                <Grid item xs={12}>
                    <GamePicker updateGameImage={handleChangeImageUrl} onChange={onSelectGame} selected={gameSku} />
                </Grid>

                <Grid item xs={12}>
                    <CsvUploader
                        id="redeemCsv"
                        name="Redeem CSV"
                        buttonLabel="Upload CSV"
                        disabled={gameSku === ''}
                        onDrop={(event: any) => handleCvsDrop(event)}
                    />
                </Grid>

                <Grid container direction="column">
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                        <Grid item xs={'auto'}>
                            <Button variant="outlined">Send Test Email</Button>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="body1">Total: {redemptions ? redemptions.length : 'N/A'}</Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Button
                                endIcon={isLoading ? <CircularProgress size={20} /> : undefined}
                                variant="contained"
                                color="secondary"
                                disabled={!formIsValid}
                                onClick={handleSubmitRedemptions}
                            >
                                Send Redemptions
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={'auto'}>
                        {redemptions &&
                            redemptions.map((r: Array<string>, ridx) => {
                                const recipients = {
                                    name: r[1],
                                    email: r[0],
                                };

                                return (
                                    <Grid key={ridx} item xs={'auto'}>
                                        <Typography variant="body1">
                                            {recipients.name} {recipients.email}
                                        </Typography>{' '}
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default RedeemerContainer;
