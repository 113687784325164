import React, { FC, useState } from 'react';
import { useTheme, Typography, Grid, Card, MenuItem, Menu, ListItemIcon } from '@material-ui/core';
import { VolumeUpRounded, FileCopyRounded, MoreVertRounded } from '@material-ui/icons';
import styled from 'styled-components';
import Asset from 'core/models/Asset';
import { convertToTime } from '../../utils/';
interface AssetItemProps {
    data: Asset;
    copyMediaKey: (key: string) => void;
}

const AssetItem: FC<AssetItemProps> = ({ data, copyMediaKey }) => {
    const { id, name, duration, addedAtUtc, locatorId, locatorName, streamUrl } = data;
    const { palette } = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const copyItems = [
        { label: 'Asset ID', value: id },
        { label: 'Locator ID', value: locatorId },
        { label: 'Locator Name', value: locatorName },
        { label: 'Stream URL', value: streamUrl },
    ];

    const handleDropdownAction = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleCopy = (key: string) => {
        copyMediaKey(key);
        setAnchorEl(null);
    };

    return (
        <Card key={id} style={{ marginBottom: 10, paddingTop: 10, paddingBottom: 10 }}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <AssetType theme={palette}>
                                <VolumeUpRounded />
                            </AssetType>
                        </Grid>
                        <Grid item>
                            <Typography>{name}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid item style={{ paddingRight: 30 }}>
                            <Typography>Length: {convertToTime(duration.toString())}</Typography>
                        </Grid>
                        <Grid item style={{ paddingRight: 20 }}>
                            <Typography>{new Date(addedAtUtc).toLocaleDateString('en-US')}</Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                borderLeft: `1px solid ${palette.borders.paperColor}`,
                                padding: 5,
                            }}
                        >
                            <MoreVertRounded
                                aria-controls="media-actions"
                                aria-haspopup="true"
                                style={{ margin: '0 5px 0 12px', cursor: 'pointer', color: palette.text.main }}
                                onClick={handleDropdownAction}
                            />
                            <Menu
                                id="container-actions"
                                open={Boolean(anchorEl)}
                                onClose={handleDropdownAction}
                                anchorEl={anchorEl}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {copyItems.map((item: { label: string; value: string }) => (
                                    <MenuItem key={item.value} dense onClick={() => handleCopy(item.value)}>
                                        <ListItemIcon>
                                            <FileCopyRounded />
                                        </ListItemIcon>
                                        <Typography>{item.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

const AssetType = styled.div`
    padding: 10px;
    display: flex;
    width: fit-content;
    border-radius: 8px;
    background-color: ${style => style.theme.action.active};
    color: ${style => style.theme.text.main};
`;

export default AssetItem;
