import PublisherService from 'core/services/PublisherService';
import {
    PublisherState,
    PublisherActions,
    PublisherActionTypes,
    FetchPublisherRequestAction,
    FetchPublisherDoneAction,
} from './publisher.actions';

const publisherInitialState = {
    ready: false,
    list: [],
};

const getPublisherList = async ({ dispatcher }: FetchPublisherRequestAction) => {
    PublisherService.getPublishers()
        .then(publisherResult => {
            const action: FetchPublisherDoneAction = {
                publishers: publisherResult,
                type: PublisherActions.FETCH_PUBLISHERS_DONE,
            };
            dispatcher(action);
        })
        .catch(err => {
            console.error('Failed to fetch narration games', err);
        });
};

const updatePublishers = (action: FetchPublisherDoneAction): PublisherState => {
    return {
        ready: true,
        list: action.publishers,
    };
};

const publisherReducer = (state: PublisherState, action: PublisherActionTypes) => {
    let newState: PublisherState = state;
    switch (action.type) {
        case PublisherActions.FETCH_PUBLISHERS_REQUEST:
            getPublisherList(action as FetchPublisherRequestAction);
            break;
        case PublisherActions.FETCH_PUBLISHERS_DONE:
            newState = updatePublishers(action as FetchPublisherDoneAction);
            break;
        default: {
            console.log("You didn't dispatch a message --> publisher.reducer.ts");
            return state;
        }
    }

    return { ...newState };
};

export { publisherReducer, publisherInitialState };
