import React, { FC, useReducer, useEffect, useRef } from 'react';
import { ItemModalState } from 'domain/scry/models/Game';
import { itemValidationRules, isValid } from 'core/services/ScryService';
import styled from 'styled-components';
import {
    useTheme,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Radio,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core';
import ImageUploader from './ImageUploader';
import { InteractionActions } from 'core/state/scry/scry.actions';
import { Interaction } from 'domain/scry/models/Game';

interface ModalProps {
    open: boolean;
    loading: boolean;
    form: Interaction | null;
    onClose: () => void;
    onSubmit: (arg0: any, editMode: boolean) => void;
}

const reducer = (state: ItemModalState, { type, value }: any) => {
    switch (type) {
        case InteractionActions.SET_INIT_STATE:
            return { ...state, ...value };
        case InteractionActions.UPDATE_FORM:
            return {
                ...state,
                [value.key]: value.newValue,
            };
        case InteractionActions.SET_MAX_QTY:
            return {
                ...state,
                setMaxQty: value.setMaxQty,
                requireBuyout: value.requireBuyout,
                maxQuantity: value.maxQuantity,
            };
        case InteractionActions.UPLOAD_CARD_URI:
            return {
                ...state,
                uploadedImageData: value.file,
                cardUri: value.cardUri,
            };
        default:
            throw new Error("You didn't dispatch  message --> ItemModal.tsx");
    }
};

const initialState: ItemModalState = {
    id: '',
    name: '',
    order: 0,
    category: 'player',
    setMaxQty: true,
    cardUri: '',
    maxQuantity: -1,
    cost: 0,
    requireBuyout: false,
    uploadedImageData: null,
};

const ItemModal: FC<ModalProps> = ({ open, form, onClose, onSubmit, loading }: ModalProps) => {
    const { palette } = useTheme();
    const [state, dispatch] = useReducer(reducer, initialState);
    const editFormRef: any = useRef(null);

    useEffect(() => {
        dispatch({
            type: InteractionActions.SET_INIT_STATE,
            value: { ...form, ...(form!.maxQuantity === -1 && { setMaxQty: false }) },
        });
    }, [form]);

    useEffect(() => {
        if (editFormRef.current === null) {
            editFormRef.current = form && form.id === '' ? null : form;
        }
    }, [editFormRef, form]);

    const {
        id,
        cardUri,
        name,
        category,
        requireBuyout,
        cost,
        maxQuantity,
        setMaxQty,
        order,
        uploadedImageData,
        gameId,
    } = state;

    const handleChange = (event: any) => {
        const { value, name } = event?.target;
        const isBoolean = value === 'false' || value === 'true';
        const numberCheck = ['maxQuantity', 'cost', 'order'];
        const finalValue = numberCheck.includes(name) ? parseInt(value) : value;

        dispatch({
            type: 'update form',
            value: {
                key: event.target.name,
                newValue: isBoolean ? Boolean(finalValue) : finalValue,
            },
        });
    };

    const handleMaxQuantity = (event: any) => {
        const value = event.target.value === 'true' ? true : false;
        const maxQuantity = value ? 0 : -1;

        dispatch({
            type: InteractionActions.SET_MAX_QTY,
            value: {
                setMaxQty: value,
                requireBuyout: false,
                maxQuantity,
            },
        });
    };

    const handleCheck = () => {
        dispatch({
            type: InteractionActions.UPDATE_FORM,
            value: {
                key: 'requireBuyout',
                newValue: requireBuyout ? false : true,
            },
        });
    };

    const disabledMaxQtySelect = () => {
        // if the maxQuantity is the same as the state, it should be disabled
        if (editFormRef?.current?.maxQuantity === -1 && maxQuantity === -1) {
            return true;
        }
        return false;
    };

    const validateMaxQtyField = (newValue: number) => {
        return editFormRef.current !== null && newValue !== -1 && newValue < editFormRef.current.maxQuantity;
    };

    const handleQtyChange = (e: any) => {
        dispatch({
            type: 'update form',
            value: {
                key: 'maxQuantity',
                newValue: e.target.value,
            },
        });
    };

    const canSubmit = () => {
        if (validateMaxQtyField(maxQuantity)) {
            return false;
        }

        const requiredFields = [
            { key: 'name', value: name },
            { key: 'order', value: order },
            { key: 'cardUri', value: cardUri },
            { key: 'cost', value: cost },
        ];

        if (setMaxQty) {
            requiredFields.push({ key: 'maxQuantity', value: maxQuantity });
        }

        const validatedFields = requiredFields.reduce(
            (acc: Array<boolean>, curr: { key: string; value: any }) => [
                ...acc,
                isValid(itemValidationRules[curr.key], curr.value),
            ],
            []
        );

        return validatedFields.every(field => field);
    };

    const submit = () => {
        onSubmit(
            {
                id,
                gameId,
                cardUri,
                name,
                category,
                requireBuyout,
                cost,
                maxQuantity: parseInt(maxQuantity),
                setMaxQty,
                order,
                uploadedImageData,
            },
            id === '' ? false : true
        );
    };

    return (
        <Modal open={open}>
            {loading && (
                <LoadingContainer>
                    <CircularProgress color="secondary" size="4rem" />
                </LoadingContainer>
            )}

            <DialogContent>
                <Typography variant="h1">{id ? 'Edit' : 'Add New'} Item</Typography>

                <Grid container direction="row" spacing={3}>
                    <Grid item xs={4}>
                        <ImageUploader dispatch={dispatch} cardUri={cardUri} />
                        <Typography variant="h4" style={{ padding: '0 10px' }}>
                            Must be at least 100x100 PNG format
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="name"
                            name="name"
                            label="Item Name"
                            autoComplete="off"
                            variant="filled"
                            size="small"
                            fullWidth
                            type="text"
                            onChange={handleChange}
                            value={name}
                        />

                        <Grid container spacing={3} direction="row" justifyContent="space-between">
                            <Grid item xs={6}>
                                <TextField
                                    id="cost"
                                    name="cost"
                                    label="cost"
                                    autoComplete="off"
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    onChange={handleChange}
                                    value={cost}
                                    disabled={id !== ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="order"
                                    name="order"
                                    label="order"
                                    autoComplete="off"
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    onChange={handleChange}
                                    value={order}
                                />
                            </Grid>
                        </Grid>

                        <Typography variant="h2">Configuration</Typography>

                        <FormControl variant="filled">
                            <InputLabel id="category-label">Category</InputLabel>
                            <Select
                                labelId="category-label"
                                id="category"
                                name="category"
                                onChange={handleChange}
                                value={category}
                            >
                                <MenuItem value="player">Player</MenuItem>
                                <MenuItem value="gm">GM</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl component="fieldset" style={{ marginTop: 10 }}>
                            <RadioLabel variant="body2" theme={palette}>
                                Set Max Qty
                            </RadioLabel>

                            <RadioGroup
                                aria-label="setMaxQty"
                                id="setMaxQty"
                                name="setMaxQty"
                                value={setMaxQty}
                                onChange={event => (disabledMaxQtySelect() ? null : handleMaxQuantity(event))}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Limit Quantity" />
                                <FormControlLabel color="primary" value={false} control={<Radio />} label="Unlimited" />
                            </RadioGroup>
                        </FormControl>

                        {setMaxQty && (
                            <>
                                <TextField
                                    id="maxQuantity"
                                    name="maxQuantity"
                                    label="Max Quantity"
                                    autoComplete="off"
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    onChange={handleQtyChange}
                                    value={maxQuantity}
                                    error={validateMaxQtyField(maxQuantity) ? true : false}
                                    helperText={
                                        validateMaxQtyField(maxQuantity)
                                            ? `Max Quantity must be higher than ${editFormRef.current.maxQuantity}`
                                            : ''.trim()
                                    }
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={requireBuyout}
                                            onChange={handleCheck}
                                            name="requireBuyout"
                                            id="requireBuyout"
                                            color="primary"
                                            value={requireBuyout}
                                        />
                                    }
                                    label="Require max quantity to fulfill action"
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={onClose} style={{ backgroundColor: palette.background.paper }}>
                    Close
                </CancelButton>
                <Button disabled={!canSubmit()} onClick={submit} variant="contained" color="primary">
                    Save Item
                </Button>
            </DialogActions>
        </Modal>
    );
};

const CancelButton = styled(Button)`
    font-size: 0.875rem;
    text-decoration: none;
    font-weight: 400;

    &:hover {
        text-decoration: none;
    }
`;

const RadioLabel = styled(Typography)`
    color: ${style => style.theme.text.secondary};
    text-transform: uppercase;
    margin-top: 0px;
    font-weight: 600;
`;

const Modal = styled(Dialog)`
    .MuiDialog-paper {
        max-width: 675px;
        width: 675px;
        margin-left: 215px;

        @media only screen and (max-width: 992px) {
            margin-left: 60px;
        }
    }
`;

const LoadingContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default ItemModal;
