import React, { FC, useEffect, useState, MouseEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { ScryActions } from 'core/state/scry/scry.actions';
import ScryService from 'core/services/ScryService';

import styled from 'styled-components';
import { GetApp, Settings, MoreHoriz } from '@material-ui/icons';
import {
    ListItemIcon,
    Grid,
    Typography,
    useTheme,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core';

import { useScryState } from 'core/state/scry/scry.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useAppState } from 'core/state/app.state';

import InteractionCollection from './components/Interactions/InteractionCollection';
import GlobalMap from './components/globalmap/GlobalMap';
import CommunityCarousel from './components/communitycarousel/CommunityCarousel';

// Tabs
import TabContent from './components/common/TabContent';
import LivePurchases from './components/Interactions/TabPanels/LivePurchases';
import PageTitle from '../../components/shared/PageTitle';
import Guid from 'core/models/Guid';
import CatalogService from 'core/services/CatalogService';
import { useGetCommunities } from './api/communities';

const EditScry: FC = () => {
    const [appState, appDispatch] = useAppState();
    const [loading, setLoading] = useState<false | boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { push } = useHistory();
    const [{ game }, scryDispatch]: any = useScryState();
    const { gameId }: { gameId: Guid } = useParams();

    const { palette } = useTheme();

    const { data: communityResponse, isLoading: fetchingCommunities } = useGetCommunities(gameId);
    const communities = communityResponse?.data ?? [];

    const fetchGameData = async () => {
        try {
            const games = await CatalogService.getGameById(gameId);

            scryDispatch({
                type: ScryActions.GET_GAME_DATA,
                value: games,
            });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (appState.auth.currentUser !== null && gameId) {
            const interval = setInterval(() => fetchRecentEngagement(), 15000);
            return () => {
                clearInterval(interval);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState]);

    const fetchRecentEngagement = async () => {
        try {
            const contributions = await ScryService.getRecentEngagement(gameId, 60);
            scryDispatch({
                type: ScryActions.UPSTREAM_RECENT_ENGAGEMENTS,
                value: contributions,
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchInteractions = async () => {
        try {
            const interactions = await ScryService.getInteractionsByGame(gameId);
            scryDispatch({
                type: ScryActions.FETCH_INTERACTION,
                value: interactions,
            });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (appState.auth.currentUser && gameId) {
            fetchInteractions();
            fetchRecentEngagement();
        }

        if (appState.auth.currentUser) {
            fetchGameData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.auth]);

    const itemCount = (key: string, lookupValue: any) => {
        if (game.interactions.status !== 'ready') {
            return 0;
        }

        if (!game.interactions.data.length) {
            return 0;
        }

        return game.interactions.data.reduce((acc: number, curr: any) => {
            const conditionMet =
                key === 'isArchived' ? curr.isArchived === true : curr[key] === lookupValue && !curr.isArchived;
            return conditionMet ? acc + 1 : acc;
        }, 0);
    };

    const tabData = [
        {
            label: 'Live',
            panel: <LivePurchases />,
            addInlineStyle: { padding: '0 20px 30px' },
        },
    ];

    const getCSV = async () => {
        try {
            setLoading(true);
            const start = new Date('08/01/2020');
            const end = new Date();
            const res = await ScryService.getEngagementCSV(game.id, start, end);
            const blob = new Blob([res]);
            saveAs(blob, 'metrics.csv');
        } catch (err) {
            console.error(err);
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: err.data.message,
                status: 'error',
                open: true,
            } as ToastSnackAction);
        } finally {
            setLoading(false);
        }
    };

    // Menu handler
    const open = Boolean(anchorEl);
    const iconClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GameDetailsContainer theme={palette}>
            <PageTitle text="Scry Games" />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: 10 }}
            >
                <Typography variant="h1">{game.name}</Typography>

                <IconButton
                    className="icon-bg"
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={iconClick}
                >
                    <MoreHoriz />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={() => push(`/games/scry/${game.id}/configuration`)}>
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                    <MenuItem onClick={getCSV}>
                        <ListItemIcon>
                            {loading ? <CircularProgress color="primary" size="1.1rem" /> : <GetApp />}
                        </ListItemIcon>
                        Download Data
                    </MenuItem>
                </Menu>
            </Grid>

            <Grid container direction="row" style={{ marginBottom: 24 }} alignContent="stretch" spacing={3}>
                <Grid item xs={8}>
                    <CommunityCarousel isFetching={fetchingCommunities} gameId={gameId} communities={communities} />
                </Grid>
                <Grid item xs={4}>
                    <GlobalMap gameId={gameId} />
                </Grid>
            </Grid>

            <TabContent gameId={game.id} disableChange={Boolean(game.interactions.data.length === 0)} tabs={tabData} />

            <InteractionCollection
                fetchInteractions={fetchInteractions}
                inventoryItems={game.interactions.data}
                gameId={gameId}
                tabStats={{
                    player: itemCount('category', 'player'),
                    gm: itemCount('category', 'gm'),
                    isArchived: itemCount('isArchived', true),
                }}
            />
        </GameDetailsContainer>
    );
};

const GameDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    h3 {
        margin-top: 0px;
        text-transform: uppercase;
        color: ${style => style.theme.text.secondary};
    }

    .icon-bg {
        background-color: ${style => style.theme.background.dark};
    }
`;

export default EditScry;
