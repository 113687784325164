import Guid from './Guid';
import { NewImageBlob } from './Game';

export interface Containers {
    id: Guid;
    order: number;
    gameId: Guid;
    name: string;
    shortKey: string;
    isFree: boolean;
    published: boolean;
    foregroundUri: string;
    backgroundUri: string;
}
export interface ContainerRequestAction {
    dispatch: React.Dispatch<any>;
    gameId: string;
}

export interface AddContainerRequest {
    name: string;
    shortKey: string;
}

export interface UpdateContainerRequest extends AddContainerRequest {
    id: Guid;
    published: boolean;
    isFree: boolean;
    foregroundUri: string;
    backgroundUri: string;
}

export interface AddContainerResponse {
    id: Guid;
    gameId: Guid;
    name: string;
    shortKey: string;
    published: boolean;
    isFree: boolean;
    foregroundUri: string;
    backgroundUri: string;
}

export interface AddContainerImageRequest {
    image: Blob;
    imageType: string;
}

export interface AddContainerImageResponse {
    image: string;
    imageType: string;
}

export enum ContainerActionTypes {
    SET_GAME = 'set game',
    GET_CONTAINERS = 'get containers',
    SET_CONTAINERS = 'set containers',
    SET_CONTAINER_DATA = 'set container data',
    SET_STATE = 'set state',
    SET_GAME_DATA = 'set game data',
    RESET_UPLOAD_IMAGES = 'reset uploaded images',
    SET_LOADING = 'set loading',
}

export interface ManageContainerState extends AddContainerResponse {
    uploadedBackgroundUri: NewImageBlob | null;
    uploadedForegroundUri: NewImageBlob | null;
    loading: boolean;
}
