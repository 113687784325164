export interface ProfileWithOrderHistory extends Profile {
    orders: {
        total: number;
        data: FortellerOrderEventData<any>[];
    };
}

export interface FortellerOrderEventData<T> {
    source: OrderSources;
    _id: string;
    addedAtUtc: Date;
    userId: string;
    gameIds: Array<string>;
    skus: Array<string>;

    subtotal: string;
    discounts: string;
    taxes: string;
    tips: string;
    total: string;

    email: string;

    raw: T;
}

export enum OrderSources {
    Shopify = 'shopify-order',
    ShopifyEmailOnly = 'shopify-order-email',
    RedemptionCode = 'redeem-code',
    BackstageOrder = 'backstage-order',
}

export interface Profile {
    _id: string;
    addedAtUtc: Date;
    version: number;
    firebaseUserId: string;
    displayName: string;
    twitchId: string;
    email: string;
    ownedProductIds: string[];
    ownedSkus: string[];
}

export interface AddSkuToAccountRequest {
    skus: string[];
    firebaseUserId: string;
}
