import Guid from 'core/models/Guid';
import React from 'react';
import { Action } from '../app.actions';

export interface PublisherState {
    ready: boolean;
    list: Array<Publisher>;
}

export enum PublisherActions {
    FETCH_PUBLISHERS_REQUEST = 'Fetch Publishers Request',
    FETCH_PUBLISHERS_DONE = 'Fetch Publishers Done',
}

export type PublisherActionTypes = FetchPublisherRequestAction | FetchPublisherDoneAction;

export interface FetchPublisherRequestAction extends Action {
    dispatcher: React.Dispatch<PublisherActionTypes>;
}

export interface FetchPublisherDoneAction extends Action {
    publishers: Array<Publisher>;
}
export interface Owner {
    id: Guid;
    name: string;
    emailAddress: string;
}

export interface Publisher {
    id: Guid;
    name: string;
    aboutUri: string;
    logoUri: string;
}
