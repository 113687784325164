import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Typography, useTheme, CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { Edit, Archive, Unarchive } from '@material-ui/icons';
import { useScryState } from 'core/state/scry/scry.state';
import { Interaction, TagProps } from 'domain/scry/models/Game';

import ScryService from 'core/services/ScryService';

import { InteractionActions, ScryActions } from 'core/state/scry/scry.actions';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useAppState } from 'core/state/app.state';
import { UpdateInteractionRequest } from 'core/models/scry/InteractionDefinition';

interface InventoryListitemProps extends Interaction {
    editInteraction: () => void;
    gameId: string;
}

const InventoryListItem: FC<InventoryListitemProps> = ({
    id,
    cardUri,
    name,
    maxQuantity,
    cost,
    requireBuyout,
    editInteraction,
    purchasedQuantity,
    category,
    isArchived,
    order,
    gameId,
}: InventoryListitemProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [, appDispatch] = useAppState();
    const [{ game }, scryDispatch]: any = useScryState();
    const { palette } = useTheme();

    const handleChange = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const RequiredTags = ({ metReqiredQty, theme }: TagProps) => {
        return (
            <Tag theme={theme} metReqiredQty={metReqiredQty}>
                <Typography variant="h4">{metReqiredQty ? 'QTY MET' : 'MIN QTY REQ'}</Typography>
            </Tag>
        );
    };

    const handleEvent = (event: any) => {
        editInteraction();
        handleChange(event);
    };

    const handleArchive = async (overwriteArchive?: any) => {
        setLoading(true);

        const editData: UpdateInteractionRequest = {
            category,
            isArchived: overwriteArchive === false ? false : true,
            cost,
            name,
            order,
            requireBuyout,
            maxQuantity,
        };

        let toastMessage: string = '';
        let toastStatus: string = '';
        try {
            const archived = await ScryService.updateInteraction(gameId, id, editData);
            const interactions = await ScryService.getInteractionsByGame(game.id);

            toastMessage = `"${name}" has been ${archived.isArchived === false ? 'activated' : 'archvied'}`;
            toastStatus = 'success';

            scryDispatch({
                type: ScryActions.FETCH_INTERACTION,
                value: interactions,
            });
        } catch (err) {
            toastMessage = err;
            toastStatus = 'error';
        } finally {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: toastMessage,
                status: toastStatus,
                open: true,
            } as ToastSnackAction);

            scryDispatch({ type: InteractionActions.TOGGLE_LOADING, value: false });
        }

        setLoading(false);
        return;
    };

    const handleActive = () => handleArchive(false);

    return (
        <TableRow>
            <TableCell width="48">
                <img src={cardUri} width="48" alt="" style={{ display: 'flex' }} />
            </TableCell>
            <LongCell>
                <Typography>{name}</Typography>
            </LongCell>

            <TableCell>{cost} time</TableCell>
            <TableCell>
                {purchasedQuantity} of {maxQuantity !== -1 ? maxQuantity : 'unl'}
            </TableCell>

            <TableCell align="right">
                {requireBuyout ? (
                    <RequiredTags metReqiredQty={purchasedQuantity === maxQuantity} theme={palette} />
                ) : (
                    <Typography style={{ width: 83 }}>&nbsp;</Typography>
                )}
            </TableCell>

            <TableCell align="right" width="48">
                <Actions theme={palette}>
                    {!isArchived && <Edit onClick={handleEvent} />}
                    {loading ? (
                        <LoadingCircle size="1rem" color="secondary" className={isArchived ? 'archived' : ''.trim()} />
                    ) : isArchived ? (
                        <Unarchive onClick={handleActive} />
                    ) : (
                        <Archive onClick={handleArchive} />
                    )}
                </Actions>
            </TableCell>
        </TableRow>
    );
};

const LoadingCircle = styled(CircularProgress)`
    position: relative;
    top: 2px;
    margin-left: 10px;

    &.archived {
        margin-left: 0px;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 5px;
    padding-left: 15px;
    border-left: 1px solid ${style => style.theme.text.disabled};

    .MuiSvgIcon-root {
        font-size: 1.125rem;
        margin-left: 10px;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }
    }
`;

const LongCell = styled(TableCell)`
    width: 40%;

    @media only screen and (max-width: 992px) {
        width: auto;
    }
`;

const Tag = styled.div<TagProps>`
    padding: 1.5px 10px;
    display: flex;
    width: fit-content;
    border-radius: 12px;
    margin-left: 10px;
    background-color: ${style => (style.metReqiredQty ? style.theme.success.main : style.theme.action.secondary)};

    h4 {
        margin: 0;
        color: ${style => (style.metReqiredQty ? style.theme.background.paper : style.theme.text.main)};
    }
`;

export default InventoryListItem;
