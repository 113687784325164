import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import MainMenu from '../main-menu/MainMenu';
import DashboardContainer from '../../domain/dashboard/DashboardContainer';
import PublisherManagementContainer from '../../domain/publishers/PublisherManagementContainer';
import ScryManagementContainer from '../../domain/scry/ScryManagementContainer';
import OrganizationContainer from '../../domain/myorg/OrganizationContainer';
import { MenuSlugs } from 'core/services/FeatureService';
import { NarrationStateProvider } from 'core/state/narration/narration.state';
import NarrationManagementContainer from '../../domain/narration/NarrationManagementWrapper';
import RedeemerContainer from 'domain/redeemer/RedeemerContainer';
import { QueryClientProvider, QueryClient } from 'react-query';
import UserManagement from '../../domain/users';

export const queryClient = new QueryClient();

const AuthorizedLayout: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AppLayout>
                <MainMenu />
                <div style={{ marginLeft: 65, display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <Switch>
                        <Route path={`/${MenuSlugs.Dashboard}`} component={DashboardContainer} />
                        <Route path={`/${MenuSlugs.Publishers}`} component={PublisherManagementContainer} />

                        <NarrationStateProvider>
                            <Route path={`/${MenuSlugs.Redeemer}`} component={RedeemerContainer} />
                            <Route path={`/${MenuSlugs.Narration}`} component={NarrationManagementContainer} />
                            <Route path={`/${MenuSlugs.Scry}`} component={ScryManagementContainer} />
                            <Route path={`/${MenuSlugs.Users}`} component={UserManagement} />
                        </NarrationStateProvider>
                        <Route path={`/${MenuSlugs.Organization}`} component={OrganizationContainer} />
                        <Redirect to={`/${MenuSlugs.Dashboard}`} />
                    </Switch>
                </div>
            </AppLayout>
        </QueryClientProvider>
    );
};

const AppLayout = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    flex: 1;
    justify-content: flex-start;
`;

export default AuthorizedLayout;
