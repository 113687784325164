import * as yup from 'yup';

//eslint-disable-next-line
const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function isValidEmail(value: string): boolean {
    return regex.test(value);
}

export const emailSchema = yup.object().shape({
    email: yup.string().email().required(),
});

export default isValidEmail;
