import React, { FC, useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import MediaAssetBox from 'components/shared/MediaAssetBox';
import { ImageContentType } from 'domain/scry/models/Community';
interface AddImageBlockProps {
    editIndex: number;
    editFields: ImageContentType | null;
    add: (arg0: ImageContentType) => void;
    update: (data: any) => void;
}

const AddImageBlock: FC<AddImageBlockProps> = ({ add, update, editIndex, editFields }) => {
    const [altTag, setAltTag] = useState('');
    const [uploadedImageId, setUploadedImageId] = useState<string>('');

    useEffect(() => {
        if (editFields) {
            setAltTag(editFields?.title ?? '');
        }
    }, [editFields]);

    const handleSubmit = () => {
        (editFields && editIndex !== -1 ? update : add)({
            contentType: 'Image',
            imageId: uploadedImageId,
            title: altTag,
        });
    };

    const handleSelect = (libraryId: string) => {
        setUploadedImageId(libraryId);
    };

    const isDisabled = () => {
        if (!editFields) {
            return altTag === '' || !uploadedImageId;
        }

        return altTag === '';
    };

    const previewUrl = () => {
        if (uploadedImageId) {
            return uploadedImageId.replace(/-/g, '');
        }
        if (editFields) {
            return editFields.imageId.replace(/-/g, '');
        }
        return '';
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    id="altTag"
                    name="altTag"
                    variant="filled"
                    label="Image alt tag (required)"
                    type="text"
                    value={altTag}
                    onChange={e => setAltTag(e.target.value)}
                    helperText="This title will not be displayed to users, only to the browser"
                />
            </Grid>
            <Grid item xs={12} style={{ margin: '10px 0 20px' }}>
                <MediaAssetBox dataName="" url={previewUrl()} selectAction={handleSelect} />
            </Grid>
            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button disabled={isDisabled()} onClick={handleSubmit} variant="contained" color="primary">
                    Save Image Block
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddImageBlock;
