import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ImageUploader from 'components/shared/ImageUploader';
import { NewImageBlob, UploadContainerImageTypes } from 'core/models/Game';

interface PreviewUrls {
    foregroundUri: string;
    backgroundUri: string;
}
interface GameMediaProps {
    handleImageUpload: (arg0: NewImageBlob, arg1: UploadContainerImageTypes) => void;
    previewUrls: PreviewUrls;
}
interface Image {
    imageUrl: string;
    name: 'foregroundUri' | 'backgroundUri';
    uploadName: UploadContainerImageTypes;
    label: string;
}

const GameMediaForm: FC<GameMediaProps> = ({ handleImageUpload, previewUrls }) => {
    const imageUploadTypes: Array<Image> = [
        {
            imageUrl: previewUrls.foregroundUri,
            name: 'foregroundUri',
            uploadName: 'uploadedForegroundUri',
            label: 'Foreground / Thumbnail',
        },
        {
            imageUrl: previewUrls.backgroundUri,
            uploadName: 'uploadedBackgroundUri',
            name: 'backgroundUri',
            label: 'Background',
        },
    ];

    return (
        <>
            <Typography variant="body2" style={{ fontWeight: 600, margin: '35px 0 5px 0' }}>
                Container Graphic Assets
            </Typography>
            <Grid container direction="row" alignItems="flex-start" spacing={3}>
                {imageUploadTypes.map((image: Image, index: number) => (
                    <Grid item xs={4} lg={3} key={`${index}-${image.name}`}>
                        <ImageUploader
                            imageUrl={image.imageUrl}
                            name={image.name}
                            id={image.name}
                            label={image.label}
                            onSuccessfullDrop={(event: NewImageBlob) => handleImageUpload(event, image.uploadName)}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default GameMediaForm;
