import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { useTheme, Typography } from '@material-ui/core';
import { Publish, Create } from '@material-ui/icons';
import { InteractionActions } from 'core/state/scry/scry.actions';
import DropToUpload from 'react-drop-to-upload';
interface ImageUploaderProps {
    dispatch: any;
    cardUri: string;
}

const ImageUploader: FC<ImageUploaderProps> = ({ dispatch, cardUri }: ImageUploaderProps) => {
    const { palette } = useTheme();

    const dropContainerCss = {
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flex: 1,
        border: `2px dashed ${palette.text.disabled}`,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        marginTop: 16,
    };

    const handleDrop = (files: any) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            event.preventDefault();
            dispatch({
                type: InteractionActions.UPLOAD_CARD_URI,
                value: {
                    file: files[0],
                    cardUri: URL.createObjectURL(files[0]),
                },
            } as any);
        };
        reader.readAsDataURL(files[0]);
    };

    const handleUpload = (event: any) => {
        handleDrop(event.target.files);
    };

    const handleClickToUpload = () => {
        const el = document.getElementById('cardUri');
        if (el) {
            el.click();
        }
    };

    return (
        <DropToUpload style={dropContainerCss} onDrop={handleDrop}>
            <HiddenInput type="file" name="cardUri" id="cardUri" accept="image/png" onChange={handleUpload} />
            {cardUri !== '' ? (
                <Fragment>
                    <EditImage onClick={handleClickToUpload} />
                    <img src={cardUri} width="100%" alt="" />
                </Fragment>
            ) : (
                <UploadLabel theme={palette}>
                    <Publish onClick={handleClickToUpload} style={{ cursor: 'pointer' }} />
                    <Typography variant="h4" style={{ margin: 0, color: palette.text.disabled }}>
                        upload or drag image
                    </Typography>
                </UploadLabel>
            )}
        </DropToUpload>
    );
};

const EditImage = styled(Create)`
    position: absolute;
    top: 15px;
    left: 15px;
    color: white;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 3px;
`;

const UploadLabel = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: ${style => style.theme.text.secondary};
    flex-direction: column;

    svg {
        font-size: 3rem;
    }
`;

const HiddenInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    opacity: 0;
`;

export default ImageUploader;
