import { useAddSkuToAccountByEmail } from 'core/api/users';
import Game from 'core/models/Game';
import { FortellerOrderEventData, OrderSources, ProfileWithOrderHistory } from 'core/models/User';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useAppState } from 'core/state/app.state';
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    List,
    ListItemText,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@material-ui/core';
import { Add, Done } from '@material-ui/icons';
import React from 'react';
import { sortByKey } from 'domain/narration/utils';
import styled from 'styled-components';

interface ListAccountProps {
    accounts: ProfileWithOrderHistory[];
    games: Game[];
    error: null | Error;
    refetch: any;
}

const ListAccounts = ({ accounts, games, error, refetch }: ListAccountProps) => {
    const { palette } = useTheme();
    const narrationGames = games.sort();
    const [, appDispatch] = useAppState();

    const NoDataFound = () => <Typography color="textSecondary">No data available</Typography>;

    const handleDelete = () => {
        appDispatch({
            type: AppActions.TOAST_SNACK,
            message: 'remove feature coming soon',
            status: 'success',
            open: true,
        } as ToastSnackAction);
    };

    const OwnedNarrationBlock = ({ skus }: { skus: string[] }) => {
        if (!skus.length) return <NoDataFound />;
        return (
            <>
                {skus.map((s: string) => {
                    const gameData = narrationGames.find(game => game.sku === s);
                    return (
                        <StyledChip
                            key={s}
                            theme={palette}
                            avatar={
                                <Avatar
                                    alt={gameData?.name}
                                    src={gameData?.gameDetailCardUri}
                                    style={{ marginRight: 10 }}
                                />
                            }
                            label={gameData?.name}
                            onDelete={handleDelete}
                        />
                    );
                })}
            </>
        );
    };

    const PurchaseHistory = ({ orders }: { orders: FortellerOrderEventData<any>[] }) => {
        if (!orders.length) return <NoDataFound />;
        const sortedOrders = sortByKey(orders, 'addedAtUtc', true);

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Added At Date</TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell>Order</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {sortedOrders.map((o: FortellerOrderEventData<any>, i: number) => {
                        const sourceValue =
                            o.raw?.name === OrderSources.BackstageOrder ? OrderSources.BackstageOrder : o.source;
                        return (
                            <TableRow key={`${o._id}-${i}`}>
                                <TableCell size="small">{new Date(o.addedAtUtc).toLocaleDateString('en-US')}</TableCell>
                                <TableCell size="small">{sourceValue}</TableCell>
                                <TableCell size="small">
                                    {sourceValue === OrderSources.Shopify ? (
                                        <a
                                            style={{ color: 'white' }}
                                            href={`https://${process.env.REACT_APP_SHOPIFY_URL}/admin/orders/${o.raw.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {o.raw.id}
                                        </a>
                                    ) : (
                                        o.skus.join(',')
                                    )}
                                </TableCell>
                                <TableCell size="small">${o.total}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    };

    const AddGameToAccount = ({ email, ownedSkus, userId }: { email: string; ownedSkus: string[]; userId: string }) => {
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
        const { mutateAsync, isLoading } = useAddSkuToAccountByEmail(email);

        const handleAddSkuToAccount = async (sku: string) => {
            try {
                await mutateAsync({ skus: [sku], firebaseUserId: userId });
                refetch();
            } catch (err) {
                appDispatch({
                    type: AppActions.TOAST_SNACK,
                    message: (err as Error).message,
                    status: 'error',
                    open: true,
                } as ToastSnackAction);
            }
        };
        return (
            <Box width="100%" textAlign="right">
                <Button
                    onClick={e => setAnchorEl(e.currentTarget)}
                    aria-controls="all-games-menu"
                    disabled={isLoading}
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    startIcon={isLoading ? undefined : <Add />}
                >
                    {isLoading ? <CircularProgress size="24px" /> : 'Add Game'}
                </Button>
                <Menu
                    id="all-games-menu"
                    onClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    style={{
                        maxHeight: 300,
                    }}
                >
                    {narrationGames.map(game => (
                        <MenuItem
                            key={game.sku}
                            style={{
                                color: ownedSkus.includes(game.sku) ? palette.text.secondary : palette.text.main,
                            }}
                            onClick={() => {
                                if (!ownedSkus.includes(game.sku)) {
                                    handleAddSkuToAccount(game.sku);
                                }
                                setAnchorEl(null);
                            }}
                        >
                            {ownedSkus.includes(game.sku) && <Done style={{ marginRight: 5 }} />}
                            {game.name}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        );
    };

    const AccountRow = ({ account, totalRows }: { account: ProfileWithOrderHistory; totalRows: number }) => {
        return (
            <Box
                mb={3}
                pb={4}
                style={{ borderBottom: totalRows > 1 ? `1px solid ${palette.borders.paperColor}` : 'none' }}
            >
                <Grid container spacing={2} style={{ position: 'relative' }}>
                    <Grid item xs={4}>
                        <List>
                            <ListItemText primary="Display Name" secondary={account.displayName} />
                            <ListItemText primary="Email Address" secondary={account.email} />
                            <ListItemText primary="Firebase User Id" secondary={account.firebaseUserId} />
                        </List>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h2">Owned Narration</Typography>
                        <OwnedNarrationBlock skus={account.ownedSkus} />
                    </Grid>

                    <Grid item xs={2}>
                        <AddGameToAccount
                            email={account.email}
                            ownedSkus={account.ownedSkus}
                            userId={account.firebaseUserId}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2">Order History</Typography>
                        <PurchaseHistory orders={account.orders.data} />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    if (error?.message.includes('404')) {
        return <NoDataFound />;
    }

    if (!accounts.length) return null;

    return (
        <>
            {accounts.map((a: ProfileWithOrderHistory) => (
                <AccountRow key={a._id} account={a} totalRows={accounts.length} />
            ))}
        </>
    );
};

const StyledChip = styled(Chip)<{ theme: any }>`
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: ${props => props.theme.background.secondaryPaper};
    .MuiChip-label {
        padding-left: 0px;
        color: ${props => props.theme.text.main};
    }
`;

export default ListAccounts;
