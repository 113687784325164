import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme, Tabs, Tab } from '@material-ui/core';

type EachTab = {
    label: string;
    panel: any;
    addInlineStyle?: any;
};

interface TabProps {
    gameId: string;
    tabs?: Array<EachTab>;
    disableChange: boolean;
}

const TabContent: FC<TabProps> = ({ tabs, disableChange, gameId }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { search } = useLocation();
    const { palette } = useTheme();

    useEffect(() => {
        if (search.includes('?metrics=true')) {
            setActiveTab(1);
        }
    }, [search]);

    const handleTabChange = (event: any, newValue: number) => {
        if (!disableChange) {
            setActiveTab(newValue);
            if (search.includes('?metrics=true') && newValue !== 1) {
                // TODO: why does this couple to scry if this is a general tab component?
                console.warn('FIX THIS TIGHT COUPLING');
                window.history.pushState({}, document.title, `/scry/${gameId}`);
            }
        }
    };

    return (
        <TabContainer theme={palette}>
            <StyledTabs theme={palette} value={activeTab} onChange={handleTabChange}>
                {tabs!.map((tab, index) => (
                    <Tab key={index} label={tab.label} />
                ))}
            </StyledTabs>

            {tabs!.map((tab, index) => {
                const customStyle = tab.addInlineStyle ? tab.addInlineStyle : {};
                return (
                    <TabPanel key={index} role="tabpanel" hidden={activeTab !== index}>
                        <div className="tab-panel-content" style={customStyle}>
                            {tab.panel}
                        </div>
                    </TabPanel>
                );
            })}
        </TabContainer>
    );
};

const TabContainer = styled.div`
    background-color: ${style => style.theme.background.paper};
`;

const TabPanel = styled.div<any>`
    .tab-panel-content {
        display: flex;
        flex-direction: column;
        padding: 0 30px 10px;

        .inventory-item {
            margin-bottom: 10px;
        }
    }
`;

const StyledTabs = styled(Tabs)<any>`
    &.MuiTabs-root {
        min-height: 1px;
        margin: 0 0 20px 0;
        padding-left: 20px;
        border-bottom: 1px solid ${style => style.theme.background.secondaryPaper};

        .MuiTabs-scroller {
            padding: 10px 0;
            margin: 0;

            button {
                width: fit-content;
                min-width: 1px;
            }
        }
    }

    .MuiButtonBase-root {
        min-width: 117px;
        font-size: 0.813rem;
        color: ${style => style.theme.text.secondary};

        &.Mui-selected {
            color: ${style => style.theme.text.main};
        }
    }
    .MuiTabs-indicator {
        background-color: ${style => style.theme.action.active};
    }
`;

export default TabContent;
