import React, { FC, ReactElement } from 'react';
import { Button, Grid, Typography, useTheme, DialogContent, Dialog } from '@material-ui/core';
import { ArrowBackRounded, CloseRounded } from '@material-ui/icons';
import styled from 'styled-components';

interface ManageContentModalProps {
    changePage: (pageName: string) => void;
    editIndex: number;
    toggleModal: () => void;
    modalOpen: boolean;
    modalPage: string;
    pageComponent: ReactElement;
}

const ManageContent: FC<ManageContentModalProps> = ({
    changePage,
    modalOpen,
    modalPage,
    pageComponent,
    toggleModal,
    editIndex,
}) => {
    const { palette } = useTheme();
    return (
        <Modal fullScreen={true} open={Boolean(modalOpen)} modalpage={modalPage}>
            <DialogHeader theme={palette} container direction="row" justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {modalPage !== 'create' && editIndex === -1 && (
                        <BackButton onClick={() => changePage('create')} theme={palette} />
                    )}
                    <Typography>{editIndex === -1 ? 'Add new' : 'Edit'} content block</Typography>
                </div>
                <Button style={{ padding: 0, minWidth: 0 }} onClick={toggleModal}>
                    <CloseRounded />
                </Button>
            </DialogHeader>
            <DialogContent style={{ padding: 10, overflow: 'hidden' }}>{pageComponent}</DialogContent>
        </Modal>
    );
};

const Modal = styled(Dialog)<{ modalpage: string }>`
    .MuiDialog-paper {
        width: 700px;
        margin: 0;
        height: fit-content;
        padding-bottom: ${style => (style.modalpage === 'create' ? '30px' : '10px')};
        margin-left: 65px;
    }
`;

const DialogHeader = styled(Grid)`
    padding: 10px;
    border-bottom: 1px solid ${style => style.theme.borders.paperColor};
`;

const BackButton = styled(ArrowBackRounded)`
    color: ${style => style.theme.text.main};
    margin-right: 10px;
    cursor: pointer;
`;

export default ManageContent;
