/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme, Typography, Grid, Button } from '@material-ui/core';
import { BackupRounded, Folder } from '@material-ui/icons';
import styled from 'styled-components';

import DropToUpload from 'react-drop-to-upload';
import AssetUploader from './AssetUploader';
import { Job } from 'core/models/Asset';
import CatalogService from 'core/services/CatalogService';
export interface FileToUpload {
    data: File;
    error?: boolean;
    fetchStatus: 'stale' | 'fetching' | 'error';
}
interface UploadAssetContainerProps {
    jobs: Array<Job>;
    fetchActiveJobs: () => void;
    fetchMediaAssets: () => void;
}

const UploadAssetContainer: FC<UploadAssetContainerProps> = ({ jobs, fetchActiveJobs, fetchMediaAssets }) => {
    const { palette } = useTheme();
    const { gameId }: { gameId: string } = useParams();
    const [filesToUpload, setFilesToUpload]: [Array<FileToUpload>, React.Dispatch<any>] = useState([]);
    const [pollStatus, setPollStatus] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (jobs.length > 0 && pollStatus && anchorEl) {
            const interval = setInterval(() => {
                fetchActiveJobs();
                fetchMediaAssets();
            }, 10000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [jobs, anchorEl]);

    const dropContainerCss = {
        margin: '0 0 25px 0',
        padding: 20,
        border: `1px dashed ${palette.borders.secondaryPaperColor}`,
    };

    const changeFetchStatus = (
        files: Array<FileToUpload>,
        fileIndex: number,
        status: 'error' | 'fetching' = 'fetching'
    ) => {
        let newFileArray = files;
        newFileArray[fileIndex].fetchStatus = status || 'fetching';
        newFileArray[fileIndex].error = true;
        setFilesToUpload(newFileArray);
    };

    const retryUpload = (index: number) => {
        let updateFetchingStatus = filesToUpload;
        updateFetchingStatus[index].fetchStatus = 'fetching';
        setFilesToUpload(updateFetchingStatus);

        const failedUpload = filesToUpload[index];
        startJob(filesToUpload, failedUpload.data.name, failedUpload, index, 0);
    };

    const startJob = (
        currentFilesArray: FileToUpload[],
        sourceFileName: string,
        file: FileToUpload,
        index: number,
        delay: number
    ) => {
        setTimeout(async () => {
            try {
                const response = await CatalogService.createMediaJob({
                    gameId,
                    sourceFileName,
                });
                await CatalogService.uploadAsset(response.uploadSasUri, file.data);
            } catch (err) {
                changeFetchStatus(currentFilesArray, index, 'error');
            } finally {
                if (index + 1 === currentFilesArray.length) {
                    setPollStatus(true);
                    setFilesToUpload(filesToUpload.filter((file: FileToUpload) => file.error));
                    fetchActiveJobs();
                }
            }
        }, delay);
    };

    const createJobs = (files: Array<FileToUpload>) => {
        setPollStatus(false);
        // eslint-disable-next-line array-callback-return
        files.map((file: FileToUpload, index: number) => {
            startJob(files, file.data.name, file, index, index * 1200);
        });
    };

    const handleDrop = (files: any) => {
        const filesAreUploading = filesToUpload.filter((file: FileToUpload) => !file.error).length > 0;
        if (filesAreUploading) {
            return null;
        }
        const uploadedFileList = files.reduce(
            (acc: Array<FileToUpload>, curr: File) => [
                ...acc,
                {
                    data: curr,
                    fetchStatus: 'fetching',
                    error: false,
                },
            ],
            []
        );

        setFilesToUpload(uploadedFileList);
        setTimeout(() => {
            createJobs(uploadedFileList);
            document.getElementById('upload-trigger')?.click();
        }, 350);
    };

    const handleFileNotificationPopup = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseNotificationPopup = () => {
        setAnchorEl(null);
    };

    const handleDeleteUploadItem = (deleteIndex: number) => {
        const newState = filesToUpload.filter((item: FileToUpload, index: number) => index !== deleteIndex);
        setFilesToUpload(newState);
    };

    return (
        <>
            <DropToUpload style={dropContainerCss} accept="image/*,audio/*,video/*" onDrop={handleDrop}>
                <Grid container justifyContent="center" spacing={1} alignItems="center">
                    <Grid item>
                        <BackupRounded color="disabled" />
                    </Grid>
                    <Grid item>
                        <Typography color="textSecondary">Drag files here to load new assets</Typography>
                    </Grid>
                </Grid>
            </DropToUpload>
            {(filesToUpload.length > 0 || jobs.length > 0) && (
                <NotificationsContainer>
                    <NotificationsTrigger
                        theme={palette}
                        aria-describedby="upload-status-container"
                        onClick={handleFileNotificationPopup}
                        variant="text"
                        id="upload-trigger"
                    >
                        <Folder />
                    </NotificationsTrigger>
                    <AssetUploader
                        retryUpload={retryUpload}
                        handleDeleteUploadItem={handleDeleteUploadItem}
                        anchorEl={anchorEl}
                        handleCloseNotificationPopup={handleCloseNotificationPopup}
                        fetchActiveJobs={fetchActiveJobs}
                        filesToUpload={filesToUpload}
                        fetchMediaAssets={fetchMediaAssets}
                        activeJobs={jobs}
                    />
                </NotificationsContainer>
            )}
        </>
    );
};

const NotificationsContainer = styled.div`
    position: absolute;
    right: 30px;
    top: 80px;
`;

const NotificationsTrigger = styled(Button)`
    padding: 15px;
    border-radius: 100%;
    min-width: 1px;
    background-color: ${style => style.theme.background.paper};
    color: ${style => style.theme.action.active};

    &:hover {
        background-color: ${style => style.theme.background.paper} !important;
    }
`;

export default UploadAssetContainer;
