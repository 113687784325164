import React, { FC } from 'react';
import { Grid, useTheme, Typography } from '@material-ui/core';
import { WallpaperRounded, LabelRounded, TextFormatRounded } from '@material-ui/icons';
import styled from 'styled-components';

interface AddBlockListProps {
    changePage: (name: string) => void;
}

const AddBlockList: FC<AddBlockListProps> = ({ changePage }) => {
    const { palette } = useTheme();
    const blockTypes = [
        {
            name: 'html',
            pageName: 'add-html',
            title: 'HTML Block',
            desc: 'Full WYSIWYG for typography text',
        },
        {
            name: 'image',
            pageName: 'add-image',
            title: 'Image Block',
            desc: 'Add images with other content blocks',
        },
        {
            name: 'tag',
            pageName: 'add-tag',
            title: 'Content Tags',
            desc: 'Create a tag list',
        },
    ];

    const blockIcon = (type: 'text' | 'image' | 'tag') => {
        switch (type) {
            case 'text':
                return <TextFormatRounded />;
            case 'image':
                return <WallpaperRounded />;
            default:
                return <LabelRounded />;
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
            style={{ width: 650, margin: '0 auto' }}
        >
            {blockTypes.map((type: any) => (
                <Grid item key={type.name} xs={4} style={{ textAlign: 'center' }}>
                    <TypeIcon onClick={() => changePage(type.pageName)} theme={palette}>
                        {blockIcon(type.name)}
                    </TypeIcon>
                    <Typography variant="h2">{type.title}</Typography>
                    <Typography color="textSecondary" style={{ maxWidth: 150, margin: '0 auto' }}>
                        {type.desc}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};

const TypeIcon = styled.div`
    display: flex;
    flex: 1;
    background-color: ${style => style.theme.background.secondaryPaper} !important;
    border-radius: 8px;
    padding: 20px;
    box-shadow: none !important;
    justify-content: center;
    align-items: center;
    width: 120px;
    cursor: pointer;
    margin: 10px auto 0;

    svg {
        font-size: 69px;
        color: ${style => style.theme.text.main};
    }
`;

export default AddBlockList;
