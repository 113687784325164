/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Button,
    useTheme,
} from '@material-ui/core';
import { ArrowRight, SearchRounded, WallpaperRounded } from '@material-ui/icons';

import { MenuSlugs } from 'core/services/FeatureService';
import { FetchPublisherRequestAction, PublisherActions } from 'core/state/publishers/publisher.actions';
import { useAppState } from 'core/state/app.state';

import PageContainer from 'components/shared/PageContainer';
import PageTitle from 'components/shared/PageTitle';
import { Publisher } from 'core/models/PublisherDefinitions';

const PublisherList: FC = () => {
    const [{ publishers, auth }]: any = useAppState();
    const [filter, setFilter] = useState('');
    const { push } = useHistory();
    const { palette } = useTheme();

    const fetchListOfPublishers = useCallback(async () => {
        publishers.dispatch({
            type: PublisherActions.FETCH_PUBLISHERS_REQUEST,
            dispatcher: publishers.dispatch,
        } as FetchPublisherRequestAction);
    }, [publishers.dispatch]);

    useEffect(() => {
        if (publishers.ready === false && auth.currentUser) {
            fetchListOfPublishers();
        }
    }, [publishers.ready, auth.currentUser, fetchListOfPublishers]);

    const publisherRows = () => {
        const publisherList: Array<Publisher> =
            filter.length > 0
                ? publishers?.list?.filter((p: Publisher) => p.name.toLowerCase().indexOf(filter) !== -1)
                : publishers.list;

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Logo</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {publisherList.map(({ name, id, logoUri }: Publisher) => {
                            return (
                                <TableRow
                                    key={id}
                                    onClick={() => push(`/publishers/edit/${id}`)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell align="center">
                                        {logoUri !== '' ? (
                                            <img src={logoUri} alt="" height="44" width="44" />
                                        ) : (
                                            <NoImageFound theme={palette}>
                                                <WallpaperRounded color="secondary" />
                                            </NoImageFound>
                                        )}
                                    </TableCell>
                                    <TableCell width="80%">{name}</TableCell>
                                    <TableCell align="center">{true ? 'Enabled' : 'Disabled'}</TableCell>
                                    <TableCell align="center" width="30">
                                        <ArrowRight color="secondary" />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <PageContainer removeSidebar>
            <PageTitle text="Publisher Accounts" />
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid xs={8} md={6} lg={4} item>
                    <TextField
                        id="filter"
                        name="filter"
                        label="Filter Publishers"
                        autoComplete="off"
                        variant="filled"
                        size="small"
                        fullWidth
                        type="text"
                        onChange={(event: any) => setFilter(event.target.value.toLowerCase())}
                        value={filter}
                        InputProps={{
                            endAdornment: <SearchRounded />,
                        }}
                    />
                </Grid>

                <Grid sm="auto" item>
                    <Button variant="contained" color="primary" onClick={() => push(`/${MenuSlugs.Publishers}/add`)}>
                        New Publisher
                    </Button>
                </Grid>

                <Grid container direction="column" style={{ marginTop: 25 }}>
                    {publisherRows()}
                </Grid>
            </Grid>
        </PageContainer>
    );
};

const NoImageFound = styled.div`
    display: flex;
    flex: 1;
    width: 44px;
    height: 44px;
    background-color: ${style => style.theme.borders.secondaryPaperColor};
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`;

export default PublisherList;
