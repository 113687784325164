/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useReducer, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, Button, CircularProgress, Box } from '@material-ui/core';
import { MenuSlugs } from 'core/services/FeatureService';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';

import PageContainer from 'components/shared/PageContainer';
import PageBreadcrumbs from 'components/shared/BreadCrumbs';
import CommunityMetaForm from './components/CommunityMetaForm';
import CommunityDetailBlocks from './components/CommunityDetailBlocks';

import { useScryState } from 'core/state/scry/scry.state';
import { useAppState } from 'core/state/app.state';
import { ScryActions } from 'core/state/scry/scry.actions';

import CatalogService from 'core/services/CatalogService';
import {
    useAddNewCommunity,
    useDeleteCommunityById,
    useGetCommunities,
    useUpdateCommunityById,
} from '../api/communities';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';
import { BlockTypes, Community, ContentBodyType, CreateCommunityRequest } from '../models/Community';
import { AxiosResponse } from 'axios';
import ConfirmDeleteModal from './components/modals/ConfirmDelete';
import isBase64 from 'core/validators/base64';

interface CommunityState {
    ready: boolean;
    loading: boolean;
    id: string;
    name: string;
    cardId: string;
    iconId: string;
    savedContentBlocks: Array<BlockTypes>;
    modalOpen: boolean;
}

enum CommunityAction {
    SET_STATE = 'set state',
    SET_COMMUNITY_DATA = 'set community data',
    HANDLE_ASSET_SELECT = 'asset select',
    HANDLE_SAVE_COMMUNITY_BLOCK = 'save community block',
    TOGGLE_DELETE_MODAL = 'toggle delete modal',
}

const initialState: CommunityState = {
    ready: false,
    loading: false,
    id: '',
    name: '',
    cardId: '',
    iconId: '',
    savedContentBlocks: [],
    modalOpen: false,
};

const reducer = (state: CommunityState, [type, payload]: [CommunityAction, any]) => {
    switch (type) {
        case CommunityAction.SET_STATE:
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case CommunityAction.SET_COMMUNITY_DATA:
            return {
                ...state,
                ...payload,
            };
        case CommunityAction.HANDLE_SAVE_COMMUNITY_BLOCK:
            return {
                ...state,
                savedContentBlocks: payload.data,
            };
        case CommunityAction.HANDLE_ASSET_SELECT:
            return {
                ...state,
                [payload.assetName]: payload.libraryId,
            };
        case CommunityAction.TOGGLE_DELETE_MODAL:
            return {
                ...state,
                modalOpen: payload,
            };
        default:
            return state;
    }
};

const Communities: FC = () => {
    const communityNameRef = useRef('');
    const { gameId, communityId }: { gameId: string; communityId: string } = useParams();
    const { push, goBack } = useHistory();
    const [{ game }, scryDispatch]: any = useScryState();
    const [{ auth }, appDispatch] = useAppState();
    const [{ ready, id, modalOpen, name, cardId, iconId, savedContentBlocks }, dispatch] = useReducer(
        reducer,
        initialState
    );
    const { mutateAsync: addNewCommunity, isLoading, isSuccess } = useAddNewCommunity(gameId);
    const { mutateAsync: editCommunity, isLoading: editLoading } = useUpdateCommunityById(gameId, communityId);
    const { mutateAsync: deleteCommunity, isLoading: deleteLoading } = useDeleteCommunityById(gameId);

    const [newGameId, setNewGameId] = useState<string | null>(null);

    const { data } = useGetCommunities(gameId);
    const communities = data?.data ?? [];

    useEffect(() => {
        if (communityId && communities.length > 0) {
            const editCommunity = communities.find((c: Community) => c.id === communityId);
            if (editCommunity) {
                communityNameRef.current = editCommunity.name;
                dispatch([CommunityAction.SET_COMMUNITY_DATA, { loading: false, ready: true, ...editCommunity }]);
            }
        }

        if (!communityId) {
            dispatch([CommunityAction.SET_STATE, { key: 'ready', value: true }]);
            communityNameRef.current = 'New Community';
        }
    }, [communityId, communities]);

    useEffect(() => {
        if (isSuccess && newGameId) {
            push(`/games/scry/${gameId}/communities/${newGameId}`);
        }
    }, [isSuccess, newGameId]);

    const fetchGameData = async () => {
        try {
            const games = await CatalogService.getGameById(gameId);
            scryDispatch({
                type: ScryActions.GET_GAME_DATA,
                value: games,
            });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (game.status === 'stale' && auth.currentUser) {
            fetchGameData();
        }
    }, [auth.currentUser, game.status]);

    const pageName = () => {
        if (game.status === 'stale') {
            return '';
        }
        return communityNameRef.current !== '' ? communityNameRef.current : 'New Community';
    };

    const handleChange = (event: { target: { value: any; id: string } }) => {
        const { value, id } = event.target;
        dispatch([CommunityAction.SET_STATE, { key: id, value }]);
    };

    const handleAssetSelection = (libraryId: string, assetName: string) => {
        dispatch([CommunityAction.HANDLE_ASSET_SELECT, { assetName, libraryId }]);
    };

    const handleContentBlockChange = (blockArray: Array<BlockTypes>) => {
        dispatch([CommunityAction.HANDLE_SAVE_COMMUNITY_BLOCK, { data: blockArray }]);
    };

    const handleSubmit = async () => {
        let request: AxiosResponse<Community> | undefined;
        const postData = {
            ...(communityId && { id, gameId }),
            name,
            cardId,
            iconId,
            content: savedContentBlocks.reduce((acc: any, curr: BlockTypes) => {
                if (curr.contentType === 'Html') {
                    const data = (curr as ContentBodyType).content;
                    return [
                        ...acc,
                        {
                            ...curr,
                            content: !isBase64(data) ? btoa(data) : data,
                        },
                    ];
                }
                return [...acc, curr];
            }, []),
        };

        try {
            if (communityId) {
                request = await editCommunity(postData as Community);
            } else {
                request = await addNewCommunity(postData as CreateCommunityRequest);
            }

            if (!communityId && request?.data?.id) {
                setNewGameId(request?.data?.id);
            }
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: `${name} updated successfully!`,
                status: 'success',
                open: true,
            } as ToastSnackAction);
        } catch {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'Failed to save community. Please try again',
                status: 'error',
                open: true,
            } as ToastSnackAction);
        }
    };

    const submitDeleteCommunity = async () => {
        try {
            await deleteCommunity(communityId);
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: `${name} has been deleted successfully`,
                status: 'success',
                open: true,
            } as ToastSnackAction);
            push(`/games/scry/${gameId}`);
        } catch {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'There was an error when trying to delete this community. Please try again',
                status: 'error',
                open: true,
            } as ToastSnackAction);
        }
    };

    const buttonIsDisabled = () => {
        const fields = [name !== '', cardId !== '', iconId !== '', savedContentBlocks.length > 0];
        return !fields.every((field: boolean) => field);
    };

    return (
        <PageContainer removeSidebar addInlineStyle={{ marginTop: 0 }}>
            {game.status !== 'stale' && (
                <PageBreadcrumbs
                    links={[{ label: game.name, to: `/${MenuSlugs.Scry}/${gameId}` }, { label: pageName() as string }]}
                />
            )}

            {!ready || game.status === 'stale' ? (
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <CircularProgress color="secondary" size="3rem" />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ marginTop: 30 }}
                    >
                        <Grid item>
                            <Typography variant="h1">{communityNameRef.current}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex-end" direction="row" spacing={3}>
                                <Grid item>
                                    <Button onClick={goBack}>Cancel</Button>
                                </Grid>

                                <Grid item>
                                    <Button
                                        disabled={editLoading || isLoading || buttonIsDisabled()}
                                        onClick={handleSubmit}
                                        variant="contained"
                                        color="primary"
                                    >
                                        <ProgressiveButtonText
                                            isLoading={editLoading || isLoading}
                                            text="Save Changes"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <CommunityMetaForm
                            handleChange={handleChange}
                            handleAssetSelect={handleAssetSelection}
                            data={{ name, cardId, iconId }}
                        />
                        <CommunityDetailBlocks
                            handleContentBlockChange={handleContentBlockChange}
                            communities={communities || []}
                        />
                    </Grid>
                    {communityId && (
                        <Box paddingTop={3}>
                            <Button
                                onClick={() => dispatch([CommunityAction.TOGGLE_DELETE_MODAL, true])}
                                color="secondary"
                                variant="contained"
                            >
                                Delete Community
                            </Button>
                        </Box>
                    )}
                </>
            )}
            <ConfirmDeleteModal
                open={modalOpen}
                isLoading={deleteLoading}
                submit={submitDeleteCommunity}
                closeModal={() => dispatch([CommunityAction.TOGGLE_DELETE_MODAL, false])}
            />
        </PageContainer>
    );
};

export default Communities;
