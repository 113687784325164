import React, { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { Snackbar, useTheme } from '@material-ui/core';
import styled from 'styled-components';

interface SnackProps {
    status: 'success' | 'warning' | 'error';
    message: string;
    open: boolean;
    onClose?: () => void;
}

const Snack: React.FC<SnackProps> = ({ status, message, open }) => {
    const [isOpen, setOpen] = useState(false);
    const { palette } = useTheme();

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleClose = (event: any, reason: string) => {
        setOpen(false);
    };

    return (
        <Snackbar open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <StyledAlert elevation={6} variant="filled" severity={status} theme={palette}>
                {message}
            </StyledAlert>
        </Snackbar>
    );
};

const StyledAlert = styled(Alert)`
    background-color: ${style => {
        switch (style.severity) {
            case 'error':
                return style.theme.error.main;
            case 'warning':
                return style.theme.warning.main;
            default:
                return style.theme.success.main;
        }
    }} !important;
    color: ${style => (style.severity === 'success' ? '#000000' : style.theme.text.main)};
`;

export default Snack;
