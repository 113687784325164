import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

interface PageContainerProps {
    children: any;
    spacing?: 'small' | 'medium' | 'large';
    direction?: 'row' | 'column';
    removeSidebar?: boolean;
    addInlineStyle?: any;
}

const PageContainer: FC<PageContainerProps> = ({ children, spacing, removeSidebar, direction, addInlineStyle }) => {
    const spacingValue = () => {
        switch (spacing) {
            case 'large':
                return 5;
            case 'medium':
                return 3;
            case 'small':
                return 0;
            default:
                return 3;
        }
    };
    return (
        <StylePageContainer
            className={removeSidebar ? 'without-sidebar' : ''.trim()}
            style={addInlineStyle || {}}
            container
            direction={direction ? direction : 'column'}
            spacing={spacingValue()}
        >
            {children}
        </StylePageContainer>
    );
};

const StylePageContainer = styled(Grid)`
    margin: 90px 0 0 0;
    padding: 0 35px 0 50px;
    max-width: 1270px;

    @media only screen and (max-width: 784px) {
        padding-left: 25px;
    }

    &.without-sidebar {
        margin: 90px auto 0;
        padding: 0 25px;
    }
`;

export default PageContainer;
