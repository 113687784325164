import React, { FC, useState, useEffect } from 'react';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import styled from 'styled-components';
import { TagContentType } from 'domain/scry/models/Community';

interface AddTagBlockProps {
    add: (arg0: TagContentType) => void;
    update: (arg0: TagContentType) => void;
    editIndex: number;
    editFields: TagContentType | null;
}

const AddTagBlock: FC<AddTagBlockProps> = ({ add, update, editIndex, editFields }) => {
    const [tags, setTags]: [Array<string>, React.Dispatch<any>] = useState([]);
    const [tagTitle, setTagTitle] = useState('');
    const { palette } = useTheme();

    useEffect(() => {
        if (editIndex !== -1 && editFields) {
            setTags(editFields.tags);
            setTagTitle(editFields.title);
        }
    }, [editIndex, editFields]);

    const handleAddTag = (event: any) => {
        const {
            key,
            target: { value },
        } = event;
        if (key === 'Enter') {
            setTags([...tags, value]);
            event.target.value = '';
        }
    };

    const removeTag = (removedIndex: number) => {
        setTags(tags.filter((tag: string, index: number) => index !== removedIndex));
    };

    const handleSubmit = () => {
        (editIndex === -1 ? add : update)({
            contentType: 'Tag',
            title: tagTitle,
            tags,
        } as TagContentType);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    variant="filled"
                    label="List Title"
                    type="text"
                    value={tagTitle}
                    onChange={(event: { target: { value: string } }) => setTagTitle(event.target.value)}
                    helperText="This is the content that will appear above the tag group"
                />
            </Grid>
            <Grid item xs={12} style={{ margin: '15px 0 25px 0' }}>
                <TextField
                    id="tag-array-builder"
                    variant="filled"
                    label="Enter Tag"
                    type="text"
                    onKeyDown={handleAddTag}
                    helperText="Type a key phrase and hit enter to create a tag"
                />
            </Grid>
            {tags.length > 0 && (
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        {tags.map((tag: string, index: number) => (
                            <Tag key={`${index}-${tag}`} theme={palette}>
                                {tag}
                                <Button style={{ padding: 0, margin: 0, minWidth: 1 }}>
                                    <CloseRounded
                                        onClick={() => removeTag(index)}
                                        style={{ width: 14, height: 14, marginLeft: 5 }}
                                    />
                                </Button>
                            </Tag>
                        ))}
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={tags.length === 0 ? true : false}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '10px', padding: '12px' }}
                >
                    Add Tag Block
                </Button>
            </Grid>
        </Grid>
    );
};

const Tag = styled.span`
    display: flex;
    flex-diretion: row;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: ${style => style.theme.background.secondaryPaper};
    color: white;
    font-size: 0.875rem;
    padding: 5px 10px;
`;

export default AddTagBlock;
