import React, { FC, useState, useEffect } from 'react';
import { Grid, TextField, useTheme, Button } from '@material-ui/core';
import styled from 'styled-components';

// wysiwyg
import { convertToRaw, convertFromHTML, ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentBodyType } from 'domain/scry/models/Community';

interface AddTexBlockProps {
    editIndex: number;
    editFields: ContentBodyType | null;
    update: (arg0: ContentBodyType) => void;
    add: (arg0: ContentBodyType) => void;
}

const editorOptions = {
    options: ['blockType', 'colorPicker', 'inline', 'list'],
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3'],
    },
    list: {
        inDropdown: false,
        options: ['ordered', 'unordered'],
    },
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    colorPicker: {
        colors: [
            'rgb(125, 49, 201)',
            'rgb(254, 46, 46)',
            'rgb(46, 254, 169)',
            'rgb(255, 255, 255)',
            'rgb(120, 120, 126)',
        ],
    },
    textAlign: { inDropdown: false },
};

const AddTextBlock: FC<AddTexBlockProps> = ({ add, update, editIndex, editFields }) => {
    const [textTitle, setTextTitle] = useState('');
    const [editorData, setEditorData]: any = useState(undefined);
    const { palette } = useTheme();

    useEffect(() => {
        if (editFields && editIndex !== -1) {
            const convertContentFromBase64 = atob(editFields.content);
            const htmlBlocks = convertFromHTML(convertContentFromBase64);
            const content = ContentState.createFromBlockArray(htmlBlocks.contentBlocks, htmlBlocks.entityMap);
            setEditorData(EditorState.createWithContent(content));
            setTextTitle(editFields.title);
        }
    }, [editFields, editIndex]);

    const handleEditorChange = (event: any) => {
        setEditorData(event);
    };

    const convertEditorToHtml = () => {
        return draftToHtml(convertToRaw(editorData.getCurrentContent())).toString();
    };

    const isDisabled = textTitle === '' || editorData === undefined;

    const handleSubmit = () => {
        (editFields && editIndex !== -1 ? update : add)({
            contentType: 'Html',
            title: textTitle,
            content: convertEditorToHtml(),
        });
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 25 }}>
                <TextField
                    onChange={event => setTextTitle(event.target.value)}
                    variant="filled"
                    label="HTML Block Title (required)"
                    value={textTitle}
                    helperText="This title will not be displayed to users, only as a reference in the editor"
                />
            </Grid>

            <StyledEditorWrapper item xs={12} theme={palette}>
                <Editor
                    editorState={editorData}
                    wrapperClassName="text-block-wrapper"
                    editorClassName="text-block-editor"
                    onEditorStateChange={handleEditorChange}
                    toolbar={editorOptions}
                />
            </StyledEditorWrapper>

            <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button disabled={isDisabled} onClick={handleSubmit} variant="contained" color="primary">
                    Save Text Block
                </Button>
            </Grid>
        </Grid>
    );
};

const StyledEditorWrapper = styled(Grid)`
    margin-bottom: 25px;

    .text-block-editor {
        background-color: ${style => style.theme.background.secondaryPaper};
        padding: 10px 20px;
        min-height: 250px;
        color: ${style => style.theme.text.main};
        font-size: 1rem;
    }
    .rdw-colorpicker-modal {
        height: auto;
    }
    .rdw-colorpicker-modal-options {
        overflow: hidden;
    }
    .rdw-editor-toolbar {
        border-radius: 4px 4px 0 0;
        margin-bottom: 0;
    }
    .DraftEditor-root {
        max-height: 250px;
    }
`;

export default AddTextBlock;
