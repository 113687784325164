import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

interface PageTitleProps {
    text: string;
}

const PageTitle: FC<PageTitleProps> = ({ text }) => {
    return (
        <StyledHeader className="testing" variant="h1">
            {text}
        </StyledHeader>
    );
};

const StyledHeader = styled(Typography)`
    margin-bottom: 50px !important;

    @media only screen AND (max-width: 992px) {
        margin-bottom: 35px !important;
    }
`;

export default PageTitle;
