import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import update from 'immutability-helper';
import ContainerListItem from './ListItem';
import { Grid, Typography } from '@material-ui/core';
import { Containers } from 'core/models/Containers';
import CatalogService from 'core/services/CatalogService';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useContainerState } from 'core/state/narration/container.state';
import { ContainerActionTypes } from 'core/models/Containers';
import { sortByKey } from '../../utils/';

interface ContainerListViewProps {
    containers: Array<any>;
    gameId: string;
}

const ContainerListView: FC<ContainerListViewProps> = ({ gameId, containers }) => {
    const [containerList, setContainerList]: [Array<Containers>, React.Dispatch<any>] = useState([]);
    const containerOrderRef: any = useRef([]);
    const [, appDispatch] = useAppState();
    const { editorDispatch }: any = useContainerState();

    useEffect(() => {
        setContainerList(containers);
        containerOrderRef.current = buildGuidList(containers);
    }, [containers]);

    const buildGuidList = (arr: Array<Containers>) => {
        return arr.reduce((acc: Array<string>, curr: Containers) => [...acc, curr.id], []);
    };

    const changeContainerOrder = async (newArray: Array<string>) => {
        if (newArray !== containerOrderRef.current) {
            try {
                await CatalogService.orderContainers(newArray, gameId);
                editorDispatch([ContainerActionTypes.GET_CONTAINERS, { gameId, dispatch: editorDispatch }]);
            } catch (err) {
                // use the containerOrder state key to determine the old list & update the array accordingly
                const revertedOrder = containerOrderRef.current.reduce((acc: Array<Containers>, curr: string) => {
                    const containerObj = containerList.find((c: Containers) => c.id === curr);
                    return containerObj ? [...acc, containerObj] : acc;
                }, []);
                setContainerList(revertedOrder);
                appDispatch({
                    type: AppActions.TOAST_SNACK,
                    message: 'There was an error when trying to reorder this container',
                    status: 'error',
                    open: true,
                } as ToastSnackAction);
            }
        }
    };

    const moveContainer = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = containerList[dragIndex];
            setContainerList(
                update(containerList, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [containerList]
    );

    const sortedContainers = sortByKey(containers, 'order');

    return (
        <>
            <Typography>Containers:</Typography>
            {sortedContainers.length > 0 ? (
                sortedContainers.map((container: any, index: number) => (
                    <ContainerListItem
                        order={buildGuidList(containerList)}
                        changeContainerOrder={changeContainerOrder}
                        gameId={gameId}
                        containerData={container}
                        key={index}
                        moveContainer={moveContainer}
                        index={index}
                    />
                ))
            ) : (
                <Grid container spacing={5}>
                    <Grid item>
                        <Typography color="textSecondary">
                            You don't have any containers for this game. Click "Create Container" to add one.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ContainerListView;
