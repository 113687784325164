import React, { useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography, TextField, Card, CardContent } from '@material-ui/core';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';
import styled from 'styled-components';

import isValidEmail from '../../core/validators/email';
import { ChangeFormValuesAction, LoginActions, LoginRequestAction } from './login.actions';
import { loginReducer } from './login.reducer';
import { initialLoginState } from './login.state';

import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';

const Login: React.FC = () => {
    const [, appDispatch] = useAppState();
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useReducer(loginReducer, initialLoginState);
    const { push } = useHistory();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const action: ChangeFormValuesAction = {
            type: LoginActions.CHANGE_FORM_VALUES,
            field: e.target.id,
            value: e.target.value,
        };

        dispatch(action);
    };

    const handleSignIn = async () => {
        const { email, password } = state.form;
        setLoading(true);

        dispatch({
            type: LoginActions.LOGIN_REQUEST,
            dispatcher: dispatch,
            appDispatcher: appDispatch,
            email,
            password,
        } as LoginRequestAction);
    };

    useEffect(() => {
        if (state.isLoginAttemptValid) {
            push('/dashboard');
            return;
        }

        if (!state.isLoginAttemptValid && state.loginAttemptMessage.length > 0 && !state.isBusy) {
            // Failed attempt
            setLoading(false);
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: state.loginAttemptMessage,
                status: 'error',
                open: true,
            } as ToastSnackAction);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isBusy, state.isLoginAttemptValid, state.loginAttemptMessage]);

    const preventSubmit = !isValidEmail(state.form.email) || state.form.password.length === 0 || state.isBusy;

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && !preventSubmit) {
            handleSignIn();
        }
    };

    return (
        <Box minWidth={400}>
            <LoginCard>
                <CardContent>
                    <img
                        src="/images/ftg_logo.png"
                        alt="Forteller Games"
                        style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '15px',
                            marginBottom: '0',
                            width: '40%',
                        }}
                    />

                    <Typography
                        align="center"
                        component="div"
                        variant="h5"
                        style={{ marginTop: '-4px', marginBottom: '15px' }}
                    >
                        BACKSTAGE PORTAL
                    </Typography>

                    <TextField
                        id="email"
                        label="email address"
                        autoComplete="off"
                        variant="filled"
                        size="small"
                        fullWidth
                        type="email"
                        onChange={handleChange}
                        value={state.form.email}
                        error={state.form.pristine ? false : !isValidEmail(state.form.email)}
                    />
                    <TextField
                        id="password"
                        label="password"
                        variant="filled"
                        size="small"
                        fullWidth
                        type="password"
                        security="*"
                        onChange={handleChange}
                        value={state.form.password}
                        onKeyPress={handleKeyDown}
                    />

                    <Button
                        onClick={handleSignIn}
                        disabled={preventSubmit}
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '10px', padding: '12px' }}
                    >
                        <ProgressiveButtonText text="Sign In" isLoading={loading} />
                    </Button>
                </CardContent>
            </LoginCard>
        </Box>
    );
};

const LoginCard = styled(Card)`
    .MuiCardContent-root {
        padding: 50px;
    }
`;

export default Login;
