export interface ConfigurationStateDefinition {
    id: string;
    channelId: string;
    gameId: string;
    rewardScale: RewardScale;
}

export enum StateChange {
    SET_INIT_DATA = 'set initial data',
    UPDATE_FIELD = 'update field',
    SET_GAME_ID = 'set game id',
}

export interface RewardScale {
    nonSubscriber: AmountTypes;
    subscriberOne: AmountTypes;
    subscriberTwo: AmountTypes;
    subscriberThree: AmountTypes;
}

export interface AmountTypes {
    monthlyAmount: number;
    watchAmount: number;
}

export interface PostConfigResponse extends ConfigurationStateDefinition {
    isLive: boolean;
}
