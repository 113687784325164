import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, Icon, List, useTheme, Divider, Typography, AppBar, Toolbar } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';

import styled from 'styled-components';
import { useAppState } from 'core/state/app.state';
import { AppActions } from 'core/state/app.actions';
import { MenuSlugs } from 'core/services/FeatureService';
import UserService from 'core/services/UserService';

interface MainMenuProps {}

const MainMenu: React.FC<MainMenuProps> = () => {
    const [state, appDispatch] = useAppState();
    const { features } = state;
    const { palette } = useTheme();

    const { push } = useHistory();
    const { pathname } = useLocation();
    const [, pageName] = pathname.split('/');

    const handleMenuClick = (e: React.SyntheticEvent<HTMLElement>) => {
        const slug = e.currentTarget.id;
        if (slug === MenuSlugs.SignOut) {
            appDispatch({
                type: AppActions.SIGN_OUT,
            });
            push('/');
        } else {
            push(`/${slug}`);
        }
    };

    const appVersion = process.env.REACT_APP_VERSION;

    const logout = async () => {
        await UserService.logout();
        push('/login');
    };

    return (
        <>
            <AppBar position="fixed">
                <StyledToolbar>
                    <Typography variant="h2">Backstage</Typography>
                    <Typography style={{ color: palette.accent.secondary }} variant="h4">
                        v{appVersion}
                    </Typography>
                </StyledToolbar>
            </AppBar>
            <StyledDrawer theme={palette}>
                {features.length > 0 && (
                    <List component="nav">
                        {features.map((menuItem, i: number) => {
                            return (
                                <ListItem
                                    button
                                    divider={false}
                                    id={menuItem.slug}
                                    key={`${i}-${menuItem.slug}`}
                                    onClick={handleMenuClick}
                                    selected={menuItem.slug === pageName}
                                    title={menuItem.label}
                                >
                                    <StyledIcon>
                                        <Icon className="MuiIconButton-edgeEnd">{menuItem.icon}</Icon>
                                    </StyledIcon>
                                </ListItem>
                            );
                        })}
                        <Divider />
                    </List>
                )}

                <List>
                    <ListItem button key="Sign Out" id={MenuSlugs.SignOut} onClick={logout}>
                        <StyledIcon>
                            <ExitToApp />
                        </StyledIcon>
                    </ListItem>
                </List>
            </StyledDrawer>
        </>
    );
};

const StyledToolbar = styled(Toolbar)`
    flex-direction column;
    align-items: flex-start;
    justify-content: center;

    h2, h4 {
        margin: 0;
        line-height: normal;
    }
`;

const StyledDrawer = styled.div`
    display: flex;
    position: fixed;
    padding-top: 100px;
    z-index: 1500;
    flex-direction: column;
    height: 100%;
    background-color: ${style => style.theme.action.active} !important;
    min-width: 65px;
    width: 65px;

    hr {
        background-color: ${style => style.theme.action.active};
    }
`;

const StyledIcon = styled(ListItemIcon)`
    color: white;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 10px 0;

    .MuiIconButton-edgeEnd {
        margin-right: 0;
    }

    .MuiIconButton-root {
        color: white;
    }
`;

export default MainMenu;
