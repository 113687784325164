import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ImageUploader from '../../../../components/shared/ImageUploader';
import { NewImageBlob, UploadImageTypes } from 'core/models/Game';

interface PreviewUrls {
    learnMoreUri: string;
    gameDetailCardUri: string;
    storeCardUri: string;
}

interface UploadedImageUrls {
    uploadedLearnMoreUri: NewImageBlob | null;
    uploadedGameDetailCardUri: NewImageBlob | null;
    uploadedStoreCardUri: NewImageBlob | null;
}

interface GameMediaProps {
    handleImageUpload: (arg0: NewImageBlob, arg1: UploadImageTypes) => void;
    previewUrls: PreviewUrls;
    uploadedImageUrls: UploadedImageUrls;
}

interface Image {
    imageUrl: string;
    name: 'learnMoreUri' | 'gameDetailCardUri' | 'storeCardUri';
    uploadName: UploadImageTypes;
    label: string;
    subLabel: string;
}

const GameMediaForm: FC<GameMediaProps> = ({ handleImageUpload, previewUrls, uploadedImageUrls }) => {
    const imageUploadTypes: Array<Image> = [
        {
            imageUrl: uploadedImageUrls.uploadedLearnMoreUri
                ? uploadedImageUrls.uploadedLearnMoreUri.blob
                : previewUrls.learnMoreUri,
            name: 'learnMoreUri',
            uploadName: 'uploadedLearnMoreUri',
            label: 'Learn More Card',
            subLabel: '375x812 @3',
        },
        {
            imageUrl: uploadedImageUrls.uploadedGameDetailCardUri
                ? uploadedImageUrls.uploadedGameDetailCardUri.blob
                : previewUrls.gameDetailCardUri,
            uploadName: 'uploadedGameDetailCardUri',
            name: 'gameDetailCardUri',
            label: 'Details Banner',
            subLabel: '375x275 @3',
        },
        {
            imageUrl: uploadedImageUrls.uploadedStoreCardUri
                ? uploadedImageUrls.uploadedStoreCardUri.blob
                : previewUrls.storeCardUri,
            uploadName: 'uploadedStoreCardUri',
            name: 'storeCardUri',
            label: 'Game Store Card',
            subLabel: '277x277 @3',
        },
    ];

    return (
        <>
            <Typography variant="body2" style={{ fontWeight: 600, margin: '35px 0 5px 0' }}>
                Game Images
            </Typography>
            <Grid container direction="row" alignItems="flex-start" spacing={3}>
                {imageUploadTypes.map((image: Image, index: number) => (
                    <Grid item xs={4} lg={3} key={`${index}-${image.name}`}>
                        <ImageUploader
                            imageUrl={image.imageUrl}
                            name={image.name}
                            id={image.name}
                            label={image.label}
                            subLabel={image.subLabel}
                            onSuccessfullDrop={(event: NewImageBlob) => handleImageUpload(event, image.uploadName)}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default GameMediaForm;
