import Game from 'core/models/Game';
import { ContainerActionTypes, Containers, ContainerRequestAction } from 'core/models/Containers';
import CatalogService from 'core/services/CatalogService';

export interface ContainerEditorState {
    game: Game | null;
    containers: Array<Containers> | null;
}

export const editorInitialState: ContainerEditorState = {
    game: null,
    containers: null,
};

export const editorReducer = (state: ContainerEditorState, [type, payload]: [ContainerActionTypes, any]) => {
    let newState: ContainerEditorState = state;
    switch (type) {
        case ContainerActionTypes.SET_GAME:
            newState = setGameData(newState, payload as Game);
            break;
        case ContainerActionTypes.GET_CONTAINERS:
            getContainers(payload as ContainerRequestAction);
            break;
        case ContainerActionTypes.SET_CONTAINERS:
            newState = setContainerData(newState, payload as Array<Containers>);
            break;
        default: {
            console.log("You didn't dispatch a message --> editor.reducer");
            return state;
        }
    }
    return newState;
};

const setGameData = (currentState: ContainerEditorState, payload: Game) => {
    return {
        ...currentState,
        game: payload,
    };
};

const getContainers = async ({ dispatch, gameId }: ContainerRequestAction): Promise<void> => {
    CatalogService.getContainersByGameId(gameId)
        .then(result => {
            dispatch([ContainerActionTypes.SET_CONTAINERS, result]);
        })
        .catch(err => {
            if (err.status === 404) {
                dispatch([ContainerActionTypes.SET_CONTAINERS, []]);
            } else {
                console.error('There was an error fetching containers', err);
            }
        });
};

const setContainerData = (currentState: ContainerEditorState, payload: Array<Containers>) => {
    return {
        ...currentState,
        containers: payload,
    };
};
