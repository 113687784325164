import { FC, useEffect, useState } from 'react';

import { ai } from 'core/services/TelemetryService';

interface TelemetryProviderProps {
    children: any;
}

const TelemetryProvider: FC<TelemetryProviderProps> = ({ children }) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!Boolean(initialized)) {
            ai.initialize(
                process.env.REACT_APP_APPNAME as string,
                process.env.REACT_APP_VERSION as string,
                process.env.REACT_APP_INSIGHT_KEY as string
            );

            setInitialized(true);
        }
    }, [initialized]);

    return children;
};

export default TelemetryProvider;
