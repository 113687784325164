import React, { useReducer, createContext, useContext } from 'react';
import { scryReducer } from './scry.reducer';
import { Community } from 'domain/scry/models/Community';
import { Game, EngagementSummary, RecentEngagement } from 'domain/scry/models/Game';
export interface ScryState {
    ready: boolean;
    totalUsers: number | string;
    totalTimeSpent: number | string;
    totalActiveItems: number | string;
    communities: Array<Community> | null;
    engagementSummary: EngagementSummary | null;
    recentEngagements: Array<RecentEngagement>;
    game: Game;
}

export const initialScryState: ScryState = {
    ready: false,
    totalTimeSpent: '3,444',
    totalUsers: '6,478',
    totalActiveItems: '14',
    engagementSummary: null,
    recentEngagements: [],
    communities: [],
    game: {
        configuration: null,
        status: 'stale',
        id: '',
        name: '',
        cardUri: '',
        published: true,
        publisher: {
            id: '',
            name: '',
        },
        order: 0,
        aboutUri: '',
        interactions: {
            status: 'stale',
            data: [],
        },
    },
};

const ScryStateContext = createContext(initialScryState);

const ScryStateProvider = ({ children }: any) => {
    const [scryState, scryDispatch] = useReducer(scryReducer, initialScryState);
    return <ScryStateContext.Provider value={[scryState, scryDispatch] as any}>{children}</ScryStateContext.Provider>;
};

const useScryState = () => {
    const context = useContext(ScryStateContext);

    if (context === undefined) {
        throw new Error('useScryState must be within the ScryStateProvider');
    }

    return context;
};

export { ScryStateProvider, useScryState };
