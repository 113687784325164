export const convertToTime = (duration: string) => {
    const sec_num = parseInt(duration, 10);
    let hours: number | string = Math.floor(sec_num / 3600);
    let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60);
    let seconds: number | string = sec_num - hours * 3600 - minutes * 60;

    if (hours === 0o0 || hours === 0) {
        hours = '';
    }
    if (hours !== '' && hours < 0) {
        hours = `0${hours}:`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}:`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }

    return `${hours}${minutes}${seconds}`;
};

export const sortByKey = (list: Array<any>, key: string, inverted?: boolean) => {
    return list.sort((a, b) => {
        let x = a[key];
        let y = b[key];

        if (inverted) {
            return x > y ? -1 : x < y ? 1 : 0;
        }
        return x < y ? -1 : x > y ? 1 : 0;
    });
};

export const copyToClipboard = (text: string) => {
    let copied = false;
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        copied = true;
    }
    return copied;
};
