import React, { FC } from 'react';
import { Typography, Button, Grid, useTheme } from '@material-ui/core';
import styled from 'styled-components';

interface ConfirmRemoveProps {
    listIsArray: boolean;
    trackId: string;
    setDisplayDeleteConfirm: (arg0: boolean) => void;
    removeTrack: (id: string) => void;
    forceDelete?: boolean;
}

const ConfirmRemove: FC<ConfirmRemoveProps> = ({
    setDisplayDeleteConfirm,
    listIsArray,
    removeTrack,
    trackId,
    forceDelete,
}) => {
    const { palette } = useTheme();

    return (
        <ConfirmContainer theme={palette}>
            <Grid item xs={12}>
                <Typography>
                    {listIsArray || forceDelete
                        ? 'Are you sure you want to delete this track? It will delete any child nodes attached to it.'
                        : 'You cannot remove anymore tracks. The minimum amount of tracks is 1.'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Button
                        variant="text"
                        style={{ textDecoration: 'none' }}
                        onClick={() => setDisplayDeleteConfirm(false)}
                    >
                        {!listIsArray || forceDelete ? 'CLOSE' : 'NO'}
                    </Button>
                    {(listIsArray || forceDelete) && (
                        <Button variant="text" style={{ textDecoration: 'none' }} onClick={() => removeTrack(trackId)}>
                            YES
                        </Button>
                    )}
                </Grid>
            </Grid>
        </ConfirmContainer>
    );
};

const ConfirmContainer = styled(Grid)`
    background-color: ${style => style.theme.error.main};
    color: ${style => style.theme.text.main};
    padding: 15px 15px 0 15px;
    border-radius: 4px;
    margin-top: 10px;

    p {
        margin: 0;
    }
`;

export default ConfirmRemove;
