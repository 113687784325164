import CatalogService from 'core/services/CatalogService';
import {
    FetchGamesDoneAction,
    FetchGamesRequestAction,
    NarrationActions,
    FetchAssetRequest,
    FetchAssetRequestDone,
} from 'core/state/narration/narration.actions';
import { NarrationState, GameAsset } from 'core/state/narration/narration.state';
import { sortByKey } from '../../../domain/narration/utils';

export const reducer = (state: NarrationState, action: any): any => {
    let newState: NarrationState = state;

    switch (action.type) {
        case NarrationActions.FETCH_GAMES_REQUEST:
            getNarrationGames(action as FetchGamesRequestAction);
            break;
        case NarrationActions.FETCH_GAMES_DONE:
            newState = updateGames(state, action as FetchGamesDoneAction);
            break;
        case NarrationActions.FETCH_MEDIA_ASSETS:
            getAssetsByGameId(action as FetchAssetRequest);
            break;
        case NarrationActions.SET_MEDIA_ASSETS:
            newState = updateAssets(state, action.assets as GameAsset) as any;
            break;
    }

    return newState;
};

function getNarrationGames({ dispatcher }: FetchGamesRequestAction): void {
    CatalogService.getGames()
        .then(games => {
            const action: FetchGamesDoneAction = {
                games,
                type: NarrationActions.FETCH_GAMES_DONE,
            };

            dispatcher(action);
        })
        .catch(err => {
            console.error('Failed to fetch narration games', err);
        });
}

function updateGames(currentState: NarrationState, action: FetchGamesDoneAction): NarrationState {
    return {
        ...currentState,
        games: action.games,
        hasError: false,
    };
}

function getAssetsByGameId({ dispatcher, gameId }: FetchAssetRequest): void {
    CatalogService.getMediaAssets(gameId)
        .then(response => {
            const action: FetchAssetRequestDone = {
                type: NarrationActions.SET_MEDIA_ASSETS,
                assets: {
                    id: gameId,
                    data: sortByKey(response, 'name'),
                },
            };
            dispatcher(action);
        })
        .catch(err => {
            console.log('failed to fetch media assets', err);
        });
}

function updateAssets(currentState: NarrationState, gameAssets: GameAsset) {
    const game = currentState.media?.find((a: GameAsset) => a.id === gameAssets.id);
    if (game) {
        return {
            ...currentState,
            media: currentState.media?.reduce((acc: Array<GameAsset>, curr: GameAsset) => {
                if (curr.id === game.id) {
                    return [
                        ...acc,
                        {
                            ...curr,
                            data: gameAssets.data,
                        },
                    ];
                }

                return acc;
            }, []),
        };
    }
    return {
        ...currentState,
        media: currentState.media ? [...currentState.media, gameAssets] : [gameAssets],
    };
}
