import { Action, AppActionTypes } from 'core/state/app.actions';

export enum LoginActions {
    CHANGE_FORM_VALUES = 'Change Form Values',
    MARK_DIRTY = 'Mark Dirty',
    CHANGE_CAN_SUBMIT = 'Change CanSubmit',
    CHANGE_IS_BUSY = 'Change IsBusy',
    LOGIN_REQUEST = 'Login Request',
    LOGIN_RESPONSE = 'Login Response',
}

export type LoginActionTypes =
    | ChangeFormValuesAction
    | MarkDirtyAction
    | ChangeCanSubmitAction
    | ChangeIsBusyAction
    | LoginRequestAction;

export interface ChangeFormValuesAction extends Action {
    field: string;
    value: string;
}

export interface MarkDirtyAction extends Action {}

export interface ChangeCanSubmitAction extends Action {
    canSubmit: boolean;
}

export interface ChangeIsBusyAction extends Action {
    isBusy: boolean;
}

export interface LoginRequestAction extends Action {
    email: string;
    password: string;
    dispatcher: React.Dispatch<LoginActionTypes>;
    appDispatcher: React.Dispatch<AppActionTypes>;
}

export interface LoginResponseAction extends Action {
    message: string;
    success: boolean;
}
