import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookie from 'js-cookie';

interface PrivateRouteProps extends RouteProps {
    component: any;
}

const PrivateRoute: React.SFC<PrivateRouteProps> = props => {
    const isLoggedIn = Cookie.get('backstage_session');
    const { component: Component } = props;

    return <Route render={comp => (isLoggedIn ? <Component {...comp} /> : <Redirect to="/login" />)} />;
};

export default PrivateRoute;
