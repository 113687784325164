/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useReducer, useRef } from 'react';
import Guid from 'core/models/Guid';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, Button, useTheme, Grid, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';

import { useNarrationState } from 'core/state/narration/narration.state';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';
import Game, { GameId, UploadContainerImageTypes, NewImageBlob } from 'core/models/Game';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import {
    ContainerActionTypes,
    Containers,
    ManageContainerState,
    AddContainerImageRequest,
    UpdateContainerRequest,
    AddContainerRequest,
    AddContainerResponse,
} from 'core/models/Containers';
import { MenuSlugs } from 'core/services/FeatureService';
import { NarrationSubMenu } from '../components/manifest-editor/menus/SubMenu';
import SubNavigation from '../../../components/main-menu/SubNavigation';

import PageContainer from 'components/shared/PageContainer';
import PageBreadcrumbs from 'components/shared/BreadCrumbs';
import ContainerForm from './ContainerForm';
import ContainerMediaUpload from './ContainerMediaUpload';

import { useContainerState } from 'core/state/narration/container.state';
import { useAppState } from 'core/state/app.state';
import CatalogService, { isValid, formValidationRules } from 'core/services/CatalogService';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';

interface ParamsProps extends GameId {
    containerId: string;
}

const initialState: ManageContainerState = {
    id: '',
    gameId: '',
    published: false,
    isFree: false,
    name: '',
    shortKey: '',
    backgroundUri: '',
    foregroundUri: '',
    uploadedBackgroundUri: null,
    uploadedForegroundUri: null,
    loading: false,
};

const reducer = (state: ManageContainerState, [type, payload]: [string, any]) => {
    switch (type) {
        case ContainerActionTypes.SET_STATE:
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case ContainerActionTypes.SET_CONTAINER_DATA:
            return {
                ...state,
                ...payload,
            };
        case ContainerActionTypes.SET_LOADING:
            return {
                ...state,
                loading: payload,
            };
        case ContainerActionTypes.RESET_UPLOAD_IMAGES:
            return {
                ...state,
                uploadedBackgroundUri: null,
                uploadedForegroundUri: null,
            };
        default: {
            console.log("You didn't dispatch a message --> CreateContainer.tsx");
            return state;
        }
    }
};

const CreateContainer: FC = () => {
    const { gameId, containerId }: ParamsProps = useParams();
    const { push } = useHistory();
    const { palette } = useTheme();
    const [{ games, hasError }, narrationDispatch] = useNarrationState();
    const [
        {
            auth: { currentUser },
        },
        appDispatch,
    ] = useAppState();

    const containerTitleRef = useRef('');
    const [state, dispatch] = useReducer(reducer, initialState);

    const { editorDispatch, containers, game: currentGame }: any = useContainerState();

    const {
        id,
        name,
        published,
        isFree,
        shortKey,
        backgroundUri,
        foregroundUri,
        uploadedBackgroundUri,
        uploadedForegroundUri,
        loading,
    } = state;

    useEffect(() => {
        if (gameId && games.length > 0) {
            const currentGame = games.find((g: Game) => g.id === gameId) as Game;
            editorDispatch([ContainerActionTypes.SET_GAME, currentGame]);
        }
    }, [games, gameId]);

    useEffect(() => {
        let data = containers ? containers.find((c: Containers) => c.id === containerId) : undefined;
        dispatch([ContainerActionTypes.SET_CONTAINER_DATA, data]);
        if (data) {
            containerTitleRef.current = data.name;
        }
    }, [containerId, containers]);

    useEffect(() => {
        if (games.length === 0 && !hasError) {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                dispatcher: narrationDispatch,
            } as FetchGamesRequestAction);
        }
    }, [games]);

    useEffect(() => {
        if (currentGame && containerId && !containers && currentUser) {
            editorDispatch([ContainerActionTypes.GET_CONTAINERS, { gameId, dispatch: editorDispatch }]);
        }
    }, [currentGame, containerId, containers, currentUser]);

    useEffect(() => {
        if (!containerId) {
            containerTitleRef.current = 'Create New Container';
        }
    }, [containerId, id]);

    const breadcrumbList = [
        { label: 'Narration', to: `/${MenuSlugs.Narration}` },
        { label: currentGame?.name || '', to: `/${MenuSlugs.Narration}/${gameId}` },
        { label: 'Editor', to: `/${MenuSlugs.Narration}/${gameId}/containers` },
        { label: containerTitleRef.current },
    ];

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        dispatch([
            ContainerActionTypes.SET_STATE,
            {
                key: name,
                value,
            },
        ]);
    };

    const handleToggle = async (key: string) => {
        if (!currentGame || !containers) {
            return null;
        }
        try {
            dispatch([
                ContainerActionTypes.SET_STATE,
                {
                    key,
                    value: !state[key],
                },
            ]);
        } catch (err) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                open: true,
                status: 'error',
                message: `There was an error ${published ? 'enabling' : 'disabling'} this container. Please try again.`,
            } as ToastSnackAction);
        }
    };

    const handleImageUpload = (files: NewImageBlob, imageName: UploadContainerImageTypes) => {
        dispatch([ContainerActionTypes.SET_STATE, { key: imageName, value: files }]);
    };

    const previewUrls = () => {
        return {
            foregroundUri: uploadedForegroundUri ? uploadedForegroundUri.blob : foregroundUri,
            backgroundUri: uploadedBackgroundUri ? uploadedBackgroundUri.blob : backgroundUri,
        };
    };

    const uploadImage = async (image: AddContainerImageRequest, updatedContainerId: Guid) => {
        try {
            await CatalogService.updateContainerImage(gameId, updatedContainerId, image);
        } catch (err) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: `There was an error uploading the ${image.imageType} image`,
                status: 'success',
                open: true,
            } as ToastSnackAction);
        }
    };

    const onSubmit = async () => {
        let fetched: boolean = false;
        let responseMessage: string = `${name} has been updated successfully.`;
        let responseType: 'success' | 'error' = 'success';
        let containerRequest: AddContainerResponse;

        try {
            dispatch([ContainerActionTypes.SET_LOADING, true]);
            let postData: UpdateContainerRequest | AddContainerRequest = { shortKey, name, isFree };
            let newContainer = false;

            if (id !== '') {
                postData = { ...postData, id, published, foregroundUri, backgroundUri };
                containerRequest = await CatalogService.updateContainer(
                    gameId,
                    containerId,
                    postData as UpdateContainerRequest
                );
                fetched = true;
            } else {
                newContainer = true;
                fetched = true;
                containerRequest = await CatalogService.addContainer(gameId, postData as AddContainerRequest);
            }

            if (containerRequest.id) {
                fetched = true;
            }

            if (uploadedBackgroundUri) {
                await uploadImage(
                    { image: uploadedBackgroundUri.file[0], imageType: 'background' },
                    containerRequest.id
                );
                fetched = true;
            }

            if (uploadedForegroundUri) {
                await uploadImage(
                    { image: uploadedForegroundUri.file[0], imageType: 'foreground' },
                    containerRequest.id
                );
                fetched = true;
            }

            if (fetched) {
                setTimeout(async () => {
                    // give it a sec
                    await new Promise(resolve => {
                        editorDispatch([ContainerActionTypes.GET_CONTAINERS, { gameId, dispatch: editorDispatch }]);
                        // clear out uploaded images
                        dispatch([ContainerActionTypes.RESET_UPLOAD_IMAGES, null]);
                        resolve({});
                    });
                }, 500);

                if (newContainer) {
                    setTimeout(() => push(`/games/narration/${gameId}/containers/${containerRequest.id}`), 500);
                }

                appDispatch({
                    type: AppActions.TOAST_SNACK,
                    message: responseMessage,
                    status: responseType,
                    open: true,
                } as ToastSnackAction);
            }
        } catch (err) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'There was an error when updating your container',
                status: 'error',
                open: true,
            } as ToastSnackAction);
        } finally {
            dispatch([ContainerActionTypes.SET_LOADING, false]);
        }
    };

    const isSubmitDisabled = () => {
        /**
         * Validate Images:
         * If the image keys (storeCardUri, gameDetailCardUri, learnMoreUri) are blank strings,
         * the corresponding uploadedImage object cannot be NULL
         */
        const imagesToValidate = [
            { imageName: 'backgroundUri', newImage: uploadedBackgroundUri },
            { imageName: 'foregroundUri', newImage: uploadedForegroundUri },
        ]
            .reduce(
                (acc: Array<boolean>, curr: { imageName: string; newImage: null | NewImageBlob }) => [
                    ...acc,
                    state[curr.imageName] === '' && !curr.newImage ? false : true,
                ],
                []
            )
            .every(image => image);

        const validateFormFields = [
            { value: name, key: 'name' },
            { value: shortKey, key: 'shortKey' },
        ]
            .reduce(
                (acc: Array<boolean>, curr: { key: string; value: string }) => [
                    ...acc,
                    isValid(formValidationRules[curr.key], curr.value),
                ],
                []
            )
            .every(field => field);

        /**
         * Either imagesToValidate or validateFormFields returning false means that there is a field
         * that hasn't validated. If either of them aren't valid, return TRUE -- meaning the form IS disabled
         */
        const imagesAlsoValidate = id === '' ? imagesToValidate === false : false;
        return validateFormFields === false || imagesAlsoValidate;
    };

    return (
        <>
            <SubNavigation title="Game Info" navLinks={NarrationSubMenu(gameId, 'containers')} />
            <PageContainer>
                <PageBreadcrumbs links={breadcrumbList} />
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h1">{containerTitleRef.current}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={0} justifyContent="space-around" direction="row">
                            <Grid item>
                                {containerId && (
                                    <StyledSwitch
                                        theme={palette}
                                        checked={isFree}
                                        control={
                                            <Switch
                                                onChange={() => handleToggle('isFree')}
                                                id="isFree"
                                                name="isFree"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="start"
                                        enabled={isFree ? 'enabled' : 'disabled'}
                                        label="Free Product"
                                        style={{
                                            marginRight: 5,
                                            color: isFree ? palette.text.main : palette.text.disabled,
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {containerId && (
                                    <StyledSwitch
                                        theme={palette}
                                        checked={published}
                                        control={
                                            <Switch
                                                onChange={() => handleToggle('published')}
                                                name="published"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="start"
                                        enabled={published ? 'enabled' : 'disabled'}
                                        label={published ? 'Enabled' : 'Disabled'}
                                        style={{
                                            marginRight: 25,
                                            paddingRight: 15,
                                            color: published ? palette.text.main : palette.text.disabled,
                                            borderRight: `1px solid ${palette.borders.paperColor}`,
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={onSubmit}
                                    disabled={isSubmitDisabled()}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    <ProgressiveButtonText
                                        text={containerId ? 'Save Changes' : 'Create Container'}
                                        isLoading={loading}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {(currentGame && containerTitleRef.current !== '') || !containerId ? (
                    <>
                        <ContainerForm shortKey={shortKey} name={name} handleChange={handleChange} />
                        <ContainerMediaUpload handleImageUpload={handleImageUpload} previewUrls={previewUrls()} />
                    </>
                ) : (
                    <CircularProgress color="secondary" size="3rem" style={{ marginTop: 10 }} />
                )}
            </PageContainer>
        </>
    );
};

const StyledSwitch = styled(FormControlLabel)<{ enabled: 'enabled' | 'disabled' }>`
    .MuiTypography-body1 {
        color: ${style => (style.enabled === 'enabled' ? style.theme.text.main : style.theme.text.secondary)};
    }
`;

export default CreateContainer;
