/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Track } from 'core/models/Game';
import TrackFormItem from './TrackFormItem';
import update from 'immutability-helper';
import { Grid, Button, useTheme, Typography } from '@material-ui/core';
import { AddCircleRounded } from '@material-ui/icons';
import DeleteConfirm from './DeleteConfirm';
import styled from 'styled-components';
import { DrawerType } from 'domain/narration/TrackEditorWrapper';
import { GameAsset, useNarrationState } from 'core/state/narration/narration.state';

interface TrackFormContainerProps {
    tracks: Array<Track>;
    drawerType: DrawerType;
    closeDrawer: () => void;
    submitForm: (trackData: Array<Track> | Track) => void;
    deleteEntireDecisionNode: () => void;
    submitEditSingleNode: (trackId: string, updatedTrackData: Track) => void;
    submitDecisionBlockChange: (updatedTrackData: any) => void;
    parentTransitionPrompt: string;
    selectedTrackId: string;
    currentTrackLength: number;
}

const TrackFormContainer: FC<TrackFormContainerProps> = ({
    tracks,
    drawerType,
    closeDrawer,
    deleteEntireDecisionNode,
    submitForm,
    submitEditSingleNode,
    submitDecisionBlockChange,
    parentTransitionPrompt,
    selectedTrackId,
    currentTrackLength,
}) => {
    const { palette } = useTheme();
    const { gameId }: { gameId: string } = useParams();
    const [trackList, setTrackList]: any = useState([]);
    const [displayDeleteConfirm, setDisplayDeleteConfirm] = useState(false);
    const [{ media }] = useNarrationState();

    const generateLocalTrackId = () => {
        const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
        return id;
    };

    const blankTrackObject = {
        id: generateLocalTrackId(),
        title: '',
        asset: null,
        transition: {
            type: 'linear',
            requirementDescription: '',
            transitionPrompt: '',
            forceEnd: false,
            endType: null,
            childNodes: [],
        },
    } as Track;

    useEffect(() => {
        setTrackList(tracks.length === 0 ? [blankTrackObject] : tracks);
    }, [tracks]);

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = trackList[dragIndex];
            setTrackList(
                update(trackList, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [trackList]
    );

    const removeTrack = (removeId: string) => {
        if (trackList.length > 1) {
            const newTrackList: Array<Track> = trackList.filter((t: Track) => t.id !== removeId);
            setTrackList(newTrackList);
        }
    };

    const updateTrackData = (id: string, key: string, value: any) => {
        const updatedTracks: Array<Track> = trackList;
        setTrackList(
            updatedTracks.reduce((acc: Array<Track>, curr: Track) => {
                const addedObject = () => {
                    switch (key) {
                        case 'requirementDescription':
                        case 'transitionPrompt':
                        case 'endType':
                            return {
                                transition: {
                                    ...curr.transition,
                                    [key]: value,
                                },
                            };
                        case 'forceEnd':
                            return {
                                transition: {
                                    ...curr.transition,
                                    forceEnd: value[0],
                                    endType: value[0] === false ? null : value[1],
                                },
                            };
                        default:
                            return { [key]: value };
                    }
                };

                return [
                    ...acc,
                    {
                        ...curr,
                        ...(curr.id === id && addedObject()),
                    },
                ];
            }, [])
        );
    };

    const addNewTrackItem = () => {
        const newTrackList: Array<Track> = [...trackList, blankTrackObject as Track];
        setTrackList(newTrackList);
    };

    const handleSubmit = () => {
        const data = trackList.length > 1 ? trackList : trackList[0];
        if (drawerType === 'edit') {
            submitEditSingleNode(data.id, data);
            return;
        }
        if (drawerType === 'editBlock') {
            submitDecisionBlockChange(trackList);
            return;
        }
        submitForm(data);
    };

    const handleDelete = () => {
        if (drawerType === 'editBlock') {
            submitDecisionBlockChange([]);
        } else {
            deleteEntireDecisionNode();
        }
    };

    const formTitle = () => {
        if (drawerType === 'editBlock') {
            return 'Edit Decision Node';
        }

        if (drawerType === 'edit') {
            return 'Edit Node';
        }

        return 'Add New Node';
    };

    const isFormDisabled = () => {
        if (trackList.length === 0) {
            return false;
        }
        const validFields = trackList.reduce((acc: Array<boolean>, curr: Track) => {
            const standardDataValid = curr.asset !== null && curr.title !== '';
            const ctaDataValid = () => {
                if (curr.transition.forceEnd && !curr.transition.endType) {
                    return false;
                }
                return true;
            };
            return [...acc, standardDataValid === true && ctaDataValid() === true];
        }, []);
        return !validFields.every((field: boolean) => field === true);
    };

    const mediaByGameId = () => {
        if (!media) {
            return [];
        } else {
            const response = media.find((m: GameAsset) => m.id === gameId) as GameAsset;
            return response?.data || [];
        }
    };

    return (
        <>
            <DrawerHeader theme={palette} container direction="row" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h2">{formTitle()}</Typography>
                </Grid>
                <Grid item>
                    <Button color="default" size="large" onClick={closeDrawer}>
                        Cancel
                    </Button>
                    <Button
                        disabled={isFormDisabled()}
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        size="large"
                    >
                        Save
                    </Button>
                </Grid>
            </DrawerHeader>
            <div style={{ marginTop: 20 }}>
                {trackList.length >= 2 && parentTransitionPrompt !== '' && (
                    <Typography variant="h1" style={{ padding: '0 40px' }}>
                        {parentTransitionPrompt}
                    </Typography>
                )}
                {trackList.map((track: Track, index: number) => (
                    <TrackFormItem
                        onChange={updateTrackData}
                        displayMoveIcon={trackList.length > 1}
                        removeTrack={removeTrack}
                        key={track.id}
                        track={track}
                        index={index}
                        moveCard={moveCard}
                        media={mediaByGameId()}
                    />
                ))}
                <Grid item xs={12} style={{ marginTop: 20, padding: '0 40px' }}>
                    <Grid container direction="row" justifyContent={drawerType === 'edit' ? 'center' : 'space-between'}>
                        {drawerType !== 'edit' && currentTrackLength > 0 && (
                            <Grid item>
                                <AddNodeButton onClick={addNewTrackItem} theme={palette} variant="text">
                                    <AddCircleRounded />
                                </AddNodeButton>
                            </Grid>
                        )}
                        {(drawerType === 'edit' || drawerType === 'editBlock') && (
                            <Grid item>
                                <Button onClick={() => setDisplayDeleteConfirm(true)} color="secondary" variant="text">
                                    Delete Entire Node
                                </Button>
                            </Grid>
                        )}
                        {displayDeleteConfirm && (
                            <DeleteConfirm
                                forceDelete
                                trackId={selectedTrackId}
                                listIsArray={trackList.length > 1}
                                setDisplayDeleteConfirm={setDisplayDeleteConfirm}
                                removeTrack={handleDelete}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const DrawerHeader = styled(Grid)`
    padding: 10px 40px;
    border-bottom: ${style => `1px solid ${style.theme.borders.secondaryPaperColor}`};
`;

const AddNodeButton = styled(Button)`
    margin: 0;
    padding: 0;
    min-width: 1px;
    color: ${style => style.theme.action.active};

    svg {
        font-size: 3rem;
    }
`;

export default TrackFormContainer;
