import React, { FC, useState } from 'react';
import { Grid, Button, Typography, useTheme, CircularProgress } from '@material-ui/core';
import { PriorityHigh, HourglassEmpty } from '@material-ui/icons';
import { Job } from 'core/models/Asset';
import styled from 'styled-components';
import CatalogService from 'core/services/CatalogService';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';

interface JobItemProps {
    data: Job;
    isLastItem: boolean;
    fetchActiveJobs: () => void;
}

const JobItem: FC<JobItemProps> = ({ data, isLastItem, fetchActiveJobs }) => {
    const { palette } = useTheme();
    const { state, originalFilename, id } = data;
    const [, appDispatch] = useAppState();

    const [loading, setLoading] = useState(false);
    const [actiionButtonText, setButtonText] = useState('Remove');

    const handleDelete = async (jobId: string) => {
        if (loading) {
            return;
        }
        setButtonText('Removing...');
        setLoading(true);
        try {
            await CatalogService.deleteMediaJob(jobId);
            fetchActiveJobs();
        } catch (err) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'There was an error when trying to delete this job. Please try again',
                status: 'error',
                open: true,
            } as ToastSnackAction);
        } finally {
            setButtonText('Remove');
            setLoading(false);
        }
    };

    const jobState = () => {
        switch (state) {
            case 'PendingUpload':
                return 'Pending Upload';
            case 'FailedProcessing':
                return 'Job Failed';
            default:
                return 'Job Processing';
        }
    };

    return (
        <Grid
            key="file-pending"
            container
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ marginBottom: isLastItem ? 0 : 15 }}
        >
            <Grid item xs={2}>
                <AssetType theme={palette} state={state}>
                    {state === 'FailedProcessing' ? <PriorityHigh /> : <HourglassEmpty />}
                </AssetType>
            </Grid>
            <Grid item xs={10}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography style={{ margin: '0' }}>{originalFilename}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={7}>
                                <Typography
                                    variant="body2"
                                    style={{
                                        margin: 0,
                                        color: state === 'FailedProcessing' ? palette.error.main : palette.warning.main,
                                    }}
                                >
                                    {jobState()}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} style={{ textAlign: 'right' }}>
                                <Button
                                    onClick={() => handleDelete(id)}
                                    style={{
                                        fontSize: '0.725rem',
                                        margin: 0,
                                        padding: 0,
                                        position: 'relative',
                                        top: -3,
                                        lineHeight: 'normal',
                                        textDecoration: loading ? 'none' : 'inherit',
                                    }}
                                >
                                    <>
                                        {actiionButtonText}
                                        {loading && (
                                            <CircularProgress style={{ marginLeft: 5 }} color="secondary" size="1rem" />
                                        )}
                                    </>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const AssetType = styled.div<{ state: 'PendingUpload' | 'FailedProcessing' | 'Processing' }>`
    padding: 10px;
    display: flex;
    width: fit-content;
    border-radius: 8px;
    background-color: ${style =>
        style.state === 'FailedProcessing' ? style.theme.error.main : style.theme.background.secondaryPaper};
    color: ${style => style.theme.text.main};
`;

export default JobItem;
