import { auth } from './FirebaseService';

class UserServiceImpl {
    currentUser: firebase.User | null = null;

    async login(email: string, password: string): Promise<firebase.auth.UserCredential | null> {
        return await tryWrap<firebase.auth.UserCredential>(async () => {
            const cred = await auth.signInWithEmailAndPassword(email, password);
            this.currentUser = cred.user;
        }, 'Error signing in');
    }

    async logout(): Promise<void> {
        await tryWrap<void>(() => auth.signOut(), 'Error signing out');
    }

    async getToken(): Promise<firebase.auth.IdTokenResult | null> {
        return await tryWrap<firebase.auth.IdTokenResult>(async () => {
            const currentUser = auth.currentUser;

            if (currentUser === null) {
                console.warn('Current user is null', currentUser);
                return null;
            }

            return await currentUser.getIdTokenResult();
        }, 'Error getting id token');
    }

    async hasClaim(claim: string): Promise<boolean> {
        const result = await tryWrap<boolean>(async () => {
            const token = await this.getToken();

            if (!token) {
                console.warn('Token is null');
                return false;
            }

            const claimKeys = Object.keys(token.claims);
            if (!claimKeys.includes(claim)) {
                return false;
            }

            return true;
        }, 'Error checking claim');

        return result!;
    }

    async checkClaimValue(claim: string): Promise<string> {
        const result = await tryWrap<string>(async () => {
            const token = await this.getToken();

            if (!token) {
                console.warn('Token is null');
                return false;
            }

            const claimKeys = Object.entries(token.claims).reduce((acc: any, curr: any) => {
                const [label, value] = curr;
                return label === claim ? value : acc;
            }, 'none');

            return claimKeys;
        }, 'Error checking claim');

        return result!;
    }

    async hasAnyValueForClaim(claim: string, values: string[]): Promise<boolean> {
        const result = await tryWrap<boolean>(async () => {
            const token = await this.getToken();

            if (!token) {
                console.warn('Token is null');
                return false;
            }

            const claimKeys = Object.keys(token.claims);
            if (!claimKeys.includes(claim)) {
                return false;
            }

            const claimValue = (token.claims[claim] as string).split('|');
            return claimValue.some(c => values.includes(c));
        }, 'Error checking claim');

        return result!;
    }
}

async function tryWrap<T>(fn: Function, errorMessage: string): Promise<T | null> {
    try {
        return await fn();
    } catch (error) {
        console.error(errorMessage, error);
        return null;
    }
}

const UserService = new UserServiceImpl();
export default UserService;
