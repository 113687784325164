import React, { FC } from 'react';
import { useScryState } from 'core/state/scry/scry.state';

import styled from 'styled-components';
import { useTheme, Typography } from '@material-ui/core';
import { RecentEngagement } from 'domain/scry/models/Game';

const LiveInteractionList: FC<any> = () => {
    const { palette } = useTheme();
    const [{ recentEngagements }]: any = useScryState();

    const formatDate = (date: any) => {
        const options: object = {
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        const newDate = new Date(date);
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(newDate);

        return formattedDate.replace(',', ' @');
    };

    const NoItems = () => (
        <ErrorContainer theme={palette}>
            <Typography>No live history</Typography>
        </ErrorContainer>
    );

    return (
        <LiveInteractionContainer hasItems={recentEngagements.length > 0} theme={palette}>
            {recentEngagements.length === 0 ? (
                <NoItems />
            ) : (
                recentEngagements.map(
                    (
                        {
                            contributionId,
                            amountSpent,
                            interactionName,
                            purchasedAt,
                            userDisplayName,
                        }: RecentEngagement,
                        index: number
                    ) => (
                        <ListItem theme={palette} key={`${index}-${contributionId}`}>
                            <Typography>
                                <b>{userDisplayName}</b> purchased <b>{interactionName}</b> for{' '}
                                <b>{amountSpent} time</b> {formatDate(purchasedAt)}
                            </Typography>
                        </ListItem>
                    )
                )
            )}
        </LiveInteractionContainer>
    );
};

const LiveInteractionContainer = styled.div<{ hasItems: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    height: ${style => (style.hasItems ? '305px' : 'auto')};
    padding: 0;

    @media only screen and (max-width: 1024px) {
        max-height: 352px;
    }
    @media only screen and (min-width: 1440px) {
        max-height: 530px;
    }
    @media only screen and (max-width: 1440px) {
        height: auto;
    }
`;

const ErrorContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 10px 10px 0 10px;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-radius: 3px;

    p {
        margin: 0;
        background-color: ${style => style.theme.background.secondaryPaper};
        padding: 20px;
        width: 100%;
        color ${style => style.theme.text.main};
    }
`;

const ListItem = styled.div`
    display: flex;
    background-color: ${style => style.theme.background.secondaryPaper};
    padding: 10px;
    margin-bottom: 10px;
    align-items: center;

    p {
        margin: 0;
        font-size: 0.825rem;
        color: ${style => style.theme.text.light};
    }

    b {
        color: ${style => style.theme.text.main};
        font-weight: 400;
    }
`;

export default LiveInteractionList;
