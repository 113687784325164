import * as React from 'react';
import Login from '../login/Login';

interface LoginContainerProps {}

const LoginContainer: React.FC<LoginContainerProps> = () => {
    return <Login />;
};

export default LoginContainer;
