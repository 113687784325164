import UserService from './UserService';

export type MenuItem = {
    order: number;
    slug: string;
    icon: 'account_box' | 'exit_to_app' | 'person' | 'games' | 'volume_up' | 'redeem';
    label: string;
    requiredClaim: string[];
};

export enum MenuSlugs {
    Dashboard = 'dashboard',
    Publishers = 'publishers',
    Organization = 'org',
    Narration = 'games/narration',
    Scry = 'games/scry',
    SignOut = 'signout',
    Redeemer = 'redeemer',
    Users = 'users',
}

const menu: MenuItem[] = [
    {
        order: 1,
        icon: 'account_box',
        slug: MenuSlugs.Publishers,
        label: 'Publisher Management',
        requiredClaim: ['admin'],
    },
    {
        order: 2,
        icon: 'person',
        slug: MenuSlugs.Users,
        label: 'Users',
        requiredClaim: ['admin'],
    },
    {
        order: 3,
        icon: 'volume_up',
        slug: MenuSlugs.Narration,
        label: 'Narration Games',
        requiredClaim: ['admin'],
    },
    {
        order: 4,
        icon: 'games',
        slug: MenuSlugs.Scry,
        label: 'Scry Games',
        requiredClaim: ['admin', 'publisher'],
    },
    {
        order: 5,
        icon: 'redeem',
        slug: MenuSlugs.Redeemer,
        label: 'Redemption',
        requiredClaim: ['admin'],
    },
];

const BackstageRoleClaim = 'ftg_bs_role';

class FeatureServiceImpl {
    async getAllowed(): Promise<MenuItem[]> {
        const items = await Promise.all(
            menu.map(async item => {
                if (await UserService.hasAnyValueForClaim(BackstageRoleClaim, item.requiredClaim)) {
                    return item;
                }
            })
        );

        const available = items.filter(element => element !== undefined);

        return available as MenuItem[];
    }
}

const FeatureService = new FeatureServiceImpl();
export default FeatureService;
