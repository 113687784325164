import { AddSkuToAccountRequest, ProfileWithOrderHistory } from 'core/models/User';
import AxiosService from 'core/services/AxiosService';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';

export const useSearchAccountByEmail = (
    email: string
): UseQueryResult<AxiosResponse<ProfileWithOrderHistory[]>, unknown> => {
    return useQuery(
        `account-${email}`,
        async () => AxiosService.get<ProfileWithOrderHistory[]>(`/orders/account/${email}`),
        {
            enabled: false,
            retry: false,
        }
    );
};

export const useAddSkuToAccountByEmail = (
    email: string
): UseMutationResult<AxiosResponse<any>, unknown, AddSkuToAccountRequest, unknown> => {
    return useMutation(`add-sku-to-account`, async (data: AddSkuToAccountRequest) => {
        return await AxiosService.post(`/orders/account/${email}`, data);
    });
};
