import React, { FC, useState } from 'react';
import { Grid, Typography, useTheme, TextField, Select, MenuItem, Button } from '@material-ui/core';
import { AutorenewRounded } from '@material-ui/icons';
import styled from 'styled-components';
import Game from 'core/models/Game';
import { Publisher } from 'core/state/publishers/publisher.actions';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';
import CatalogService from 'core/services/CatalogService';
interface GameMetaDataProps {
    handleChange: (arg0: any) => void;
    fetchGames: () => void;
    snackMessage: (message: string, status: string) => void;
    game: Game;
    publishers: Array<Publisher>;
}

const GameMetaData: FC<GameMetaDataProps> = ({ handleChange, game, publishers, fetchGames, snackMessage }) => {
    const { palette } = useTheme();
    const [loading, setLoading] = useState(false);

    const refreshDuration = async () => {
        try {
            setLoading(true);
            await CatalogService.refreshGameDuration(game.id);
            fetchGames();
        } catch (err) {
            snackMessage('There was an error refreshing duration. Please try again', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Typography variant="body2" style={{ fontWeight: 600, margin: '0 0 5px 0' }}>
                Game Meta Data
            </Typography>
            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4}>
                    <Typography>Game Name</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                    <TextField
                        id="name"
                        name="name"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        onChange={handleChange}
                        value={game.name}
                    />
                </Grid>
            </FormGroup>

            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4}>
                    <Typography>Game Sku</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                    <TextField
                        id="sku"
                        name="sku"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        onChange={handleChange}
                        value={game.sku}
                    />
                </Grid>
            </FormGroup>

            {game?.id && (
                <FormGroup container theme={palette} direction="row" alignItems="center">
                    <Grid item xs={5} md={4}>
                        <Typography>Duration</Typography>
                    </Grid>
                    <Grid item xs={7} md={8}>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                            <Grid item xs={10}>
                                <TextField
                                    id="duration"
                                    name="duration"
                                    autoComplete="off"
                                    variant="standard"
                                    aria-readonly={true}
                                    fullWidth
                                    type="text"
                                    value={game.duration}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    onClick={refreshDuration}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={!loading && <AutorenewRounded />}
                                >
                                    <ProgressiveButtonText text="Refresh" isLoading={loading} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormGroup>
            )}

            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4}>
                    <Typography>Publisher</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                    <Select
                        fullWidth
                        displayEmpty
                        variant="standard"
                        labelId="publisher-label"
                        id="publisherId"
                        name="publisherId"
                        value={game.publisherId}
                        onChange={handleChange}
                    >
                        {publishers.map((publisher: Publisher) => (
                            <MenuItem value={publisher.id} key={publisher.id}>
                                {publisher.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </FormGroup>

            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4}>
                    <Typography>About Content URL</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                    <TextField
                        id="aboutUri"
                        name="aboutUri"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        value={game.aboutUri}
                        onChange={handleChange}
                    />
                </Grid>
            </FormGroup>

            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4}>
                    <Typography>Price (Cents)</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                    <TextField
                        id="price"
                        name="price"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="number"
                        value={game.price}
                        onChange={handleChange}
                    />
                </Grid>
            </FormGroup>
        </>
    );
};

const FormGroup = styled(Grid)`
    width: 100%;
    background-color: ${style => style.theme.background.paper};
    padding: 0 20px;
`;

export default GameMetaData;
