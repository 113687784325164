import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography, useTheme, Grid, TextField } from '@material-ui/core';

interface ContainerFormProps {
    handleChange: (event: any) => void;
    name: string;
    shortKey: string;
}

const ContainerForm: FC<ContainerFormProps> = ({ handleChange, name, shortKey }) => {
    const { palette } = useTheme();
    return (
        <>
            <FormGroup
                position="start"
                container
                theme={palette}
                direction="row"
                alignItems="center"
                style={{ marginTop: 30 }}
            >
                <Grid item xs={5} md={4}>
                    <Typography style={{ margin: 0 }}>Container Name</Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                    <TextField
                        id="name"
                        name="name"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        value={name}
                        onChange={handleChange}
                    />
                </Grid>
            </FormGroup>
            <FormGroup position="end" container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4}>
                    <Typography style={{ margin: 0 }}>Short Adventure Key</Typography>
                    <Typography variant="body2" style={{ margin: 0, color: palette.text.secondary }}>
                        Reference ID for searching containers
                    </Typography>
                </Grid>
                <Grid item xs={7} md={8}>
                    <TextField
                        id="shortKey"
                        name="shortKey"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        value={shortKey}
                        onChange={handleChange}
                        inputProps={{ maxLength: 4 }}
                    />
                </Grid>
            </FormGroup>
        </>
    );
};

const FormGroup = styled(Grid)<{ position?: 'start' | 'end' }>`
    width: 100%;
    background-color: ${style => style.theme.background.paper};
    padding: 0 20px;
    ${style => {
        if (style.position === 'start') {
            return 'padding-top: 10px';
        }
        if (style.position === 'end') {
            return 'padding-bottom: 10px';
        }
    }}
`;

export default ContainerForm;
