import AxiosService from 'core/services/AxiosService';
import Game, { CreateGameRequest, Track, PlaylistResponse } from 'core/models/Game';
import Asset, { CreateJobRequest, Job, CreateJobRequestResponse, FetchStreamkeyResponse } from 'core/models/Asset';
import axios, { AxiosResponse } from 'axios';
import Guid from 'core/models/Guid';
import {
    Containers,
    AddContainerRequest,
    AddContainerResponse,
    AddContainerImageRequest,
    AddContainerImageResponse,
    UpdateContainerRequest,
} from 'core/models/Containers';

export const formValidationRules: any = {
    name: /\w{2,}/,
    sku: /\w{2,}/,
    shortKey: /^[a-zA-Z0-9]{2,5}$/,
    publisherId: /\w{1,}/,
    aboutUri: /^((http|https):\/\/)(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/gm,
    price: /^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$/,
};

export const isValid = (expression: any, value: any) => {
    const check = new RegExp(expression);
    return check.test(value);
};

class CatalogServiceImpl {
    async getGames(): Promise<Array<Game>> {
        return new Promise<Array<Game>>((resolve, reject) => {
            AxiosService.get('/catalog/games')
                .then((response: AxiosResponse<Array<Game>>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async refreshGameDuration(gameId: string): Promise<Game> {
        return new Promise<Game>((resolve, reject) => {
            AxiosService.post(`b/catalog/games/${gameId}/duration`, {})
                .then((response: AxiosResponse<Game>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async addNewGame(formData: CreateGameRequest): Promise<Game> {
        return new Promise<Game>((resolve, reject) => {
            AxiosService.post('/b/catalog/games', formData)
                .then((response: AxiosResponse<Game>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async updateGame(formData: CreateGameRequest): Promise<Game> {
        const { id, ...postData } = formData;
        return new Promise<Game>((resolve, reject) => {
            AxiosService.post(`/b/catalog/games/${id}`, postData)
                .then((response: AxiosResponse<Game>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async updateCardImage(card: File, type: string, id: Guid): Promise<Game> {
        const postData = new FormData();
        postData.set('card', card);
        postData.set('cardType', type);

        return new Promise<Game>((resolve, reject) => {
            AxiosService.post(`/b/catalog/games/${id}/card`, postData)
                .then((response: AxiosResponse<Game>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async getGameById(gameId: Guid): Promise<Game> {
        return new Promise<Game>((resolve, reject) => {
            AxiosService.get(`/catalog/games/${gameId}`)
                .then((response: AxiosResponse<Game>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async getContainersByGameId(gameId: Guid): Promise<Array<Containers>> {
        return new Promise<Array<Containers>>((resolve, reject) => {
            AxiosService.get(`/catalog/games/${gameId}/containers`)
                .then((response: AxiosResponse<Array<Containers>>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async getAudioStreamKey(locatorId: string): Promise<FetchStreamkeyResponse> {
        return new Promise<FetchStreamkeyResponse>((resolve, reject) => {
            AxiosService.get(`/b/media/streamkey/${locatorId}`)
                .then((response: AxiosResponse<FetchStreamkeyResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async orderContainers(containerIds: Array<string>, gameId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            AxiosService.post(`/b/catalog/games/${gameId}/containerorder`, { containerIds })
                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async addContainer(gameId: Guid, data: AddContainerRequest): Promise<AddContainerResponse> {
        return new Promise<AddContainerResponse>((resolve, reject) => {
            AxiosService.post(`/b/catalog/games/${gameId}/containers`, data)
                .then((response: AxiosResponse<AddContainerResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async updateContainer(
        gameId: Guid,
        containerId: Guid,
        data: UpdateContainerRequest
    ): Promise<AddContainerResponse> {
        return new Promise<AddContainerResponse>((resolve, reject) => {
            AxiosService.post(`/b/catalog/games/${gameId}/containers/${containerId}`, data)
                .then((response: AxiosResponse<AddContainerResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async updateContainerImage(
        gameId: Guid,
        containerId: Guid,
        { image, imageType }: AddContainerImageRequest
    ): Promise<AddContainerImageResponse> {
        const postData = new FormData();
        postData.set('image', image);
        postData.set('imageType', imageType);

        return new Promise<AddContainerImageResponse>((resolve, reject) => {
            AxiosService.post(`/b/catalog/games/${gameId}/containers/${containerId}/images`, postData)
                .then((response: AxiosResponse<AddContainerImageResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async getMediaAssets(gameId: string): Promise<Array<Asset>> {
        return new Promise<Array<Asset>>((resolve, reject) => {
            AxiosService.get(`/b/media/${gameId}/assets`)
                .then((response: AxiosResponse<Array<Asset>>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async getAciveJobs(gameId: string): Promise<Array<Job>> {
        return new Promise<Array<Job>>((resolve, reject) => {
            AxiosService.get(`/b/media/${gameId}/jobs`)
                .then((response: AxiosResponse<Array<Job>>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async createMediaJob(data: CreateJobRequest): Promise<CreateJobRequestResponse> {
        return new Promise<CreateJobRequestResponse>((resolve, reject) => {
            AxiosService.post('/b/media/jobs', data)
                .then((response: AxiosResponse<CreateJobRequestResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async uploadAsset(uploadSasUri: string, file: File): Promise<Asset> {
        return new Promise<Asset>((resolve, reject) => {
            axios
                .put(uploadSasUri, file, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                    },
                })
                .then((response: AxiosResponse<Asset>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async deleteMediaJob(jobId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            AxiosService.delete(`/b/media/jobs/${jobId}`)
                .then((response: AxiosResponse<void>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async getGamePlaylist(gameId: string, containerId: string): Promise<PlaylistResponse> {
        return new Promise<PlaylistResponse>((resolve, reject) => {
            AxiosService.get(`/b/catalog/games/${gameId}/containers/${containerId}/playlist`)
                .then((response: AxiosResponse<PlaylistResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }

    async updateGamePlaylist(
        gameId: string,
        containerId: string,
        updatedList: Array<Track>
    ): Promise<PlaylistResponse> {
        return new Promise<PlaylistResponse>((resolve, reject) => {
            AxiosService.put(`/b/catalog/games/${gameId}/containers/${containerId}/playlist`, { content: updatedList })
                .then((response: AxiosResponse<PlaylistResponse>) => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    }
}

const CatalogService = new CatalogServiceImpl();
export default CatalogService;
