import React, { FC, useState, useRef } from 'react';
import { Grid, Typography, useTheme, Card, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import {
    DragIndicatorRounded,
    MoreVertRounded,
    CreateRounded,
    WallpaperRounded,
    LabelRounded,
    TextFormatRounded,
    DeleteRounded,
} from '@material-ui/icons';
import styled from 'styled-components';

// Re-Order
import { XYCoord } from 'dnd-core';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { BlockTypes } from 'domain/scry/models/Community';

interface BlockContentRowProps {
    block: BlockTypes;
    edit: (index: number) => void;
    removeBlock: (index: number) => void;
    blockIndex: number;
    moveBlock: (dragIndex: number, hoverIndex: number) => void;
}
interface DragItem {
    index: number;
    id: string;
    type: string;
}

const ItemTypes = {
    CARD: 'card',
};

const BlockContentRow: FC<BlockContentRowProps> = ({ block, edit, blockIndex, removeBlock, moveBlock }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef<HTMLDivElement>(null);
    const { palette } = useTheme();

    const blockIcon = (type: 'Image' | 'Html' | 'Tag') => {
        switch (type) {
            case 'Image':
                return <WallpaperRounded />;
            case 'Tag':
                return <LabelRounded />;
            default:
                return <TextFormatRounded />;
        }
    };

    const handleDropdownAction = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleEdit = () => {
        handleDropdownAction(null);
        edit(blockIndex);
    };

    const handleDelete = () => {
        handleDropdownAction(null);
        removeBlock(blockIndex);
    };

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = blockIndex;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveBlock(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id: `${blockIndex}-block-item`, index: blockIndex },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <Card ref={ref} style={{ marginBottom: 10, opacity }} key={JSON.stringify(block)} data-handler-id={handlerId}>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{ padding: '6px 0' }}
                    >
                        <Grid item>
                            <DragIndicatorRounded style={{ color: palette.text.secondary, cursor: 'move' }} />
                        </Grid>
                        <Grid item>
                            <BlockType theme={palette}>{blockIcon(block.contentType)}</BlockType>
                        </Grid>
                        <Grid item>
                            <Typography>{block.title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <MoreVertRounded
                        aria-controls={`${blockIndex}-container-actions`}
                        aria-haspopup="true"
                        style={{ margin: '0 5px 0 12px', cursor: 'pointer', color: palette.text.main }}
                        onClick={handleDropdownAction}
                    />
                    <Menu
                        id={`${blockIndex}-container-actions`}
                        onClose={handleDropdownAction}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <StyledMenu dense theme={palette} onClick={handleEdit}>
                            <ListItemIcon>
                                <CreateRounded />
                            </ListItemIcon>
                            <Typography>Edit</Typography>
                        </StyledMenu>
                        <StyledMenu dense theme={palette} onClick={handleDelete}>
                            <ListItemIcon>
                                <DeleteRounded />
                            </ListItemIcon>
                            <Typography>Delete</Typography>
                        </StyledMenu>
                    </Menu>
                </Grid>
            </Grid>
        </Card>
    );
};

const StyledMenu = styled(MenuItem)`
    background-color: ${style => style.theme.background.paper} !important;
    &:hover {
        background-color: ${style => style.theme.background.paper} !important;
    }
`;

const BlockType = styled.div`
    display: flex;
    padding: 4px;
    border-radius: 4px;
    background-color: black;
    color: ${style => style.theme.text.main};
`;

export default BlockContentRow;
