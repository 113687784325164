import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ImageUploader from 'components/shared/ImageUploader';
import { NewImageBlob } from 'core/models/Game';

interface PreviewUrls {
    logoUri: string;
}

interface UploadedImageUrls {
    uploadedLogoUri: NewImageBlob | null;
}

interface PublisherMediaProps {
    handleImageUpload: (arg0: NewImageBlob, arg1: 'uploadedLogoUri') => void;
    previewUrls: PreviewUrls;
    uploadedImageUrls: UploadedImageUrls;
}

interface Image {
    imageUrl: string;
    name: 'logoUri';
    uploadName: 'uploadedLogoUri';
    label: string;
    subLabel: string;
}

const PublisherMediaForm: FC<PublisherMediaProps> = ({ handleImageUpload, previewUrls, uploadedImageUrls }) => {
    const imageUploadTypes: Array<Image> = [
        {
            imageUrl: uploadedImageUrls?.uploadedLogoUri?.blob || previewUrls.logoUri,
            name: 'logoUri',
            uploadName: 'uploadedLogoUri',
            label: 'Publisher Logo',
            subLabel: '500x500 @3',
        },
    ];

    return (
        <>
            <Typography variant="body2" style={{ fontWeight: 600, margin: '35px 0 5px 0' }}>
                Publisher Assets
            </Typography>
            <Grid container direction="row" alignItems="flex-start" spacing={3}>
                {imageUploadTypes.map((image: Image, index: number) => (
                    <Grid item xs={4} lg={3} key={`${index}-${image.name}`}>
                        <ImageUploader
                            imageUrl={image.imageUrl}
                            name={image.name}
                            id={image.name}
                            label={image.label}
                            subLabel={image.subLabel}
                            onSuccessfullDrop={(event: NewImageBlob) => handleImageUpload(event, image.uploadName)}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default PublisherMediaForm;
