import React, { ReactElement } from 'react';
import { MenuSlugs } from 'core/services/FeatureService';
import { InfoRounded } from '@material-ui/icons';
import Guid from 'core/models/Guid';

export interface PublisherSubMenuItem {
    icon: ReactElement;
    text: string;
    path: string;
    selected: boolean;
}

const PublisherSubMenu = (publisherId: Guid, currentPageName: string): Array<PublisherSubMenuItem> => {
    return [
        {
            icon: <InfoRounded />,
            text: 'Meta Data',
            path: `/${MenuSlugs.Publishers}/${publisherId}`,
            selected: currentPageName === 'summary',
        },
    ];
};
export default PublisherSubMenu;
