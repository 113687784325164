import React, { FC, useReducer, useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, Button, Card, CardHeader, CardContent, TextField, useTheme } from '@material-ui/core';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useScryState } from 'core/state/scry/scry.state';
import {
    ConfigurationStateDefinition,
    StateChange,
    AmountTypes,
    RewardScale,
} from 'core/models/scry/ConfigurationDefinitions';
import ScryService from 'core/services/ScryService';
import { ScryActions } from 'core/state/scry/scry.actions';
import { GameId } from 'core/models/Game';

interface ValidationArray {
    name: string;
    nonSubscriber: string;
    subscriberOne: string;
    subscriberTwo: string;
    subscriberThree: string;
}

const initialState: ConfigurationStateDefinition = {
    id: '',
    channelId: '',
    gameId: '',
    rewardScale: {
        nonSubscriber: {
            monthlyAmount: 0,
            watchAmount: 0,
        },
        subscriberOne: {
            monthlyAmount: 0,
            watchAmount: 0,
        },
        subscriberTwo: {
            monthlyAmount: 0,
            watchAmount: 0,
        },
        subscriberThree: {
            monthlyAmount: 0,
            watchAmount: 0,
        },
    },
};

const updateState = (
    currentState: ConfigurationStateDefinition,
    objectKey: string,
    innerKey: string,
    value: number | string
) => {
    if (objectKey === 'root') {
        return {
            ...currentState,
            [innerKey]: value,
        };
    }

    const updatedObjectValue = () => {
        const fullList: any = Object.entries(currentState.rewardScale);
        return fullList.reduce((acc: RewardScale, curr: [string, AmountTypes]) => {
            const [key, object] = curr;
            if (key === objectKey) {
                return {
                    ...acc,
                    [key]: {
                        ...object,
                        [innerKey]: parseInt(value as string),
                    },
                };
            }

            return {
                ...acc,
                [key]: object,
            };
        }, {});
    };

    return {
        ...currentState,
        rewardScale: updatedObjectValue(),
    };
};

const reducer = (state: ConfigurationStateDefinition, [type, payload]: [string, any]) => {
    switch (type) {
        case StateChange.SET_INIT_DATA:
            return {
                ...state,
                ...payload,
            };
        case StateChange.SET_GAME_ID:
            return {
                ...state,
                gameId: payload,
            };
        case StateChange.UPDATE_FIELD:
            return updateState(state, payload.objectKey, payload.innerKey, payload.value);
        default:
            return state;
    }
};

const GameConfiguration: FC = () => {
    const [{ auth }, appDispatch] = useAppState();
    const [{ game }, scryDispatch]: any = useScryState();
    const [form, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(true);

    const { palette } = useTheme();
    const { goBack } = useHistory();
    const { gameId }: GameId = useParams();

    useEffect(() => {
        if (gameId) {
            dispatch([StateChange.SET_GAME_ID, gameId]);
        }
    }, [gameId]);

    const setLocalConfig = useCallback(
        async (config: any) => {
            if (!config) {
                try {
                    const getConfigRequest = await ScryService.getGameConfiguration(gameId);
                    scryDispatch({
                        type: ScryActions.GET_GAME_CONFIGURATION,
                        value: getConfigRequest,
                    });
                } catch (err) {
                    appDispatch({
                        type: AppActions.TOAST_SNACK,
                        message: 'There was an error fetching the game configuration',
                        status: 'error',
                        open: true,
                    } as ToastSnackAction);
                    setTimeout(() => goBack(), 300);
                } finally {
                    setLoading(false);
                    return;
                }
            }

            dispatch([StateChange.SET_INIT_DATA, config]);
            setTimeout(() => setLoading(false), 300);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [game.configuration]
    );

    useEffect(() => {
        if (auth.currentUser) {
            setLocalConfig(game.configuration);
        }
    }, [game.configuration, auth, setLocalConfig]);

    const postGameConfig = async () => {
        setLoading(true);
        try {
            const postConfigRequest = await ScryService.postGameConfiguration(gameId, form);
            scryDispatch({
                type: ScryActions.GET_GAME_CONFIGURATION,
                value: postConfigRequest,
            });
        } catch (err) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: err.message,
                status: 'error',
                open: true,
            } as ToastSnackAction);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(gameId).then(
                () => {
                    appDispatch({
                        type: AppActions.TOAST_SNACK,
                        message: 'Game key Copied!',
                        status: 'success',
                        open: true,
                    } as ToastSnackAction);
                },
                error => {
                    console.log(error);
                }
            );
        }
    };

    const CopyGameKey = () => (
        <i
            className="far fa-copy"
            style={{ color: palette.action.active, fontSize: '1.25rem', cursor: 'pointer' }}
            onClick={copyToClipboard}
        ></i>
    );

    const handleChange = (event: { target: { id: string; value: string | number } }) => {
        const [objectKey, innerKey] = event.target.id.split(':');
        const { value } = event.target;
        dispatch([
            StateChange.UPDATE_FIELD,
            {
                objectKey,
                innerKey,
                value: value === '' ? 0 : value,
            },
        ]);
    };

    const tierValidation = () => {
        // build the data structure to loop & validate
        const validateArrayConstruct = ['monthlyAmount', 'watchAmount'].reduce(
            (acc: Array<ValidationArray>, curr: string) => {
                return [
                    ...acc,
                    {
                        name: curr,
                        nonSubscriber: form.rewardScale.nonSubscriber[curr],
                        subscriberOne: form.rewardScale.subscriberOne[curr],
                        subscriberTwo: form.rewardScale.subscriberTwo[curr],
                        subscriberThree: form.rewardScale.subscriberThree[curr],
                    },
                ];
            },
            []
        );

        // create an array of booleans that determine if each object validates
        const checkIfFieldsAreValid = validateArrayConstruct.reduce((acc: Array<boolean>, curr: ValidationArray) => {
            const { nonSubscriber, subscriberOne, subscriberTwo, subscriberThree } = curr;

            const noNegativeNumbers = [nonSubscriber, subscriberOne, subscriberTwo, subscriberThree].every(
                (value: number | string) => value >= 0
            );
            if (!noNegativeNumbers) {
                return [...acc, false];
            }

            const isNonSubscriberValid =
                nonSubscriber <= subscriberOne && nonSubscriber <= subscriberTwo && nonSubscriber <= subscriberThree;
            const isSubscriberOneValid =
                subscriberOne >= nonSubscriber && subscriberOne <= subscriberTwo && subscriberOne <= subscriberThree;
            const isSubscriberTwoValid =
                subscriberTwo >= nonSubscriber && subscriberTwo >= subscriberOne && subscriberTwo <= subscriberThree;
            const isSubscriberThreeValid =
                subscriberThree >= subscriberTwo &&
                subscriberThree >= subscriberOne &&
                subscriberThree >= nonSubscriber;
            return [
                ...acc,
                isNonSubscriberValid && isSubscriberOneValid && isSubscriberTwoValid && isSubscriberThreeValid,
            ];
        }, []);

        // all values in the array should be true if valid
        return checkIfFieldsAreValid.every((valid: boolean) => valid) && form.channelId && form.channelId !== '';
    };

    const inputTiers = () => {
        return ['nonSubscriber', 'subscriberOne', 'subscriberTwo', 'subscriberThree'].map(
            (input: string, index: number) => (
                <Grid item xs={12} md={6} lg={3} key={`${index}-${input}`}>
                    <Card>
                        <CardHeader title={index === 0 ? 'No-Sub' : `Tier ${index}`} />
                        <CardContent>
                            <TextField
                                type="number"
                                fullWidth
                                variant="filled"
                                InputProps={{ inputProps: { min: 0 } }}
                                id={`${input}:monthlyAmount`}
                                label="Monthly Accrual"
                                helperText="Per monthly subscription"
                                style={{ marginBottom: 15 }}
                                value={form.rewardScale[input]?.monthlyAmount}
                                onChange={handleChange}
                            />
                            <TextField
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                fullWidth
                                variant="filled"
                                id={`${input}:watchAmount`}
                                label="Live Watch Points"
                                helperText="Every 5 mins"
                                value={form.rewardScale[input]?.watchAmount}
                                onChange={handleChange}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )
        );
    };

    return (
        <Grid container spacing={5} style={{ marginTop: 20 }}>
            <Grid container justifyContent="space-between" direction="row" style={{ marginBottom: 20 }}>
                <Grid item>
                    <Typography variant="h1">Game Configuration</Typography>
                </Grid>
                <Grid item>
                    <Button variant="text" onClick={goBack} color="primary" style={{ padding: '0 20px' }}>
                        Discard Changes
                    </Button>
                    <Button onClick={postGameConfig} disabled={!tierValidation()} variant="contained" color="primary">
                        <ProgressiveButtonText text="Save Changes" isLoading={loading} />
                    </Button>
                </Grid>
            </Grid>

            <Grid container style={{ marginBottom: 40 }}>
                <Card style={{ width: '100%' }}>
                    <CardHeader title="CHANNEL SPECIFICATIONS" />
                    <CardContent>
                        <Grid container direction="row" justifyContent="space-evenly" spacing={5}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    id="gameKey"
                                    name="gameKey"
                                    value={gameId}
                                    disabled
                                    label="Game Key"
                                    InputProps={{
                                        endAdornment: <CopyGameKey />,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    id="root:channelId"
                                    name="channelId"
                                    value={form.channelId || ''}
                                    onChange={handleChange}
                                    label="Channel Id"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid container style={{ marginBottom: 20 }}>
                <Typography variant="h2" style={{ marginBottom: 30 }}>
                    Reward Programs
                </Typography>

                <Grid container justifyContent="space-evenly" direction="row" spacing={5}>
                    {inputTiers()}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GameConfiguration;
