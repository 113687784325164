import React, { useRef, FC, useState, useEffect } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import {
    Grid,
    TextField,
    useTheme,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DragIndicatorRounded, PanToolRounded, DeleteOutlineRounded } from '@material-ui/icons';
import DeleteConfirm from './DeleteConfirm';
import { Track } from 'core/models/Game';
import Asset from 'core/models/Asset';
import { sortByKey } from 'domain/narration/utils';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';
import CatalogService from 'core/services/CatalogService';
import { isSafari, isIOS, isMacOs } from 'react-device-detect';

export const ItemTypes = {
    CARD: 'card',
};
export interface CardProps {
    track: Track;
    index: number;
    media: Array<Asset>;
    displayMoveIcon: boolean;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    onChange: (id: string, key: string, asset: Asset | boolean | [boolean, 'return' | null]) => void;
    removeTrack: (id: string) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const TrackFormItem: FC<CardProps> = ({ track, index, moveCard, onChange, removeTrack, media, displayMoveIcon }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [displayDeleteConfirm, setDisplayDeleteConfirm] = useState(false);
    const [fetchingStreamkey, setFetchingStatus] = useState(false);
    const { palette } = useTheme();
    const { id, title, asset, transition } = track;

    useEffect(() => {});

    const confirmRemoveTrack = () => {
        removeTrack(id);
    };

    const handleChange = (event: any) => {
        onChange(id, event.target.name, event.target.value);
    };

    const handleForceStop = () => {
        onChange(id, 'forceEnd', [!track.transition.forceEnd, 'return']);
    };

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleSelect = (value: Asset) => {
        if (!value) {
            return;
        }
        onChange(id, 'asset', value);
    };

    const nodeAudioArray = sortByKey(media || [], 'name');
    const assetIndex = () => {
        if (nodeAudioArray.length > 0) {
            return nodeAudioArray.reduce(
                (acc: number, curr: Asset, index: number) => (curr.id === asset?.id ? index : acc),
                0
            );
        }
        return 0;
    };

    const handlePreviewAudio = async () => {
        if (track.asset) {
            try {
                setFetchingStatus(true);
                const aesToken = await CatalogService.getAudioStreamKey(track.asset.locatorId);
                const format = isMacOs || isSafari || isIOS ? 'm3u8-aapl' : 'mpd-time-csf';
                window.open(
                    `https://aka.ms/azuremediaplayer?url=${track.asset.streamUrl}(format=${format},encryption=cbc)&aes=true&aestoken=${aesToken.token}`
                );
            } catch (err) {
                console.log(err, 'handlePreviewAudio');
            } finally {
                setFetchingStatus(false);
            }
        }
    };

    drag(drop(ref));
    return (
        <Grid
            container
            ref={ref}
            style={{
                opacity: isDragging ? 0 : 1,
                padding: '15px 40px',
            }}
        >
            <Grid item xs={12}>
                <TextField
                    id="title"
                    name="title"
                    label="Display Name"
                    autoComplete="off"
                    variant="filled"
                    size="small"
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    value={title}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="requirementDescription"
                    multiline
                    minRows={5}
                    autoComplete="off"
                    name="requirementDescription"
                    label="Requirements Description (option)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    value={transition.requirementDescription}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="asset-list"
                    fullWidth
                    options={nodeAudioArray}
                    getOptionLabel={option => (option.name ? option.name : '')}
                    value={track.asset ? nodeAudioArray[assetIndex()] : null}
                    onChange={(event: object, value: any) => handleSelect(value)}
                    renderInput={params => (
                        <TextField {...params} id="asset" name="asset" label="Node Audio" variant="filled" />
                    )}
                />
            </Grid>
            {track.asset && (
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button color="primary" onClick={handlePreviewAudio}>
                        <ProgressiveButtonText text="Preview Audio" isLoading={fetchingStreamkey} />
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    id="transitionPrompt"
                    autoComplete="off"
                    name="transitionPrompt"
                    label="Transition Prompt (optional)"
                    variant="filled"
                    size="small"
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    value={transition.transitionPrompt}
                />
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    borderBottom: `1px solid ${palette.borders.secondaryPaperColor}`,
                    paddingBottom: 15,
                    marginTop: 5,
                }}
            >
                <Grid container justifyContent="space-between" direction="row">
                    <Grid item>
                        {transition.forceEnd && (
                            <Grid container>
                                <FormControl component="fieldset">
                                    <Typography color="textSecondary" style={{ margin: '0 0 5px 0' }}>
                                        End Call to Action
                                    </Typography>
                                    <RadioGroup
                                        aria-label="end call to action"
                                        name="endType"
                                        value={transition.endType}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="return"
                                            control={<Radio />}
                                            label="Default (back to scenario list)"
                                        />
                                        <FormControlLabel value="winlose" control={<Radio />} label="Win & Lose" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        )}
                        {displayMoveIcon && (
                            <DragIndicatorRounded
                                style={{
                                    color: palette.text.secondary,
                                    cursor: 'move',
                                    marginTop: transition.forceEnd ? 15 : 0,
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="text"
                            onClick={handleForceStop}
                            style={{
                                margin: 0,
                                padding: 0,
                                minWidth: 0,
                            }}
                        >
                            <PanToolRounded
                                style={{
                                    color: transition.forceEnd ? palette.error.main : palette.text.secondary,
                                    fontSize: '1.3rem',
                                }}
                            />
                        </Button>
                        {displayMoveIcon && (
                            <Button
                                onClick={() => setDisplayDeleteConfirm(true)}
                                variant="text"
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    marginLeft: 15,
                                    paddingLeft: 15,
                                    minWidth: 0,
                                    borderLeft: `1px solid ${palette.borders.secondaryPaperColor}`,
                                }}
                            >
                                <DeleteOutlineRounded style={{ color: palette.text.main }} />
                            </Button>
                        )}
                    </Grid>
                </Grid>

                {displayDeleteConfirm && (
                    <DeleteConfirm
                        trackId={id}
                        listIsArray={displayMoveIcon}
                        setDisplayDeleteConfirm={setDisplayDeleteConfirm}
                        removeTrack={confirmRemoveTrack}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default TrackFormItem;
