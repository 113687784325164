import React, { useReducer, createContext, useContext, Fragment, useEffect } from 'react';
import Cookie from 'js-cookie';
import { Action, ToastSnackAction, AppActions } from './app.actions';
import { appReducer } from './app.reducer';
import Snack from '../../components/snack/Snack';
import { auth } from 'core/services/FirebaseService';
import { MenuItem } from 'core/services/FeatureService';
import { ai } from 'core/services/TelemetryService';
import UserService from 'core/services/UserService';

// Global Reducers
import { publisherReducer, publisherInitialState } from './publishers/publisher.reducer';
import { updateAuth } from 'core/services/AxiosService';

// Interfaces
export interface AppState {
    snack: {
        open: boolean;
        status: 'success' | 'warning' | 'error';
        message: string;
    };
    auth: AuthState;
    features: MenuItem[];
    activeFeature: MenuItem | null;
}

// Auth State
export interface AuthState {
    currentUser: firebase.User | null;
}

const initialAppState: AppState = {
    snack: {
        open: false,
        status: 'success',
        message: '',
    },
    auth: {
        currentUser: null,
    },
    features: [],
    activeFeature: null,
};

const AppStateContext = createContext(undefined as any);

const AppStateProvider = ({ children }: any) => {
    const [state, appDispatch] = useReducer(appReducer, initialAppState);
    const [publisherState, publisherDispatch] = useReducer(publisherReducer, publisherInitialState);
    const { snack } = state;

    useEffect(() => {
        let timeout: any;
        if (snack.open === true) {
            timeout = setTimeout(() => {
                appDispatch({
                    type: AppActions.TOAST_SNACK,
                    message: '',
                    status: 'success',
                    open: false,
                } as ToastSnackAction);
            }, 4000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [snack]);

    useEffect(() => {
        auth.onAuthStateChanged(onAuthStateChangedHandler);
        return () => {
            auth.onAuthStateChanged(onAuthStateChangedHandler);
        };
    }, []);

    const onAuthStateChangedHandler = async (authUser: firebase.User | null) => {
        if (authUser) {
            var token = await authUser.getIdToken();
            updateAuth(token);
            Cookie.set('backstage_session', token, { path: '/' });
            ai.setUserContext(authUser.uid);
        } else {
            updateAuth(null);
            Cookie.remove('backstage_session', { path: '/' });
            ai.setUserContext(null);
        }

        const userRole = await UserService.checkClaimValue('ftg_bs_role');
        const currentUser = authUser ? ({ ...authUser, userRole } as any) : null;

        appDispatch({
            type: AppActions.CURRENT_USER_CHANGED,
            currentUser,
        });

        if (authUser) {
            appDispatch({
                type: AppActions.FEATURES_UPDATE_REQUEST,
                user: {
                    ...authUser,
                    userRole,
                } as any,
                dispatcher: appDispatch,
            });
        }
    };

    const providerValue = [
        {
            ...state,
            publishers: {
                ...publisherState,
                dispatch: publisherDispatch,
            },
        },
        appDispatch,
    ];

    return (
        <Fragment>
            <AppStateContext.Provider value={providerValue}>{children}</AppStateContext.Provider>
            {snack.open && <Snack status={snack.status} message={snack.message} open={snack.open} />}
        </Fragment>
    );
};

const AppStateStorybookProvider = ({ children }: any, givenState: AppState) => {
    const context = useReducer(appReducer, givenState);
    const snackState = context[0].snack;

    const resetSnack = () => {
        context[1]({
            type: AppActions.TOAST_SNACK,
            status: 'success',
            message: '',
        } as ToastSnackAction);
    };

    return (
        <Fragment>
            <AppStateContext.Provider value={context}>{children}</AppStateContext.Provider>
            {snackState.open && (
                <Snack
                    status={snackState.status}
                    message={snackState.message}
                    open={snackState.open}
                    onClose={resetSnack}
                />
            )}
        </Fragment>
    );
};

const useAppState = (): [AppState, React.Dispatch<Action>] => {
    const context = useContext(AppStateContext);

    if (context === undefined) {
        throw new Error('useAppState must be within the AppStateProvider');
    }

    return context;
};

export { AppStateProvider, AppStateStorybookProvider, useAppState, initialAppState };
