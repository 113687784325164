/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';

import { useNarrationState } from 'core/state/narration/narration.state';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';
import Game, { GameId } from 'core/models/Game';
import { ContainerActionTypes } from 'core/models/Containers';
import { MenuSlugs } from 'core/services/FeatureService';
import { NarrationSubMenu } from '../components/manifest-editor/menus/SubMenu';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import SubNavigation from '../../../components/main-menu/SubNavigation';
import PageContainer from 'components/shared/PageContainer';
import ContainerListView from './components/ListWrapper';
import PageBreadcrumbs from 'components/shared/BreadCrumbs';

import { useContainerState } from 'core/state/narration/container.state';
import { useAppState } from 'core/state/app.state';

const ContainerEditor: FC = () => {
    const { gameId }: GameId = useParams();
    const { push } = useHistory();
    const { editorDispatch, containers, game: currentGame }: any = useContainerState();
    const [{ games, hasError }, narrationDispatch] = useNarrationState();
    const [
        {
            auth: { currentUser },
        },
    ] = useAppState();

    useEffect(() => {
        if (gameId && games.length > 0) {
            const currentGame = games.find((g: Game) => g.id === gameId) as Game;
            editorDispatch([ContainerActionTypes.SET_GAME, currentGame]);
        }
    }, [games, gameId]);

    useEffect(() => {
        if (gameId && currentUser) {
            editorDispatch([ContainerActionTypes.GET_CONTAINERS, { gameId, dispatch: editorDispatch }]);
        }
    }, [gameId, currentUser]);

    useEffect(() => {
        if (games.length === 0 && !hasError && currentUser) {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                dispatcher: narrationDispatch,
            } as FetchGamesRequestAction);
        }
    }, [games, currentUser]);

    const breadcrumbList = [
        { label: 'Narration', to: `/${MenuSlugs.Narration}` },
        { label: currentGame?.name || '' },
        { label: 'Editor' },
    ];

    return (
        <>
            <SubNavigation title="Game Info" navLinks={NarrationSubMenu(gameId, 'containers')} />
            <PageContainer>
                <PageBreadcrumbs links={breadcrumbList} />
                <>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        {currentGame && (
                            <>
                                <Grid item>
                                    <Typography variant="h1">{currentGame.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => push(`/${MenuSlugs.Narration}/${currentGame.id}/containers/add`)}
                                    >
                                        Create Container
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {currentGame && containers && (
                        <DndProvider backend={HTML5Backend}>
                            <ContainerListView gameId={currentGame.id} containers={containers || []} />
                        </DndProvider>
                    )}
                </>
            </PageContainer>
        </>
    );
};

export default ContainerEditor;
