/* eslint-disable react-hooks/exhaustive-deps */
import {
    Grid,
    makeStyles,
    createStyles,
    Theme,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Box,
    Typography,
    Button,
} from '@material-ui/core';
import { AddRounded, MoreHoriz } from '@material-ui/icons';
import { useDeleteMedia, useUploadMedia } from 'domain/scry/api/communities';
import { MediaItem } from 'domain/scry/models/Community';
import React, { FC, useEffect, useState } from 'react';

export interface AssetProps {
    media?: MediaItem;
    preview?: File;
    gameId: string;
    handleSelect?: (libraryId: string, storageId: string) => void;
}

const Asset: FC<AssetProps> = ({ media, preview, gameId, handleSelect }) => {
    const classes = useStyles();
    const imageUrl = preview
        ? URL.createObjectURL(preview)
        : `${process.env.REACT_APP_IMAGE_CDN_URL}/${media?.storageId ?? ''}`;

    const [displayErrorConfirm, setDisplayErrorConfirm] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteLoading, setLoading] = useState<boolean>(false);
    const [isHoverActive, setHoverActive] = useState<boolean>(false);

    const { mutateAsync } = useUploadMedia(gameId);
    const { mutateAsync: deleteFile, isSuccess: successfullDelete } = useDeleteMedia(gameId);

    const uploadImage = async () => {
        try {
            await mutateAsync(preview as File);
        } catch (err) {
            console.log(err);
            setDisplayErrorConfirm(true);
        }
    };

    useEffect(() => {
        if (preview) {
            uploadImage();
        }
    }, []);

    const handleDeleteFile = async () => {
        setLoading(true);
        await deleteFile(media!._id);
    };

    const LoadingContainer = () => (
        <div className={classes.loading}>
            <CircularProgress color="primary" />
        </div>
    );

    const deleteConfirm = () => {
        return (
            <Box textAlign="center" className={classes.confirmDialog}>
                {deleteLoading && <LoadingContainer />}
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            Are you sure you want to delete this image? This could affect live data.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.actions}>
                            <Button variant="contained" onClick={() => setDisplayErrorConfirm(false)}>
                                Cancel
                            </Button>

                            <Button onClick={handleDeleteFile}>Delete</Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const handlePopDeleteConfirm = () => {
        setAnchorEl(null);
        setDisplayErrorConfirm(true);
    };

    if (displayErrorConfirm && successfullDelete) {
        setDisplayErrorConfirm(false);
    }

    return (
        <Grid item xs={12} sm={4} md={3} className={classes.root}>
            <div
                className={classes.imageContainer}
                onMouseOver={() => setHoverActive(true)}
                onMouseLeave={() => setHoverActive(false)}
            >
                {displayErrorConfirm && deleteConfirm()}

                <Grid container justifyContent="space-between" direction="row" className={classes.header}>
                    <Grid item xs={9}>
                        <Typography noWrap={true} variant="caption" style={{ display: 'block', margin: 0 }}>
                            {media?.displayName ?? ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Box textAlign="right">
                            <MoreHoriz
                                aria-controls={`menu-${media?._id ?? 1}`}
                                aria-haspopup="true"
                                onClick={(event: React.ChangeEvent<any>) => {
                                    if (!media) {
                                        return null;
                                    }

                                    setAnchorEl(event.currentTarget);
                                }}
                                color="secondary"
                                fontSize="small"
                            />
                        </Box>

                        <Menu
                            variant="menu"
                            id={`menu-${media?._id ?? 1}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem onClick={handlePopDeleteConfirm}>Delete Image</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

                <IconButton
                    onClick={() => (handleSelect ? handleSelect(media!._id, media!.storageId) : null)}
                    className={classes.add}
                    style={{ display: isHoverActive ? 'block' : 'none' }}
                >
                    <AddRounded />
                </IconButton>

                <img src={imageUrl} alt="" className={classes.image} />
                {preview && <LoadingContainer />}
            </div>
        </Grid>
    );
};

export default Asset;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxHeight: 180,
            padding: theme.spacing(1),
        },
        header: {
            padding: '6px 7px 0',
            marginBottom: 5,
            borderRadius: '4px 4px 0 0',
            backgroundColor: theme.palette.background.default,
        },
        imageContainer: {
            height: 170,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 4,
            width: '100%',
            position: 'relative',
            border: `1px solid ${theme.palette.borders.secondaryPaperColor}`,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        image: {
            maxWidth: '100%',
            padding: '5px 10px 10px',
            width: 'fit-content',
            alignSelf: 'center',
            maxHeight: 132,
        },
        alt: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            textAlign: 'center',
            padding: '2px 5px',
        },
        add: {
            zIndex: 1200,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
        loading: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1500,
            borderRadius: 4,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
        moreActions: {
            zIndex: 1200,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            top: 5,
            right: 7,
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
        },
        confirmDialog: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.background.default,
            zIndex: 1250,
            borderRadius: 4,
            padding: 15,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        actions: {
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    })
);
