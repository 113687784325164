import React, { FC, useEffect, useState } from 'react';
import {
    Button,
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from '@material-ui/core';
import { NavigateNext, Search } from '@material-ui/icons';

import { useNarrationState } from 'core/state/narration/narration.state';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';
import Game from 'core/models/Game';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useHistory } from 'react-router-dom';
import { MenuSlugs } from 'core/services/FeatureService';

// shared components
import PageContainer from 'components/shared/PageContainer';
import PageTitle from 'components/shared/PageTitle';

const ListView: FC = () => {
    const [filter, setFilter] = useState('');
    const [
        {
            auth: { currentUser },
        },
        appDispatch,
    ] = useAppState();
    const { push } = useHistory();
    const [narrationState, narrationDispatch] = useNarrationState();

    useEffect(() => {
        if (narrationState.games.length === 0 && !narrationState.hasError && currentUser) {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                dispatcher: narrationDispatch,
            } as FetchGamesRequestAction);
        }
    }, [narrationState.games, narrationState.hasError, narrationDispatch, currentUser]);

    useEffect(() => {
        if (appDispatch && narrationState.hasError) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'Failed to get games',
                status: 'error',
                open: true,
            } as ToastSnackAction);
        }
    }, [appDispatch, narrationState.hasError]);

    const audioNarrationGames = narrationState.games.filter(g => g.type === 'AudioNarration');

    const gameRows = () => {
        const list: Array<Game> =
            filter.length > 0
                ? audioNarrationGames.filter(g => g.name.toLowerCase().indexOf(filter) !== -1)
                : audioNarrationGames;

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Game</TableCell>
                            <TableCell>Sku</TableCell>
                            <TableCell>Game ID</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(({ id, name, sku }: Game) => (
                            <TableRow key={id}>
                                <TableCell width="40%">{name}</TableCell>
                                <TableCell width="25%">{sku}</TableCell>
                                <TableCell width="30%">{id}</TableCell>
                                <TableCell
                                    style={{ cursor: 'pointer' }}
                                    align="right"
                                    onClick={() => push(`/${MenuSlugs.Narration}/${id}`)}
                                >
                                    <NavigateNext />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <PageContainer removeSidebar>
            <PageTitle text="Narration Games" />
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid xs={8} md={6} lg={4} item>
                    <TextField
                        id="filter"
                        name="filter"
                        label="Filter Games"
                        autoComplete="off"
                        variant="filled"
                        size="small"
                        fullWidth
                        type="text"
                        onChange={(event: any) => setFilter(event.target.value.toLowerCase())}
                        value={filter}
                        InputProps={{
                            endAdornment: <Search />,
                        }}
                    />
                </Grid>

                <Grid sm="auto" item>
                    <Button variant="contained" color="primary" onClick={() => push(`/${MenuSlugs.Narration}/add`)}>
                        New Narration Game
                    </Button>
                </Grid>

                <Grid container direction="column" style={{ marginTop: 25 }}>
                    {gameRows()}
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default ListView;
