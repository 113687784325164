import axios from 'axios';
import Cookie from 'js-cookie';

const baseUrl = process.env.REACT_APP_BACKSTAGE_API_URL;
const subscriptionKey = process.env.REACT_APP_BACKSTAGE_SUBSCRIPTION_KEY;

const AxiosService = axios.create({
    baseURL: baseUrl,
    headers: {
        'Forteller-Subscription-Key': subscriptionKey,
    },
});

export const updateAuth = async (newToken: string | null) => {
    return new Promise(resolve => {
        AxiosService.defaults.headers['Authorization'] = `Bearer ${newToken}`;
        resolve(true);
    });
};

const token = Cookie.get('backstage_session');
AxiosService.defaults.headers['Authorization'] = `Bearer ${token}`;

export default AxiosService;
