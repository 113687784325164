import React, { useRef, FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuSlugs } from 'core/services/FeatureService';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import {
    Grid,
    Typography,
    Card,
    useTheme,
    FormControlLabel,
    Switch,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@material-ui/core';
import { DragIndicatorRounded, MoreVertRounded, Launch, CreateRounded } from '@material-ui/icons';

import { useAppState } from 'core/state/app.state';
import { useContainerState } from 'core/state/narration/container.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import CatalogService from 'core/services/CatalogService';
import { ContainerActionTypes } from 'core/models/Containers';

export const ItemTypes = {
    CARD: 'card',
};

export interface ContainerListItemProps {
    containerData: any;
    gameId: string;
    index: number;
    moveContainer: (dragIndex: number, hoverIndex: number) => void;
    order: Array<string>;
    changeContainerOrder: (newArray: Array<string>) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}
const ContainerListItem: FC<ContainerListItemProps> = ({
    gameId,
    containerData,
    index,
    moveContainer,
    order,
    changeContainerOrder,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const { editorDispatch }: any = useContainerState();
    const [, appDispatch] = useAppState();
    const { push } = useHistory();
    const { palette } = useTheme();

    const handleDropdownAction = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveContainer(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop() {
            changeContainerOrder(order);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id: containerData.id, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const toggleEnabledStatus = async () => {
        try {
            const updatedContainerData = { ...containerData, published: !containerData.published };
            await CatalogService.updateContainer(gameId, containerData.id, updatedContainerData);
            editorDispatch([
                ContainerActionTypes.GET_CONTAINERS,
                { gameId: containerData.gameId, dispatch: editorDispatch },
            ]);
        } catch (err) {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                status: 'error',
                message: `There was an error ${
                    !containerData.published === false ? 'enabling' : 'disabling'
                } this container. Please try again.`,
            } as ToastSnackAction);
        }
    };

    drag(drop(ref));
    return (
        <Card style={{ marginBottom: 10, opacity: isDragging ? 0 : 1 }} ref={ref} data-handler-id={handlerId}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{
                    padding: '6px 0',
                }}
            >
                <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                        <Grid item>
                            <DragIndicatorRounded style={{ color: palette.text.secondary, cursor: 'move' }} />
                        </Grid>
                        <Grid item>
                            <Typography>{containerData.shortKey}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{containerData.name}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3} alignItems="center">
                        <Grid item>
                            <FormControlLabel
                                style={{
                                    paddingRight: 10,
                                }}
                                control={
                                    <Switch
                                        color="primary"
                                        name="published"
                                        onChange={toggleEnabledStatus}
                                        checked={containerData.published}
                                    />
                                }
                                label={containerData.published ? 'Enabled' : 'Disabled'}
                                labelPlacement="start"
                                value={containerData.published}
                            />
                        </Grid>
                        <Grid
                            style={{
                                borderLeft: `1px solid ${palette.borders.paperColor}`,
                            }}
                        >
                            <MoreVertRounded
                                aria-controls="container-actions"
                                aria-haspopup="true"
                                style={{ margin: '0 5px 0 12px', cursor: 'pointer', color: palette.text.main }}
                                onClick={handleDropdownAction}
                            />
                            <Menu
                                id="container-actions"
                                open={Boolean(anchorEl)}
                                onClose={handleDropdownAction}
                                anchorEl={anchorEl}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem
                                    dense
                                    onClick={() =>
                                        push(`/${MenuSlugs.Narration}/${gameId}/containers/${containerData.id}`)
                                    }
                                >
                                    <ListItemIcon>
                                        <CreateRounded />
                                    </ListItemIcon>
                                    <Typography>Edit</Typography>
                                </MenuItem>
                                <MenuItem
                                    dense
                                    onClick={() => push(`/${MenuSlugs.Narration}/${gameId}/${containerData.id}/items`)}
                                >
                                    <ListItemIcon>
                                        <Launch />
                                    </ListItemIcon>
                                    <Typography>Launch</Typography>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ContainerListItem;
