import React, { FC } from 'react';
import styled from 'styled-components';
import { Grid, useTheme, TextField, Typography } from '@material-ui/core';
import { Publisher } from 'core/state/publishers/publisher.actions';

interface PublisherFormProps {
    publisherDetails: Publisher;
    handleChange: (event: any) => void;
}

const PublisherForm: FC<PublisherFormProps> = ({ publisherDetails, handleChange }) => {
    const { name, aboutUri } = publisherDetails;
    const { palette } = useTheme();

    return (
        <>
            <Typography variant="body2" style={{ fontWeight: 600, margin: '20px 0 5px 0' }}>
                Publisher Meta Data
            </Typography>
            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4} lg={3}>
                    <Typography style={{ margin: 0 }}>Publisher Name</Typography>
                </Grid>
                <Grid item xs={7} md={8} lg={9}>
                    <TextField
                        id="name"
                        name="name"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        onChange={handleChange}
                        value={name}
                    />
                </Grid>
            </FormGroup>

            <FormGroup container theme={palette} direction="row" alignItems="center">
                <Grid item xs={5} md={4} lg={3}>
                    <Typography style={{ margin: 0 }}>Publisher URL</Typography>
                </Grid>
                <Grid item xs={7} md={8} lg={9}>
                    <TextField
                        id="aboutUri"
                        name="aboutUri"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        type="text"
                        onChange={handleChange}
                        value={aboutUri}
                    />
                </Grid>
            </FormGroup>
        </>
    );
};

const FormGroup = styled(Grid)`
    width: 100%;
    background-color: ${style => style.theme.background.paper};
    padding: 0 20px;
`;

export default PublisherForm;
