import React, { FC } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

interface TextProps {
    isLoading: boolean;
    text: string;
    color?: 'primary' | 'secondary';
}

const ProgressiveButtonText: FC<TextProps> = ({ isLoading, text, color = 'primary' }) => {
    return isLoading ? (
        <CircularProgress color={color} size="1.5rem" />
    ) : (
        <Typography component="div" variant="button">
            {text}
        </Typography>
    );
};

export default ProgressiveButtonText;
