/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from 'core/state/app.state';
import { useNarrationState } from 'core/state/narration/narration.state';

import AdminView from './components/management/AdminView';
import PublisherView from './components/management/PublisherView';
import PageContainer from './components/common/PageContainer';
import { NarrationActions } from 'core/state/narration/narration.actions';
import Game from 'core/models/Game';

const ScryManagement: FC = () => {
    const [{ auth }]: any = useAppState();
    const [gamesState, narrationDispatch] = useNarrationState();

    const { gameId }: { gameId: string } = useParams();
    useEffect(() => {
        if (auth?.currentUser && gamesState.games.length === 0) {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                gameId,
                dispatcher: narrationDispatch,
            });
        }
    }, [auth, gamesState.games]);

    const getScryData = gamesState.games.find((g: Game) => g.type === 'Scry');

    const prepAdminView = () => {
        if (!getScryData) {
            return [];
        }
        return [
            {
                publisherName: getScryData.publisher.name,
                games: [
                    {
                        gameName: getScryData.name,
                        gameId: getScryData.id,
                    },
                ],
            },
        ];
    };

    const renderPage = () => {
        return auth.currentUser?.userRole === 'admin' ? (
            <AdminView scryData={prepAdminView()} />
        ) : (
            <PublisherView
                id={getScryData?.id ?? ''}
                name={getScryData?.name ?? ''}
                gameDetailCardUri={getScryData?.gameDetailCardUri ?? 'images/outbreak-card-bg.png'}
            />
        );
    };

    return <PageContainer>{auth.currentUser !== null && renderPage()}</PageContainer>;
};

export default ScryManagement;
