import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

let appInsights: ApplicationInsights;

const createTelemetryService = (): {
    appInsights: ApplicationInsights;
    initialize(appName: string, appVersion: string, instrumentationKey: string): void;
    setUserContext(userId: string | null): void;
} => {
    const initialize = (appName: string, appVersion: string, instrumentationKey: string): void => {
        if (!appName) {
            throw new Error('Missing web app name');
        }
        if (!appVersion) {
            throw new Error('Missing web app version');
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided');
        }

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                enableAutoRouteTracking: true,
                maxBatchInterval: 2500,
                disableFetchTracking: false,
                disableTelemetry: instrumentationKey === 'LOCAL',
                endpointUrl: `${process.env.REACT_APP_BACKSTAGE_API_URL}/analytics`,
            },
        });

        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer(function (env: ITelemetryItem) {
            if (env && env.tags) {
                env.tags['ai.cloud.role'] = appName;
                env.tags['ai.application.ver'] = appVersion;
            }
        });

        if (instrumentationKey === 'LOCAL') {
            console.info('Instrumentation disabled for localhost development');
        }
    };

    const setUserContext = (userId: string | null): void => {
        if (appInsights?.addTelemetryInitializer) {
            appInsights.addTelemetryInitializer(function () {
                if (userId !== null) {
                    appInsights.setAuthenticatedUserContext(userId, undefined, true);
                } else {
                    appInsights.clearAuthenticatedUserContext();
                }
            });
        }
    };

    return { appInsights, initialize, setUserContext };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
