export interface LoginState {
    isBusy: boolean;
    loginAttemptMessage: string;
    isLoginAttemptValid: boolean;

    form: {
        canSubmit: boolean;
        pristine: boolean;
        email: string;
        password: string;
    };
}

export const initialLoginState: LoginState = {
    isBusy: false,
    loginAttemptMessage: '',
    isLoginAttemptValid: false,

    form: {
        canSubmit: false,
        pristine: true,
        email: '',
        password: '',
    },
};
