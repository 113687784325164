import React, { FC } from 'react';
import { useTheme, Typography, Grid, LinearProgress, Button } from '@material-ui/core';
import { VolumeUpRounded, PriorityHigh } from '@material-ui/icons';
import styled from 'styled-components';
import { FileToUpload } from './UploadAssetContainer';

interface UploadItemProps {
    file: FileToUpload;
    index: number;
    handleDeleteUploadItem: (indexNumber: number) => void;
    retryUpload: (index: number) => void;
}

const UploadItem: FC<UploadItemProps> = ({ file, index, handleDeleteUploadItem, retryUpload }) => {
    const { palette } = useTheme();
    const formatFileSize = (bytes: number) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))}${sizes[i]}`;
    };

    return (
        <Grid
            key={`file-${file.data.type}-${index}`}
            container
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ marginBottom: 15 }}
        >
            <Grid item xs={2}>
                <AssetType theme={palette} state={file.fetchStatus}>
                    {file.fetchStatus === 'error' ? <PriorityHigh /> : <VolumeUpRounded />}
                </AssetType>
            </Grid>
            <Grid item xs={10}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={9}>
                        <Typography style={{ margin: 0 }}>{file.data.name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography align="right" style={{ margin: 0 }}>
                            {formatFileSize(file.data.size)}
                        </Typography>
                    </Grid>
                </Grid>
                {file.fetchStatus !== 'error' ? (
                    <LinearProgress style={{ marginTop: 10 }} value={100} variant="indeterminate" color="primary" />
                ) : (
                    <Grid container direction="row">
                        <Grid item>
                            <Typography
                                variant="body2"
                                color={file.fetchStatus === 'error' ? 'error' : 'textPrimary'}
                                style={{ margin: 0 }}
                            >
                                Failed Upload
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleDeleteUploadItem(index)}
                                style={{
                                    fontSize: '0.725rem',
                                    margin: 0,
                                    padding: 0,
                                    position: 'relative',
                                    top: -3,
                                    lineHeight: 'normal',
                                }}
                            >
                                Remove
                            </Button>
                            <Button
                                onClick={() => retryUpload(index)}
                                style={{
                                    fontSize: '0.725rem',
                                    margin: 0,
                                    padding: 0,
                                    position: 'relative',
                                    top: -3,
                                    lineHeight: 'normal',
                                }}
                            >
                                Retry
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

const AssetType = styled.div<{ state: 'error' | 'fetching' | 'stale' }>`
    padding: 10px;
    display: flex;
    width: fit-content;
    border-radius: 8px;
    background-color: ${style =>
        style.state === 'error' ? style.theme.error.main : style.theme.background.secondaryPaper};
    color: ${style => style.theme.text.main};
`;

export default UploadItem;
