import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Breadcrumbs, useTheme } from '@material-ui/core';

interface BreadcrumbItem {
    label: string;
    to?: string;
}

interface PageBreadcrumbsProps {
    links: Array<BreadcrumbItem>;
    ariaLabel?: string;
}

const PageBreadcrumbs: FC<PageBreadcrumbsProps> = ({ links, ariaLabel }) => {
    const { palette } = useTheme();

    const BreadcrumbListiItem = ({ label, to }: BreadcrumbItem) => {
        if (to) {
            return (
                <Link style={{ color: palette.action.active, textDecoration: 'none', fontSize: '0.825rem' }} to={to}>
                    {label}
                </Link>
            );
        }

        return <Typography style={{ fontSize: '0.825rem', margin: 0 }}>{label}</Typography>;
    };

    return (
        <Breadcrumbs {...(ariaLabel && { ariaLabel })}>
            {links.map((link: BreadcrumbItem) => (
                <BreadcrumbListiItem key={JSON.stringify(link)} {...link} />
            ))}
        </Breadcrumbs>
    );
};

export default PageBreadcrumbs;
