import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useAppState } from 'core/state/app.state';

import PageContainer from '../../components/shared/PageContainer';
import PageTitle from '../../components/shared/PageTitle';

const DashboardContainer: React.FC = () => {
    const [state] = useAppState();

    const { auth } = state;

    return (
        <PageContainer removeSidebar>
            <PageTitle text="Dashboard" />
            <Grid item xs={12}>
                Hello, {auth.currentUser?.email}!
            </Grid>
        </PageContainer>
    );
};

export default DashboardContainer;
