import {
    LoginActions,
    LoginActionTypes,
    ChangeFormValuesAction,
    LoginRequestAction,
    LoginResponseAction,
} from './login.actions';
import { LoginState } from './login.state';

import UserService from 'core/services/UserService';
import { CurrentUserChangeAction, AppActions } from 'core/state/app.actions';

export const loginReducer = (state: LoginState, action: LoginActionTypes): LoginState => {
    let newState: LoginState;

    switch (action.type) {
        case LoginActions.CHANGE_FORM_VALUES:
            newState = changeFormValues(state, action as ChangeFormValuesAction);
            break;

        case LoginActions.LOGIN_REQUEST:
            // this is where the action is to try and login
            newState = login(state, action as LoginRequestAction);
            break;

        case LoginActions.LOGIN_RESPONSE:
            // this is where the api call finished good or bad
            newState = loginAttemptFinished(state, action as LoginResponseAction);
            break;

        default:
            console.warn('Login Reducer unknown or unhandled dispatch action', action);
            return state;
    }

    return {
        ...newState,
    };
};

//=============================================================================
function login(
    currentState: LoginState,
    { email, password, dispatcher, appDispatcher }: LoginRequestAction
): LoginState {
    UserService.login(email, password)
        .then(async result => {
            if (result !== null) {
                const userRole = await UserService.checkClaimValue('ftg_bs_role');
                UserService.hasClaim('ftg_bs_role')
                    .then(hasClaim => {
                        dispatchAll(hasClaim, hasClaim ? ({ ...UserService.currentUser, userRole } as any) : null);
                    })
                    .catch(error => {
                        console.error('Sign in failed', error);
                        dispatchAll(false);
                    });
            } else {
                dispatchAll(false);
            }
        })
        .catch(error => {
            console.error('Sign in failed', error);
            dispatchAll(false);
        });

    // Wrapper for dispatching failure actions
    const dispatchAll = (success: boolean, cred: firebase.User | null = null) => {
        appDispatcher({
            type: AppActions.CURRENT_USER_CHANGED,
            currentUser: cred,
        } as CurrentUserChangeAction);

        dispatcher({
            type: LoginActions.LOGIN_RESPONSE,
            success: success,
            message: success ? 'Sign in successful' : 'Sign in failed',
        } as LoginResponseAction);
    };

    return {
        ...currentState,
        isBusy: true,
    };
}

//=============================================================================
function loginAttemptFinished(currentState: LoginState, { message, success }: LoginResponseAction): LoginState {
    const newState = {
        ...currentState,
        isBusy: false,
        isLoginAttemptValid: success,
        loginAttemptMessage: message,
    };

    if (success) {
        return {
            ...newState,
            form: {
                email: '',
                password: '',
                canSubmit: false,
                pristine: true,
            },
        };
    }

    return newState;
}

//=============================================================================
function changeFormValues(currentState: LoginState, { field, value }: ChangeFormValuesAction): LoginState {
    const newState: LoginState = currentState;

    if (field === 'email') {
        newState.form.pristine = false;
        newState.form.email = value;
    }

    if (field === 'password') {
        newState.form.pristine = false;
        newState.form.password = value;
    }

    return newState;
}
