import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
// import 'firebase/analytics';

const config = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FB_DB,
    projectId: process.env.REACT_APP_FB_PID,
    storageBucket: process.env.REACT_APP_FB_SB,
    messagingSenderId: process.env.REACT_APP_FB_SID,
    appId: process.env.REACT_APP_FB_APPID,
    measurementId: process.env.REACT_APP_FB_MID,
};

const app = firebase.initializeApp(config);
const auth = app.auth();
// const analytics = app.analytics();

const baseUrl = process.env.REACT_APP_BASE_URL;
const scryBaseUrl = process.env.REACT_APP_SCRY_BASE_URL;

const subscriptionKey = process.env.REACT_APP_BACKSTAGE_SUBSCRIPTION_KEY;

export { auth, baseUrl, scryBaseUrl, subscriptionKey };
