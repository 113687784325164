import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface Props {
    children: any;
    pageTitle?: string;
}

const PageContainer: FC<Props> = ({ children, pageTitle }) => (
    <Container>
        {pageTitle && (
            <Typography variant="h1" style={{ marginBottom: 0 }}>
                {pageTitle}
            </Typography>
        )}
        {children}
    </Container>
);

const Container = styled.div`
    display: flex;
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column;
    flex: 1;
`;

export default PageContainer;
