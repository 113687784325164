import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { useGetCartography, useUpdateCartography } from 'domain/scry/api/communities';
import MediaAssetBox from 'components/shared/MediaAssetBox';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
interface GlobalMapProps {
    gameId: string;
}

const GlobalMap: FC<GlobalMapProps> = ({ gameId }) => {
    const { palette } = useTheme();
    const { appDispatch }: any = useAppState();
    const [uploadError, setUploadError] = useState<boolean>(false);

    const { data } = useGetCartography(gameId);
    const { mutateAsync } = useUpdateCartography(gameId);
    const imageData = data?.data?.worldMapId ?? '';

    const handleSelectMap = async (libraryId: string, name: string) => {
        try {
            await mutateAsync({ gameId, worldMapId: libraryId });
            setUploadError(false);
        } catch (err) {
            setUploadError(true);
            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: 'Failed to attach Image, please try again',
                status: 'error',
                open: true,
            } as ToastSnackAction);
        }
    };

    return (
        <>
            <Wrapper theme={palette}>
                <Header item xs={12} alignItems="center" container theme={palette}>
                    <Typography variant="h2">Global Map</Typography>
                </Header>

                <ActionContainer>
                    <MediaAssetBox
                        url={uploadError ? '' : imageData}
                        dataName="worldMapId"
                        selectAction={handleSelectMap}
                    />
                </ActionContainer>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Grid)`
    background-color: ${style => style.theme.background.paper};
    height: 100%;
    border-radius: 3px;
`;
const Header = styled(Grid)`
    padding: 5px 30px;
    border-bottom: 1px solid ${style => style.theme.background.secondaryPaper};
`;

const ActionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default GlobalMap;
