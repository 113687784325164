import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useNarrationState } from 'core/state/narration/narration.state';
import { useEffect } from 'react';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';
import { useAppState } from 'core/state/app.state';

interface GamePickerProps {
    selected: string;
    onChange: any;
    updateGameImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const GamePicker: React.FC<GamePickerProps> = props => {
    const [
        {
            auth: { currentUser },
        },
    ] = useAppState();
    const [narrationState, narrationDispatch] = useNarrationState();

    useEffect(() => {
        if (narrationState.games.length === 0 && !narrationState.hasError && currentUser) {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                dispatcher: narrationDispatch,
            } as FetchGamesRequestAction);
        }
    }, [narrationState.games, narrationState.hasError, narrationDispatch, currentUser]);

    return (
        <>
            <FormControl variant="filled">
                <InputLabel id="lbl-choose-game">Choose Game SKU</InputLabel>
                <Select
                    labelId="lbl-choose-game"
                    id="sel-choose-game"
                    label="Choose Game SKU"
                    value={props.selected}
                    onChange={props.onChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {narrationState.games.map(g => {
                        return (
                            <MenuItem key={g.sku} value={g.sku}>
                                {g.name} by&nbsp;<span style={{ fontStyle: 'italic' }}>{g.publisher.name}</span>&nbsp;|
                                {` ${g.sku} `}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            <FormControl variant="filled">
                <TextField
                    defaultValue=""
                    onChange={props.updateGameImage}
                    label="Game Image URL"
                    variant="filled"
                    fullWidth
                />
            </FormControl>
        </>
    );
};

export default GamePicker;
