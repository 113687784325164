import AxiosService from 'core/services/AxiosService';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import {
    Cartography,
    CartographyUpdateRequest,
    Community,
    CreateCommunityRequest,
    MediaItem,
} from '../models/Community';

export const useListItemsByGameId = (gameId: string): UseQueryResult<AxiosResponse<MediaItem[]>, unknown> => {
    return useQuery(
        `scry-media-items-${gameId}`,
        async () => await AxiosService.get<AxiosResponse<MediaItem[]>>(`/b/library/items?gameId=${gameId}`),
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useUploadMedia = (
    gameId: string
): UseMutationResult<AxiosResponse<AxiosResponse<MediaItem[]>>, unknown, File, unknown> => {
    const cache = useQueryClient();
    return useMutation(
        `scry-media-items-${gameId}`,
        async (data: File) => {
            const postData = new FormData();
            postData.set('image', data);
            return await AxiosService.post<AxiosResponse<MediaItem[]>>(`/b/library/items?gameId=${gameId}`, postData, {
                headers: {
                    'x-item-type': data.type,
                    'x-item-cat': 'Image',
                    'x-item-filename': data.name,
                    'x-item-display': data.name,
                },
            });
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(`scry-media-items-${gameId}`);
            },
        }
    );
};

export const useDeleteMedia = (gameId: string): UseMutationResult<AxiosResponse, unknown, string, unknown> => {
    const cache = useQueryClient();
    return useMutation(
        `scry-media-items-${gameId}`,
        async (fileId: string) => {
            return await AxiosService.delete<AxiosResponse>(`/b/library/items/${fileId}?gameId=${gameId}`);
        },
        {
            retry: 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
            onSuccess: () => cache.invalidateQueries(`scry-media-items-${gameId}`),
        }
    );
};

export const useGetCommunities = (gameId: string): UseQueryResult<AxiosResponse<Community[]>, unknown> => {
    return useQuery(
        `scry-communities-${gameId}`,
        async () => AxiosService.get<AxiosResponse<Community[]>>(`/b/scry/${gameId}/communities`),
        {
            refetchOnWindowFocus: false,
            retry: 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
        }
    );
};

export const useAddNewCommunity = (gameId: string): UseMutationResult<AxiosResponse, unknown, Community, unknown> => {
    const cache = useQueryClient();
    return useMutation(
        `scry-communities-${gameId}`,
        async (data: CreateCommunityRequest) => {
            return await AxiosService.post<AxiosResponse<Community>>(`/b/scry/${gameId}/communities`, data);
        },
        {
            retry: 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
            onSuccess: () => {
                cache.invalidateQueries(`scry-communities-${gameId}`);
            },
        }
    );
};

export const useUpdateCommunityById = (
    gameId: string,
    communityId: string
): UseMutationResult<AxiosResponse, unknown, Community, unknown> => {
    const cache = useQueryClient();
    return useMutation(
        `scry-communities-${gameId}`,
        async (data: Community) => {
            return await AxiosService.put<AxiosResponse<Community>>(
                `/b/scry/${gameId}/communities/${communityId}`,
                data
            );
        },
        {
            retry: 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
            onSuccess: () => {
                cache.invalidateQueries(`scry-communities-${gameId}`);
            },
        }
    );
};

export const useGetCommunityById = (
    gameId: string,
    communityId: string
): UseQueryResult<AxiosResponse<Community[]>, unknown> => {
    return useQuery(
        `scry-communities-${gameId}-${communityId}`,
        async () => AxiosService.get<AxiosResponse<Community>>(`/b/scry/${gameId}/communities/${communityId}`),
        {
            enabled: false,
        }
    );
};

export const useDeleteCommunityById = (gameId: string): UseMutationResult<AxiosResponse, unknown, string, unknown> => {
    const cache = useQueryClient();
    return useMutation(
        `scry-communities-${gameId}`,
        async (communityId: string) => {
            return await AxiosService.delete<AxiosResponse>(`/b/scry/${gameId}/communities/${communityId}`);
        },
        {
            retry: 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
            onSuccess: () => {
                cache.invalidateQueries(`scry-communities-${gameId}`);
            },
        }
    );
};

export const useGetCartography = (gameId: string): UseQueryResult<AxiosResponse<Cartography>, unknown> => {
    return useQuery(
        `cartography-${gameId}`,
        async () => await AxiosService.get<AxiosResponse<Cartography>>(`/b/scry/${gameId}/cartography`),
        {
            retry: 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
            refetchOnWindowFocus: false,
        }
    );
};

export const useUpdateCartography = (
    gameId: string
): UseMutationResult<AxiosResponse, unknown, CartographyUpdateRequest, unknown> => {
    const cache = useQueryClient();
    return useMutation(
        `cartography-${gameId}`,
        async (data: CartographyUpdateRequest) =>
            await AxiosService.put<AxiosResponse<Cartography>>(`/b/scry/${gameId}/cartography`, data),
        {
            onSuccess: () => cache.invalidateQueries(`cartography-${gameId}`),
        }
    );
};
