import { MenuItem } from '../services/FeatureService';

export interface Action {
    type: string;
}

export enum AppActions {
    INIT = 'App Initialized',
    CURRENT_USER_CHANGED = 'Current User Changed',
    TOAST_SNACK = 'Toast Snackbar',
    FEATURES_UPDATE_REQUEST = 'Features Update Request',
    FEATURES_UPDATE_RESPONSE = 'Features Update Response',
    CHANGE_ACTIVE_FEATURE = 'Change Active Feature',
    SIGN_OUT = 'Signout',
}

export type AppActionTypes =
    | InitAction
    | CurrentUserChangeAction
    | FeaturesUpdateRequestAction
    | FeaturesUpdateResponseAction
    | ChangeActiveFeatureAction
    | SignoutAction;

export interface InitAction extends Action {}
export interface CurrentUserChangeAction extends Action {
    currentUser: firebase.User | null;
}

export interface ToastSnackAction extends Action {
    status: 'success' | 'error' | 'warning';
    message: string;
    open: boolean;
}

export interface FeaturesUpdateRequestAction extends Action {
    user: firebase.User;
    dispatcher: React.Dispatch<AppActionTypes>;
}

export interface FeaturesUpdateResponseAction extends Action {
    features: MenuItem[];
}

export interface ChangeActiveFeatureAction extends Action {
    current: MenuItem;
}

export interface SignoutAction extends Action {}
