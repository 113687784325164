import React, { FC } from 'react';
import styled from 'styled-components';
import { Interaction } from 'domain/scry/models/Game';
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    TableContainer,
    Typography,
    useTheme,
} from '@material-ui/core';

import InteractionItem from './Interaction';

interface InteractionTableProps {
    interactions: Array<Interaction>;
    editItem(item: Interaction): any;
    gameId: string;
}

const InteractionTable: FC<InteractionTableProps> = ({ interactions, editItem, gameId }) => {
    const { palette } = useTheme();
    return interactions.length > 0 ? (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Cost</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {interactions.map((item: Interaction) => (
                        <InteractionItem
                            key={item.name}
                            {...item}
                            gameId={gameId}
                            editInteraction={() => editItem(item)}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <NoItems theme={palette}>
            <Typography>No Items to display</Typography>
        </NoItems>
    );
};

const NoItems = styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border: px dashed ${style => style.theme.text.secondary};
    margin-top: 20px;

    p {
        color: ${style => style.theme.text.secondary};
    }
`;

export default InteractionTable;
