import { ScryActions } from './scry.actions';
import { ScryState } from './scry.state';
import { PostConfigResponse } from 'core/models/scry/ConfigurationDefinitions';

export const scryReducer = (state: ScryState, action: any): ScryState => {
    switch (action.type) {
        case ScryActions.FETCH_INTERACTION:
            return getInteractions(state, action);
        case ScryActions.ADD_NEW_INTERACTION:
            return addInteractionToState(state, action);
        case ScryActions.GET_GAME_DATA:
            return getGame(state, action);
        case ScryActions.GET_ENGAGEMENT_SUMMARY:
            return updateEngagementSummary(state, action);
        case ScryActions.UPSTREAM_RECENT_ENGAGEMENTS:
            return updateRecentEngagements(state, action);
        case ScryActions.GET_GAME_CONFIGURATION:
            return updateGameConfiguration(state, action);
        default:
            console.warn('Login Reducer unknown or unhandled dispatch action', action);
            return state;
    }
};

function updateGameConfiguration(currentState: ScryState, action: { value: PostConfigResponse }): ScryState {
    return {
        ...currentState,
        game: {
            ...currentState.game,
            configuration: action.value,
        },
    };
}

function updateRecentEngagements(currentState: ScryState, action: any): ScryState {
    return {
        ...currentState,
        recentEngagements: action.value,
    };
}

function updateEngagementSummary(currentState: ScryState, action: any): ScryState {
    return {
        ...currentState,
        engagementSummary: action.value,
    };
}

function getInteractions(currentState: ScryState, action: any): ScryState {
    return {
        ...currentState,
        game: {
            ...currentState.game,
            interactions: {
                status: 'ready',
                data: action.value,
            },
        },
    };
}

function getGame(currentState: ScryState, action: any): ScryState {
    return {
        ...currentState,
        game: {
            ...currentState.game,
            status: 'full',
            ...action.value,
        },
    };
}

function addInteractionToState(currentState: ScryState, action: any): ScryState {
    return {
        ...currentState,
        game: {
            ...currentState.game,
            interactions: {
                status: 'ready',
                data: [action.value, ...currentState.game.interactions.data],
            },
        },
    };
}
