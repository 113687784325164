/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Track, EditorTypes } from 'core/models/Game';
import { Drawer } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { DrawerType } from '../../TrackEditorWrapper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import TrackFormContainer from './TrackFormContainer';
import { useAppState } from 'core/state/app.state';
import { useNarrationState } from 'core/state/narration/narration.state';
import { NarrationActions } from 'core/state/narration/narration.actions';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';

interface TrackSlideoutDrawerProps {
    currentTrackArray: Array<Track>;
    selectedTrackData: Track;
    selectedIndex: number;
    dispatch: any;
    closeDrawer: () => void;
    isOpen: boolean;
    drawerType: DrawerType;
}

const TrackSlideoutDrawer: FC<TrackSlideoutDrawerProps> = ({
    currentTrackArray,
    selectedTrackData,
    dispatch,
    isOpen,
    closeDrawer,
    selectedIndex,
    drawerType,
}: TrackSlideoutDrawerProps) => {
    const { gameId }: { gameId: string } = useParams();
    const [{ auth }, appDispatch] = useAppState();
    const [{ media: mediaArray }, narrationDispatch] = useNarrationState();

    useEffect(() => {
        if (auth.currentUser && !mediaArray) {
            narrationDispatch({
                type: NarrationActions.FETCH_MEDIA_ASSETS,
                gameId,
                dispatcher: narrationDispatch,
            });
        }
    }, [mediaArray, auth]);

    /**
     *
     * @tracksArray :the array we need to iterate through. Will either be top level array or `transition.trackList`
     * @trackId : ID of the track we want to add
     * @newTrackData : the object `Track' node that we're adding to the array
     * @currentIndex : to assist in ordering the tracks
     * @topOfArray :  if the track needs to be added to the root of the array
     */
    const addNewTrack = (
        tracksArray: Array<Track>,
        trackId: string,
        newTrackData: Track | Array<Track>,
        currentIndex: number,
        topOfArray?: boolean,
        isAtStart?: boolean
    ) => {
        if (topOfArray || isAtStart) {
            if (tracksArray.length === 0) {
                tracksArray.push(newTrackData as Track);
            } else if (currentIndex === -1) {
                tracksArray.unshift(newTrackData as Track);
            } else {
                tracksArray.splice(currentIndex + 1, 0, newTrackData as Track);
            }
        } else {
            let cycle = 0;
            for (let i = 0; i < tracksArray.length; i++) {
                const { id, transition } = tracksArray[i];
                if (id === trackId) {
                    if (Array.isArray(newTrackData)) {
                        if (transition.childNodes.length === 0) {
                            transition.type = 'choice';
                        }
                        transition.childNodes = [...transition.childNodes, ...newTrackData];
                    } else {
                        cycle++;
                        if (transition.type === 'linear' && cycle > 1) {
                            if (tracksArray.length === 0) {
                                tracksArray.push(newTrackData);
                            } else {
                                tracksArray.splice(currentIndex + 1, 0, newTrackData);
                            }
                        } else {
                            if (transition.childNodes.length === 0) {
                                transition.childNodes.push(newTrackData);
                            } else {
                                transition.childNodes.splice(currentIndex, 0, newTrackData as Track);
                            }
                        }
                    }
                } else {
                    addNewTrack(transition.childNodes, trackId, newTrackData, currentIndex);
                }
            }
        }
    };

    const submitForm = (trackData: Array<Track> | Track) => {
        const nodeType = Array.isArray(trackData) ? 'choice' : 'linear';

        if (nodeType === 'linear') {
            addTrackToList(
                currentTrackArray,
                selectedTrackData.id,
                trackData,
                selectedIndex,
                isAtRootLevel(),
                selectedIndex === -1 ? true : false
            );
        }

        if (nodeType === 'choice') {
            addTrackToList(currentTrackArray, selectedTrackData.id, trackData, selectedIndex);
        }

        closeDrawer();
    };

    const deleteEntireDecisionNode = () => {
        removeTrackFromList(currentTrackArray, selectedTrackData.id);
        closeDrawer();
    };

    const addTrackToList = (
        currentTrackState: Array<Track>,
        currentId: string,
        newTrackData: Track | Array<Track>,
        currentIndex: number,
        topOfArray?: boolean,
        isAtStart?: boolean
    ) => {
        const _trackArray = currentTrackState;
        addNewTrack(_trackArray, currentId, newTrackData, currentIndex, topOfArray, isAtStart);
        dispatch([EditorTypes.UPDATE_STATE, { key: 'tracks', value: _trackArray }]);
    };

    /**
     * @tracksArray : will either be the top level array or the `transition.trackList` array
     * @trackId : the ID of the track we want to remove
     */
    const removeTrack = (tracksArray: Array<Track>, trackId: string) => {
        return tracksArray
            .filter(track => track.id !== trackId)
            .map(newTrack => {
                if (newTrack.transition.childNodes?.length === 0) {
                    return newTrack;
                }
                newTrack.transition.childNodes = removeTrack(newTrack.transition.childNodes, trackId);
                if (newTrack.transition.childNodes.length === 0) {
                    newTrack.transition.type = 'linear';
                }
                return newTrack;
            });
    };

    const removeTrackFromList = (currentTrackState: Array<Track>, trackId: string) => {
        const _trackArray = currentTrackState;
        dispatch([EditorTypes.UPDATE_STATE, { key: 'tracks', value: removeTrack(_trackArray, trackId) }]);
    };

    /**
     * @tracksArray :the array we need to iterate through. Will either be top level array or `transition.trackList`
     * @trackId : ID of the track we want to add
     * @updatedTrackData : object data that's used to update the keys of a specific object nested in the tree.
     */
    const updateTrackData = (tracksArray: Array<Track>, trackId: string, updatedTrackData: Track) => {
        for (let i = 0; i < tracksArray.length; i++) {
            const { id, transition } = tracksArray[i];

            if (id === trackId) {
                tracksArray[i] = {
                    ...tracksArray[i],
                    ...updatedTrackData,
                };
            } else {
                updateTrackData(transition.childNodes, trackId, updatedTrackData);
            }
        }
    };

    const submitDecisionBlockChange = (updatedTrackData: Array<Track>) => {
        let trackArray = currentTrackArray;
        const parentTrackId = selectedTrackData?.id || '';

        if (trackArray.length > 0 && parentTrackId !== '') {
            for (let i = 0; i < trackArray.length; i++) {
                const { id, transition } = trackArray[i];

                if (id === parentTrackId) {
                    transition.childNodes = updatedTrackData;
                    dispatch([EditorTypes.UPDATE_STATE, { key: 'tracks', value: trackArray }]);
                    closeDrawer();
                } else if (id !== parentTrackId && transition.type === 'choice') {
                    submitDecisionBlockChange(transition.childNodes);
                }
            }
        } else {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                status: 'error',
                message: 'There was an issue removing this decision block. Please try again',
            } as ToastSnackAction);
        }
    };

    const submitEditSingleNode = (trackId: string, updatedTrackData: Track) => {
        const _trackArray = currentTrackArray;
        updateTrackData(_trackArray, trackId, updatedTrackData);
        dispatch([EditorTypes.UPDATE_STATE, { key: 'tracks', value: _trackArray }]);
        closeDrawer();
    };

    const isAtRootLevel = () => {
        if (!selectedTrackData || currentTrackArray.length === 0) {
            return true;
        }
        return currentTrackArray.find(track => track.id === selectedTrackData.id) !== undefined;
    };

    const formData = () => {
        if (!selectedTrackData) {
            return [];
        }

        if (drawerType === 'edit') {
            return [selectedTrackData];
        } else if (drawerType === 'editBlock') {
            return selectedTrackData.transition.childNodes;
        } else {
            return [];
        }
    };

    return (
        <TrackDrawer anchor="right" open={isOpen} onClose={closeDrawer}>
            <DndProvider backend={HTML5Backend}>
                <TrackFormContainer
                    drawerType={drawerType}
                    closeDrawer={closeDrawer}
                    selectedTrackId={selectedTrackData?.id || ''}
                    deleteEntireDecisionNode={deleteEntireDecisionNode}
                    submitForm={submitForm}
                    submitEditSingleNode={submitEditSingleNode}
                    submitDecisionBlockChange={submitDecisionBlockChange}
                    tracks={formData()}
                    parentTransitionPrompt={selectedTrackData?.transition.transitionPrompt || ''}
                    currentTrackLength={currentTrackArray.length}
                />
            </DndProvider>
        </TrackDrawer>
    );
};

const TrackDrawer = styled(Drawer)`
    .MuiDrawer-paperAnchorRight {
        width: 500px;
        padding: 10px 0;
    }
`;

export default TrackSlideoutDrawer;
