import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import LiveInteractionList from '../../Interactions/LiveInteractionList';

const LivePurchases: FC = () => {
    return (
        <Grid container className="purchase-summary" spacing={3}>
            <Grid item xs={12} className="game-metrics">
                <LiveInteractionList />
            </Grid>
        </Grid>
    );
};

export default LivePurchases;
