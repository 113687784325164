import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
    TextField,
    Grid,
    Button,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Search, NavigateNext } from '@material-ui/icons';

import { MenuSlugs } from 'core/services/FeatureService';

type Games = Array<SingleGame>;
type SingleGame = {
    gameName: string;
    gameId: string;
};
export type PublisherList = {
    publisherName: string;
    games: Games;
};

interface Props {
    scryData: Array<PublisherList>;
}

const AdminView: FC<Props> = ({ scryData }) => {
    const [filter, setFilter] = useState('');
    const { push } = useHistory();

    const goToGame = (gameId: string) => push(`/${MenuSlugs.Scry}/${gameId}`);

    const gameRows = (liveGames: Games) => {
        return (
            <List dense>
                {liveGames.map(({ gameId, gameName }: SingleGame) => (
                    <ListItem key={gameId} onClick={() => goToGame(gameId)} style={{ cursor: 'pointer' }}>
                        <StyledListText primary={gameName} />
                        <ListItemSecondaryAction style={{ right: 0 }}>
                            <IconButton>
                                <NavigateNext />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        );
    };

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid xs={8} md={6} lg={4} item>
                <TextField
                    id="filter"
                    name="filter"
                    label="Filter Games"
                    autoComplete="off"
                    variant="filled"
                    size="small"
                    fullWidth
                    type="text"
                    onChange={(event: any) => setFilter(event.target.value)}
                    value={filter}
                    InputProps={{
                        endAdornment: <Search />,
                    }}
                />
            </Grid>

            <Grid sm="auto" item>
                <Button variant="contained" color="primary">
                    New Scry Game
                </Button>
            </Grid>

            {scryData.map(({ publisherName, games }) => {
                return (
                    <Grid key={publisherName} container direction="column" style={{ marginTop: 25 }}>
                        <Typography variant="h2">{publisherName}</Typography>
                        {gameRows(games)}
                    </Grid>
                );
            })}
        </Grid>
    );
};

const StyledListText = styled(ListItemText)`
    span {
        margin: 0;
        padding: 10px 20px;
    }
`;

export default AdminView;
