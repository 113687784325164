import React from 'react';
import Grid from '@material-ui/core/Grid';
import LoginContainer from '../../domain/auth/LoginContainer';

interface UnauthorizedLayoutProps {}

const UnauthorizedLayout: React.FC<UnauthorizedLayoutProps> = props => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={12}>
                <LoginContainer />
            </Grid>
        </Grid>
    );
};

export default UnauthorizedLayout;
