import Game from 'core/models/Game';
import Asset from 'core/models/Asset';
import React, { createContext, useContext, useReducer } from 'react';
import { NarrationActionTypes } from './narration.actions';
import { reducer } from './narration.reducer';
export interface NarrationState {
    games: Array<Game>;
    hasError: boolean;
    media: Array<GameAsset> | null;
}
export interface GameAsset {
    id: string;
    data: Array<Asset>;
}

const initialState: NarrationState = {
    games: [],
    media: null,
    hasError: false,
};

const NarrationStateContext = createContext(undefined as any);

const NarrationStateProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <NarrationStateContext.Provider value={{ state, dispatch }}>{children}</NarrationStateContext.Provider>;
};

const useNarrationState = (): [NarrationState, React.Dispatch<NarrationActionTypes>] => {
    const context = useContext(NarrationStateContext);

    if (context === undefined) {
        throw new Error('useNarrationState must be within the NarrationStateProvider');
    }

    return [context.state, context.dispatch];
};

export { NarrationStateProvider, useNarrationState, initialState };
