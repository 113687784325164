import React, { FC, useReducer } from 'react';
import { useScryState } from 'core/state/scry/scry.state';
import { InteractionActions, ScryActions } from 'core/state/scry/scry.actions';
import ScryService from 'core/services/ScryService';

import styled from 'styled-components';
import { useTheme, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import ItemModal from '../ItemModal/ItemModal';
import InteractionTable from './InteractionTable';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';

import TabContent from '../common/TabContent';
import {
    CreateInteractionRequest,
    InteractionDefinition,
    UpdateInteractionRequest,
    UpdateInteractionImageRequest,
} from 'core/models/scry/InteractionDefinition';
import { Interaction } from 'domain/scry/models/Game';

interface ItemCatalog {
    inventoryItems: Array<InteractionDefinition>;
    fetchInteractions: () => void;
    tabStats: any;
    gameId: string;
}

interface ItemState {
    modalOpen: boolean;
    loading: boolean;
    activeInventoryItem: InteractionDefinition | null;
}

const itemReducer = (state: ItemState, { type, value }: any): ItemState => {
    switch (type) {
        case InteractionActions.TOGGLE_LOADING:
            return {
                ...state,
                loading: value,
            };
        case InteractionActions.TOGGLE_MODAL:
            return {
                ...state,
                activeInventoryItem: value.formValues,
                modalOpen: value.modal,
            };
        case InteractionActions.RESET_STATE:
            return initialState;
        default:
            console.warn('Item Reducer unknown or unhandled dispatch payload', { type, value });
            return state;
    }
};

const initialState: ItemState = {
    modalOpen: false,
    loading: false,
    activeInventoryItem: null,
};

const defaultFormValues = {
    id: '',
    order: 0,
    isArchived: false,
    category: 'player',
    cardUri: '',
    name: '',
    setMaxQty: false,
    maxQuantity: -1,
    cost: 0,
    requireBuyout: false,
};

const InteractionCatalog: FC<ItemCatalog> = ({ inventoryItems, gameId, tabStats }) => {
    const [, appDispatch] = useAppState();
    const [{ game }, scryDispatch]: any = useScryState();
    const [{ activeInventoryItem, modalOpen, loading }, dispatch] = useReducer(itemReducer, initialState);
    const { palette } = useTheme();

    const errorOccured = (errorMessage: string) => {
        appDispatch({
            type: AppActions.TOAST_SNACK,
            message: errorMessage,
            status: 'error',
            open: true,
        } as ToastSnackAction);
        dispatch({ type: InteractionActions.TOGGLE_LOADING, value: false });
        return;
    };

    const handleAddNewInteraction = async (
        interaction: CreateInteractionRequest | UpdateInteractionRequest,
        isEditMode: boolean = false
    ) => {
        dispatch({ type: InteractionActions.TOGGLE_LOADING, value: true });

        let result: InteractionDefinition;
        const imageData = (interaction as any).uploadedImageData;

        try {
            if (isEditMode) {
                result = await ScryService.updateInteraction(
                    gameId,
                    activeInventoryItem!.id,
                    interaction as UpdateInteractionRequest
                );

                if (imageData) {
                    const upateRequest: UpdateInteractionImageRequest = {
                        card: imageData,
                    };
                    await ScryService.updateInteractionImage(gameId, activeInventoryItem!.id, upateRequest);
                }
            } else {
                const addRequest = interaction as CreateInteractionRequest;
                addRequest.card = imageData;
                result = await ScryService.addNewInteraction(gameId, addRequest);
            }

            fetchAllTransactions();

            appDispatch({
                type: AppActions.TOAST_SNACK,
                message: `${result!.name} has been ${isEditMode ? 'updated' : 'added to the inventory'}`,
                status: 'success',
                open: true,
            } as ToastSnackAction);
        } catch (err) {
            errorOccured(err.data.errors[0].message);
        } finally {
            dispatch({ type: InteractionActions.TOGGLE_LOADING, value: false });
            closeModal();
        }
    };

    const fetchAllTransactions = async () => {
        let interactions: Array<Interaction> = [];
        try {
            const result = await ScryService.getInteractionsByGame(game.id);
            interactions = result;
        } catch (err) {
            errorOccured(err.data.errors[0].message);
        } finally {
            scryDispatch({
                type: ScryActions.FETCH_INTERACTION,
                value: interactions,
            });
        }
    };

    const addNewCatalogItem = () => {
        dispatch({
            type: InteractionActions.TOGGLE_MODAL,
            value: {
                formValues: defaultFormValues,
                modal: true,
            },
        });
    };

    const openModal = (editValues: InteractionDefinition) => {
        dispatch({
            type: InteractionActions.TOGGLE_MODAL,
            value: {
                formValues: editValues?.id ? editValues : defaultFormValues,
                modal: true,
            },
        });
    };

    const closeModal = () => {
        dispatch({
            type: InteractionActions.RESET_STATE,
        });
    };

    const filterItems = (category: string) => {
        if (!inventoryItems || inventoryItems.length === 0) return [];
        return inventoryItems.reduce(
            (acc: Array<InteractionDefinition>, curr: InteractionDefinition) =>
                curr.category === category && !curr.isArchived ? [...acc, curr] : acc,
            []
        );
    };

    const filterArchivedItems = () => {
        if (!inventoryItems || inventoryItems.length === 0) return [];
        return inventoryItems.reduce(
            (acc: Array<InteractionDefinition>, curr: InteractionDefinition) =>
                curr.isArchived ? [...acc, curr] : acc,
            []
        );
    };

    const tabData = [
        {
            label: `Player Items (${tabStats.player})`,
            panel: <InteractionTable interactions={filterItems('player')} editItem={openModal} gameId={gameId} />,
        },
        {
            label: `GM Items (${tabStats.gm})`,
            panel: <InteractionTable interactions={filterItems('gm')} editItem={openModal} gameId={gameId} />,
        },
        {
            label: `Archived (${tabStats.isArchived})`,
            panel: <InteractionTable interactions={filterArchivedItems()} editItem={openModal} gameId={gameId} />,
        },
    ];

    return (
        <CatalogContainer theme={palette}>
            <AddNew color="primary" variant="contained" onClick={addNewCatalogItem} startIcon={<Add />}>
                Add New
            </AddNew>

            <TabContent gameId={gameId} tabs={tabData} disableChange={game.interactions.data.length === 0} />

            {activeInventoryItem && (
                <ItemModal
                    open={modalOpen}
                    loading={loading}
                    onClose={closeModal}
                    form={activeInventoryItem}
                    onSubmit={handleAddNewInteraction}
                />
            )}
        </CatalogContainer>
    );
};

const CatalogContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 10px 0 20px 0;
    background-color: ${style => style.theme.background.paper};
`;

const AddNew = styled(Button)`
    position: absolute;
    right: 30px;
    top: 20px;
    z-index: 30;
`;

export default InteractionCatalog;
