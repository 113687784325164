/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useReducer, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MenuSlugs } from 'core/services/FeatureService';
import Game, { Track, EditorTypes } from 'core/models/Game';

import { useNarrationState } from 'core/state/narration/narration.state';
import { FetchGamesRequestAction, NarrationActions } from 'core/state/narration/narration.actions';

import { Breadcrumbs, useTheme, Typography, CircularProgress, Grid, Button, Container } from '@material-ui/core';
import { ErrorRounded } from '@material-ui/icons';
import Tracks from './components/manifest-editor/Tracks';
import TrackSlideoutDrawer from './components/manifest-editor/TrackSlideoutDrawer';
import ProgressiveButtonText from 'components/shared/ProgressiveButtonText';
import styled from 'styled-components';

import CatalogService from 'core/services/CatalogService';
import { useAppState } from 'core/state/app.state';
import { AppActions, ToastSnackAction } from 'core/state/app.actions';
import { useContainerState } from 'core/state/narration/container.state';
import { ContainerActionTypes, Containers } from 'core/models/Containers';

export type DrawerType = 'root' | 'add' | 'edit' | 'editBlock' | 'start';
interface EditorState {
    fetchedTracks: boolean;
    fetchError: string | null;
    game: Game | null;
    tracks: Array<Track>;
    selectedTrack: Track | null;
    selectedIndex: number;
    isTrackDrawerOpen: boolean;
    drawerType: DrawerType;
    loading: boolean;
}

const initialState = {
    fetchedTracks: false,
    fetchError: null,
    game: null,
    tracks: [],
    selectedTrack: null,
    selectedIndex: 0,
    isTrackDrawerOpen: false,
    drawerType: 'root',
    loading: false,
};

const reducer = (state: EditorState, [type, payload]: any) => {
    switch (type) {
        case EditorTypes.UPDATE_STATE:
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case EditorTypes.MANAGE_TRACK:
            return {
                ...state,
                isTrackDrawerOpen: true,
                drawerType: payload.type,
                selectedTrack: payload.track,
                selectedIndex: payload.currentIndex,
            };
        case EditorTypes.CLOSE_TRACK_DRAWER:
            return {
                ...state,
                isTrackDrawerOpen: false,
                drawerType: 'root',
                selectedTrack: null,
            };
        default:
            console.log("You didn't dispatch a message --> Editor.tsx");
            return state;
    }
};

const Editor: FC = () => {
    const { palette } = useTheme();
    const { gameId, containerId }: { gameId: string; containerId: string } = useParams();
    const [{ auth }, appDispatch] = useAppState();
    const [{ games, hasError }, narrationDispatch] = useNarrationState();
    const { containers, editorDispatch }: any = useContainerState();
    const [
        {
            game,
            tracks,
            selectedTrack,
            isTrackDrawerOpen,
            drawerType,
            selectedIndex,
            fetchedTracks,
            fetchError,
            loading,
        },
        dispatch,
    ] = useReducer(reducer, initialState);

    useEffect(() => {
        if (games.length === 0 && !hasError) {
            narrationDispatch({
                type: NarrationActions.FETCH_GAMES_REQUEST,
                dispatcher: narrationDispatch,
            } as FetchGamesRequestAction);
        }
    }, [games, hasError, narrationDispatch]);

    useEffect(() => {
        if (!containers) {
            editorDispatch([ContainerActionTypes.GET_CONTAINERS, { gameId, dispatch: editorDispatch }]);
        }
    }, [containers, containerId, gameId]);

    const fetchTracks = async () => {
        try {
            const trackList = await CatalogService.getGamePlaylist(gameId, containerId);
            dispatch([EditorTypes.UPDATE_STATE, { key: 'tracks', value: trackList.content }]);
        } catch (err) {
            if (err.status === 404) {
                dispatch([EditorTypes.UPDATE_STATE, { key: 'tracks', value: [] }]);
            } else {
                dispatch([
                    EditorTypes.UPDATE_STATE,
                    { key: 'fetchError', value: 'There was an issue fetching your tracks. Please refresh the page.' },
                ]);
            }
        } finally {
            dispatch([EditorTypes.UPDATE_STATE, { key: 'fetchedTracks', value: true }]);
        }
    };

    useEffect(() => {
        if (games.length > 0 && !fetchedTracks && auth.currentUser) {
            fetchTracks();
        }
    }, [games, fetchedTracks, auth.currentUser]);

    const setGameDetails = () => {
        const details = games.find((g: Game) => g.id === gameId);
        dispatch([EditorTypes.UPDATE_STATE, { key: 'game', value: details }]);
    };

    useEffect(() => {
        if (games.length > 0) {
            setGameDetails();
        }
    }, [games, gameId]);

    const containerName = containers?.find((c: Containers) => c.id === containerId)?.name || '';

    const manageTrack = (track: Track, type: DrawerType, currentIndex: number, edit?: boolean) => {
        dispatch([EditorTypes.MANAGE_TRACK, { track, type, currentIndex, ...(edit && { edit: true }) }]);
    };

    const changeDrawerType = (type: DrawerType) => {
        dispatch([EditorTypes.CHANGE_DRAWER_TYPE, type]);
    };

    const saveTrackList = async () => {
        dispatch([EditorTypes.UPDATE_STATE, { key: 'loading', value: true }]);
        let snackType = 'success';
        let snackMessage = `Tracklist for ${
            containerName !== '' ? containerName : 'container'
        } has been successfully updated.`;
        try {
            await CatalogService.updateGamePlaylist(gameId, containerId, tracks);
        } catch (err) {
            snackType = 'error';
            snackMessage = 'There was a problem updating your tracks. Please try again.';
        } finally {
            appDispatch({
                type: AppActions.TOAST_SNACK,
                status: snackType,
                message: snackMessage,
                open: true,
            } as ToastSnackAction);
            dispatch([EditorTypes.UPDATE_STATE, { key: 'loading', value: false }]);
        }
    };

    const renderTrackPage = () => {
        if (fetchError) {
            return (
                <ErrorBlock theme={palette}>
                    <Typography variant="h2">
                        <ErrorRounded style={{ position: 'relative', top: 5 }} color="error" /> Fetching Error:
                    </Typography>
                    <Typography>{fetchError}</Typography>
                </ErrorBlock>
            );
        }

        return (
            <>
                <Tracks
                    changeDrawerType={changeDrawerType}
                    tracks={tracks}
                    manageTrack={manageTrack}
                    drawerType={drawerType}
                />

                <TrackSlideoutDrawer
                    currentTrackArray={tracks}
                    selectedTrackData={selectedTrack}
                    selectedIndex={selectedIndex}
                    drawerType={drawerType}
                    dispatch={dispatch}
                    isOpen={isTrackDrawerOpen}
                    closeDrawer={() => dispatch([EditorTypes.CLOSE_TRACK_DRAWER])}
                />
            </>
        );
    };

    const LoadingState = () => (
        <div style={{ margin: '50px auto 0', width: 150 }}>
            <CircularProgress color="secondary" size="4rem" />
        </div>
    );

    const isSaveDisabled = () => {
        if (tracks.length === 0) {
            return true;
        }
        const lastItem = tracks[tracks.length - 1];

        // is the last root level object have a linear transition, and if so, does it also have a forceStop
        if (lastItem.transition.type === 'linear' && !lastItem.transition.forceEnd) {
            return true;
        }

        // if the last root item's transition type is choice, does it have ANY forceEnd key set to true
        if (lastItem.transition.type === 'choice') {
            const checkForceEnd = (arr: Array<Track>) => {
                let hasForceEnd: boolean = false;
                // eslint-disable-next-line array-callback-return
                arr.map((t: Track) => {
                    if (t.transition.forceEnd) {
                        hasForceEnd = true;
                    } else {
                        checkForceEnd(t.transition.childNodes);
                    }
                });
                return hasForceEnd;
            };
            const countForceEnd = lastItem.transition.childNodes.reduce((acc: number, curr: Track) => {
                if (curr.transition.forceEnd) {
                    return acc + 1;
                }
                const hasForceEnd = checkForceEnd(curr.transition.childNodes);
                return hasForceEnd ? acc + 1 : acc;
            }, 0);

            return countForceEnd === 0;
        }

        return false;
    };

    return (
        <EditorContainer>
            <SaveChangesButton
                disabled={isSaveDisabled()}
                onClick={saveTrackList}
                size="large"
                color="primary"
                variant="contained"
            >
                <ProgressiveButtonText text="Save Changes" isLoading={loading} />
            </SaveChangesButton>
            <Breadcrumbs>
                <Link style={{ color: palette.action.active }} to={`/${MenuSlugs.Narration}`}>
                    Narration
                </Link>
                <Link style={{ color: palette.action.active }} to={`/${MenuSlugs.Narration}/${gameId}`}>
                    {game?.name || '...'}
                </Link>
                <Link style={{ color: palette.action.active }} to={`/${MenuSlugs.Narration}/${gameId}/containers`}>
                    Container Editor
                </Link>
                <Typography style={{ fontSize: '0.825rem' }}>{containerName}</Typography>
            </Breadcrumbs>

            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h1">{containerName}</Typography>{' '}
                </Grid>
                {tracks.length > 0 && <Grid item xs={12} style={{ marginBottom: 40, marginTop: 20 }}></Grid>}
                {fetchedTracks ? renderTrackPage() : <LoadingState />}
            </Grid>
        </EditorContainer>
    );
};

const SaveChangesButton = styled(Button)`
    position: fixed;
    z-index: 9999;
    top: 10px;
    left: 300px;
`;

const ErrorBlock = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: 30px auto 0;
    border-radius: 8px;
    background-color: ${style => style.theme.background.paper};

    h2,
    p {
        text-align: center;
    }
`;

const EditorContainer = styled.div`
    margin-top: 65px;
    min-width: 1500px;
    padding: 0 0 0 50px;
    width: 100%;
`;

export default Editor;
