import Game from 'core/models/Game';
import { Action } from '../app.actions';
import { GameAsset } from './narration.state';

export enum NarrationActions {
    FETCH_GAMES_REQUEST = 'Fetch Games Request',
    FETCH_GAMES_DONE = 'Fetch Games Done',
    FETCH_GAMES_FAILED = 'Fetch Games Failed',
    FETCH_MEDIA_ASSETS = 'Fetch media assets',
    SET_MEDIA_ASSETS = 'set media assets',
    ADD_GAME_REQUEST = 'Add new Narration Game',
    ADD_GAME_DONE = 'Add new Narration Done',
    ADD_GAME_FAILED = 'Add new Narration Failed',
    FETCH_MEDIA_JOBS = 'Fetch media jobs',
    SET_MEDIA_JOBS = 'Set media jobs',
}

export type NarrationActionTypes =
    | FetchGamesRequestAction
    | FetchGamesDoneAction
    | FetchAssetRequest
    | FetchAssetRequestDone
    | GameAsset;

export interface FetchGamesRequestAction extends Action {
    dispatcher: React.Dispatch<NarrationActionTypes>;
}
export interface FetchGamesDoneAction extends Action {
    games: Array<Game>;
}
export interface FetchAssetRequest extends Action {
    gameId: string;
    dispatcher: React.Dispatch<NarrationActionTypes>;
}
export interface FetchAssetRequestDone extends Action {
    assets: GameAsset;
}
