import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { MenuSlugs } from 'core/services/FeatureService';

interface PublisherViewProps {
    id: string;
    name: string;
    gameDetailCardUri: string;
}

const PublisherView: FC<PublisherViewProps> = ({ id, name, gameDetailCardUri }) => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography>My Games</Typography>
                </Grid>
            </Grid>
            <GameContainer>
                <div>
                    <Link to={`/${MenuSlugs.Scry}/${id}`}>
                        <img src={gameDetailCardUri} alt={name} />
                    </Link>
                </div>
            </GameContainer>
        </>
    );
};

const GameContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    div {
        flex: 1 0 16%;
        margin: 0 4px;

        &:first-child {
            margin-left: 0px;
        }
    }

    @media only screen and (max-width: 640px) {
        justify-content: center;
    }
`;

export default PublisherView;
