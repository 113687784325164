import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const CommunityCarouselSkeleton = () => {
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
            <Grid item xs={3}>
                <Box width="100%" height="200px" style={{ minWidth: 200 }}>
                    <Skeleton width="100%" height="100%" variant="rect" />
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box width="100%" height="200px" style={{ minWidth: 200 }}>
                    <Skeleton width="100%" height="100%" variant="rect" />
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box width="100%" height="200px" style={{ minWidth: 200 }}>
                    <Skeleton width="100%" height="100%" variant="rect" />
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box width="100%" height="200px" style={{ minWidth: 200 }}>
                    <Skeleton width="100%" height="100%" variant="rect" />
                </Box>
            </Grid>
        </Grid>
    );
};

export default CommunityCarouselSkeleton;
