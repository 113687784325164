import React, { ReactElement } from 'react';
import { MenuSlugs } from 'core/services/FeatureService';
import { PlaylistPlayRounded, InfoRounded, PublishRounded } from '@material-ui/icons';
import Guid from 'core/models/Guid';

export interface NarrationSubMenuItem {
    icon: ReactElement;
    text: string;
    path: string;
    selected: boolean;
}

export const NarrationSubMenu = (gameId: Guid, currentPageName: string): Array<NarrationSubMenuItem> => {
    return [
        {
            icon: <InfoRounded />,
            text: 'Meta Data',
            path: `/${MenuSlugs.Narration}/${gameId}`,
            selected: currentPageName === 'summary',
        },
        {
            icon: <PublishRounded />,
            text: 'Media Library',
            path: `/${MenuSlugs.Narration}/${gameId}/media`,
            selected: currentPageName === 'media',
        },
        {
            icon: <PlaylistPlayRounded />,
            text: 'Editor',
            path: `/${MenuSlugs.Narration}/${gameId}/containers`,
            selected: currentPageName === 'containers',
        },
    ];
};
