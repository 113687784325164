import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useAppState } from 'core/state/app.state';

interface OrganizationContainerProps {}

const OrganizationContainer: React.FC<OrganizationContainerProps> = props => {
    const [state] = useAppState();

    const { auth } = state;

    return (
        <Grid container spacing={5} direction="column">
            <Grid item xs={12}>
                <Typography variant="h1" component="div">
                    ORG NAME HERE
                </Typography>
            </Grid>
            <Grid item xs={12}>
                Hello, {auth.currentUser?.email}!
            </Grid>
        </Grid>
    );
};

export default OrganizationContainer;
