import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import TelemetryProvider from './components/TelemetryProvider';

// Context
import { AppStateProvider } from 'core/state/app.state';

// Styling
import { ThemeProvider } from '@material-ui/core';
import { darkTheme } from './core/themes';
import { CssBaseline } from '@material-ui/core';

import PrivateRoute from './core/PrivateRoute';
import AuthorizedLayout from './components/layouts/AuthorizedLayout';
import UnauthorizedLayout from './components/layouts/UnauthorizedLayout';

export default function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <AppStateProvider>
                <Router basename="/" forceRefresh={false} keyLength={12}>
                    <TelemetryProvider>
                        <Switch>
                            <Route exact path="/login" component={UnauthorizedLayout} />
                            <PrivateRoute path={'/'} component={AuthorizedLayout} />
                        </Switch>
                    </TelemetryProvider>
                </Router>
            </AppStateProvider>
        </ThemeProvider>
    );
}
